@import '../variables';
@import '../mixins';

.travelInfo-holder {
    .traveller-holder {
        margin-top: 100px;
        h4 {
            font-weight: $fontWeight-medium;
            font-size: 42px;
            margin-bottom: 28px;
            span {
                @include brand-gradient;
            }
        }
        .info-holder {
            .owl-carousel .owl-stage-outer {
                @include container-paddingLeft;
            }
        
            .owl-carousel .owl-stage {
                padding-left: 0px !important;

            }
            .card-wrapper {
                .img-holder {
                    img {
                        border-radius: 20px 0px 0px 20px;
                        width: 250px;
                        @media (max-width:767px){
                            border-radius: 20px 20px 0px 0px !important;
                        }
                    }
                    
                    }
                    .info {
                        padding: 32px 28px;
                        background: #F8F8F8;
                        border-radius: 0 20px 20px 0;
                        max-height: 233.5px;
    
                        p {
                            color: $color-black;
                            &:nth-child(1) {
                                font-weight: $fontWeight-medium;
                
                        font-size: 28px;
                        margin-bottom: 20px;
                        line-height: 36px;
                    }
                    &:nth-child(2) {
                        font-weight: $fontWeight-normal;
                        font-size: $fontSize-smallest;
                        opacity: 0.6;
                        line-height: 18px;
                        
                    }
                }
                
        
            }
        
        
        
        
        }
        @media screen and (max-width: 1024px) {
            .card-wrapper.d-flex {
                    flex-direction: column;
               
                }
        }
        }
    }
}
@media screen and (max-width: 600px) {
    .travelInfo-holder h4{
        font-size: 30px !important;
    }
    .travelInfo-holder .traveller-holder{
        margin-bottom: 65px;
        margin-top: 0;
    }
 }