@import "../../variables";

.filter-card {
  position: relative;
  display: inline-block;
  border-radius: 54px;
  border: transparent;
  margin-right: 24px;
  @media only screen and (min-device-width: 0px) and (max-device-width: 360px) {
    margin-right: 8px;
  }
  @media only screen and (min-device-width: 361px) and (max-device-width: 414px) {
    margin-right: 10px;
  }
  @media only screen and (min-device-width: 415px) and (max-device-width: 767px) {
    margin-right: 12px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 991px) {
    margin-right: 20px;
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    border-radius: 54px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.32) 100%
    );
    @media only screen and (min-device-width: 0px) and (max-device-width: 360px) {
      border-radius: 32px ;
    }
    @media only screen and (min-device-width: 361px) and (max-device-width: 414px) {
      border-radius: 32px ;
    }
    @media only screen and (min-device-width: 415px) and (max-device-width: 767px) {
      border-radius: 32px ;
    }
  }

  img {
    display: block;
    border-radius: 54px;
    object-fit: cover;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    @media only screen and (min-device-width: 0px) and (max-device-width: 360px) {
      border-radius: 32px ;
    }
    @media only screen and (min-device-width: 361px) and (max-device-width: 414px) {
      border-radius: 32px ;
    }
    @media only screen and (min-device-width: 415px) and (max-device-width: 767px) {
      border-radius: 32px;
    }
   
  }

  .card-info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.4);
    border: 2px solid rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    padding: 8px 20px;
    width: calc(100% - 20%);
    z-index: 8;
    @media only screen and (min-device-width: 0px) and (max-device-width: 360px) {
      padding: 5px 5px;
    }
    @media only screen and (min-device-width: 361px) and (max-device-width: 414px) {
      padding: 5px 8px;
    }
    @media only screen and (min-device-width: 415px) and (max-device-width: 767px) {
      padding: 6px 15px;
    }

    p {
      margin-bottom: 0;
      text-align: center;
      color: $color-white;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
      font-size: $fontSize-smaller + 2;
      font-weight: $fontWeight-bold;
      line-height: 23px;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
}


.mobi-category-overflow {
  @media (max-width: 767px) {
    overflow-x: auto;
    justify-content: unset !important;
    padding-left: calc((100% - 92%)/2) !important;
  }
}

.corporate-card {
  img {
    position: unset;
    width: 240px;
    height: 220px;
    @media (max-width: 767px) {
      width: 180px;
      height: 160px;
    }
  }
}
