@import "../../variables";
@import "../../mixins";

.corporatePackages-holder {
  .package {
    position: relative;
    display: flex;
    margin-bottom: 32px;

    .package-image {
      img {
        border-radius: 42px 0px 0px 42px;
        height: 429px;
        object-fit: cover;
        @media (max-width: 991px) {
          border-radius: 42px 42px 0px 0px;
          height: 361px;
        }
      }
    }

    .package-details {
      background: #f5f5f5;
      border: 1px solid #ededed;
      border-radius: 0px 42px 42px 0px;
      padding: 36px 41px 36px 32px;
      height: 100%;
      @media (max-width: 991px) {
        border-radius: 0px 0px 42px 42px;
        height: auto;
        padding: 20px;
      }
      @media (min-width: 992px) {
        height: 429px;
        overflow-y: auto;
      }
      h4 {
        font-weight: $fontWeight-medium;
        font-size: 28px;
        line-height: 36px;
        color: $color-black;
        align-items: baseline;
        @media (max-width: 991px) {
          font-size: 18px;
          line-height: 32px;
        }
        span {
          @include brand-gradient;
          font-weight: $fontWeight-bold;
          font-size: 18px;
          margin-left: 7px;
          @media (max-width: 991px) {
            font-size: 14px;
          }
        }
      }
      .opacity_2{
        opacity: 0.2;
      }

      .info {
        p {
          font-weight: $fontWeight-normal;
          font-size: 18px;
          color: #797979;
          line-height: 26px;
          margin-bottom: 0px;
          @media (max-width: 991px) {
            font-size: 14px;
            line-height: 20px;
          }
          // &:nth-child(1) {
          // }

          &:nth-child(3) {
            color: $color-black;
            margin-bottom: 10px;
          }
        }

        .line {
          margin: 8px 0px;
          width: 268px;
        }
      }

      .points {
        position: absolute;
        bottom: 36px;
        @media (max-width: 991px) {
          position: unset;
        }
        p {
          margin: 0;
          font-size: 20px;
          color: #000000;
          @media (max-width: 991px) {
            font-size: 16px;
            font-weight: 700;
            margin-top: 16px;
            margin-bottom: 8px;
          }
        }
      }
    }
    
    .bestseller-tag {
      margin-bottom: 10px;
      span{
        padding: 3px 6px;
        color: #ffffff;
        background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
        border-radius: 4px;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
      }
        
    }
    .free-tag {
      margin-bottom: 10px;
      span{
        padding: 3px 6px;
        color: #ffffff;
        background: #FFA115;
        border-radius: 4px;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
      }
        
    }
    
  }

  .owl-corporate {
    .owl-dots {
      position: absolute;
      bottom: 5px;
      left: 50%;
      transform: translateX(-50%);
      margin: 5px 7px;
      .owl-dot {
        margin: 0px 4px 5px !important;
      }
    }

    .owl-dots .owl-dot.active span,
    .owl-dots .owl-dot:hover span {
      background: $color-white !important;
    }

    .owl-dots .owl-dot span {
      background: rgba(255, 255, 255, 0.5) !important;
      margin: 0;
    }
  }
}

.corporate-category-filter {
  margin-bottom: 45px;
  @media (max-width: 767px) {
    margin-bottom: 40px;
  }
  .filter-card {
    cursor: pointer;
  }
  .cat-img {
    width: 240px;
    height: 220px;
    overflow: visible;
    @media only screen and (min-device-width: 0px) and (max-device-width: 360px) {
      width: 150px;
      height: 130px;
    }
    @media only screen and (min-device-width: 361px) and (max-device-width: 414px) {
      width: 160px;
      height: 140px;
    }
    @media only screen and (min-device-width: 415px) and (max-device-width: 767px) {
      width: 170px;
      height: 150px;
    }
  }

  .active-category {
    .img-gradient {
      .cat-img {
        // border: 7px solid #fff;
        // border-radius: inherit;
        position: relative;
        width: 240px;
        height: 220px;
        border: 8px solid transparent;
        border-radius: 54px;
        background: #fff;
        @media only screen and (min-device-width: 0px) and (max-device-width: 360px) {
          width: 150px;
          height: 130px;
          border-radius: 32px;
          border: 5px solid transparent;
        }
        @media only screen and (min-device-width: 361px) and (max-device-width: 414px) {
          width: 160px;
          height: 140px;
          border-radius: 32px;
          border: 5px solid transparent;
        }
        @media only screen and (min-device-width: 415px) and (max-device-width: 767px) {
          width: 170px;
          height: 150px;
          border-radius: 32px;
          border: 6px solid transparent;
        }
        .pseudo {
          width: calc(100% - 0px);
          height: calc(100% - 0px);
          // background: linear-gradient(111.07deg, rgba(0, 0, 0, 0.5) 14.13%, rgba(21, 3, 40, 0.5) 88.44%);
          background: linear-gradient(
            98.07deg,
            #115297a6 5.22%,
            #600abd8f 101.6%
          );
          position: absolute;
          top: 0;
          left: 0;
          border: 8px solid #fff;
          border-radius: 48px;
          overflow: hidden;
          @media only screen and (min-device-width: 0px) and (max-device-width: 360px) {
            border-radius: 30px !important;
            border: 5px solid #fff !important;
          }
          @media only screen and (min-device-width: 361px) and (max-device-width: 414px) {
            border-radius: 30px !important;
            border: 5px solid #fff !important;
          }
          @media only screen and (min-device-width: 415px) and (max-device-width: 767px) {
            border-radius: 30px;
            border: 6px solid #fff;
          }
        }
        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 0;
            margin: -8px;
            border-radius: 54px;
            background: linear-gradient(98.07deg,#115297 5.22%,#600abd 101.6%);
            @media only screen and (min-device-width: 0px) and (max-device-width: 360px) {
              border-radius: 32px !important;
              margin: -5px !important;
            }
            @media only screen and (min-device-width: 361px) and (max-device-width: 414px) {
              border-radius: 32px !important;
              margin: -5px !important;
            }
            @media only screen and (min-device-width: 415px) and (max-device-width: 767px) {
              border-radius: 32px;
              margin: -6px;
            }
          }
      }
      .card-info{
        background: rgba(0, 0, 0, 0);
        border: 0px solid rgba(0, 0, 0, 0.3);
      }
    }

  

    &::after {
      background: none;
    }
  }
}

.gift-points {
  position: absolute;
  bottom: 36px;
  border-radius: 12px;
  background: #fff;
  border: 1px solid #000000;
  width: max-content;
  @media (max-width: 767px) {
    position: unset;
    margin: 20px 0px 10px;
  }
  .total-points {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
    padding: 12px 20px;
    border-radius: 12px 0px 0px 12px;
    @media (max-width: 400px){
      font-size: 13px;
    }
    @media (max-width: 480px){
      padding: 12px 10px;
    }
  }

  .voucher {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #ffffff;
    background: #000000;
    padding: 12px 20px;
    border-radius: 0px 12px 12px 0px;
    @media (max-width: 400px){
      font-size: 13px;
    }
    @media (max-width: 480px) {
      padding: 12px 10px;
    }
  }
}

.category_carousel{
  .owl-stage-outer{
    padding-left: 0px !important;
    overflow: visible;
  }
  .category-filter {
    padding-left: calc((100% - 1105px)/ 2);
  }
   .see-all-events {
    border: 2px solid #EDEDED;
    border-radius: 54px;
    @media only screen and (min-device-width: 992px) and (max-device-width: 1200px) {
    width: 100% !important;
    }
    display: flex;
   height: 220px;
   width: 165px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    cursor: pointer;
    p {
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      color: #000;
      margin-bottom: 0;
  }
  }
  .active-see-all {
    border: 8px solid #600ABD;
    border-radius: 54px;
    overflow: hidden;
    background: #fff;
  }
  .owl-stage{
    max-height: 220px;
  }
  .owl-item{
    @media only screen and (min-device-width: 1400px) and (max-device-width: 1800px) {
      max-width: 245px !important;
    }
    max-width: 280px !important;
  }
  .active-category-card{
    width: 100%;
    @media only screen and (min-device-width: 992px) and (max-device-width: 1200px) {
      width: 100% !important;
    }
     .img-gradient{
       .card-info {
      background: transparent;
      border: 0 solid rgba(0,0,0,.3);
  }
       .cat-img {
    position: relative;
    width: 100%;
    height: 220px;
    border: 8px solid transparent;
    border-radius: 54px;
    background: #fff;
   
     }
     .cat-img::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
      margin: -8px;
      border-radius: 54px;
      background: linear-gradient(98.07deg, #115297 5.22%, #600abd 101.6%);
     }
    }
    .pseudo {
      width: calc(100% - 0px);
      height: calc(100% - 0px);
      background: linear-gradient(98.07deg, #115297a6 5.22%, #600abd8f 101.6%);
      position: absolute;
      top: 0;
      left: 0;
      border: 8px solid #fff;
      border-radius: 48px;
      overflow: hidden;
  }
}

.active-category-card::after{
  background: none !important;
}
  .category-card{
    display: inline-block;
    width: 245px;
    border-radius: 54px;
    border: transparent;
    .cat-img {
     
      height: 220px;
      overflow: visible;
      img{
        display: block;
        border-radius: 54px;
        object-fit: cover;
        width: 100%;
        height: 100%;
        max-height: 220px;
        overflow: hidden;
        position: absolute;
      }
    }
    .card-info {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%,-50%);
      transform: translate(-50%,-50%);
      background: rgba(0,0,0,.4);
      border: 2px solid rgba(0,0,0,.3);
      border-radius: 16px;
      padding: 8px 20px;
      width: 80%;
      z-index: 8;
      p {
        margin-bottom: 0;
        text-align: center;
        color: #fff;
        // text-shadow: 0 2px 4px rgb(0 0 0 / 40%);
        font-size: 18px;
        font-weight: 700;
        line-height: 23px;
    }
  }
  }
  .category-card::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 220px;
    display: inline-block;
    border-radius: 54px;
    background: linear-gradient(180deg,rgba(0,0,0,.2),rgba(0,0,0,.32));
  }
}
.load-more-btn{
  background: #ffffff;
  border: 2px solid #6610f2;
  padding: 10px 15px;
  border-radius: 50px;
  font-weight: 500;
  
  span {
    background: -webkit-linear-gradient(98.07deg,#115297 5.22%,#600abd 101.6%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.searchResuts{
  .corporatePackages-holder .package .package-details {
    @media (min-width: 768px){
      height: 350px;
    }
    border-radius: 42px;
   
}
}