@import '../../variables';

.booking-details-page {
    .schedule-holder {
        background: $color-white;
        border: 2px solid #F8F8F8;
        border-radius: 24px;
        text-align: center;
        padding: 24px 15px;
        margin-top: 24px;

        p {
            margin-bottom: 0px;
            font-weight: 700;
            font-size: 22px;
            color: $color-black;
            @media (max-width:767px){
                font-size: 18px;
            }
        }
    }

    .points-holder {
        display: flex;
        justify-content: center;
        margin-top: 24px;

        p {
            margin-bottom: 0;
            font-weight: 500;
            font-size: 18px;
            text-align: center;
            color: #000000;
        }
    }

    .special-request {
        p {
            font-weight: 500 !important;
            font-size: 14px !important;
        }
    }

    .representative {
        p {
            margin-bottom: 0px;
            background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            text-align: center;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            margin: 48px auto 0;
            width: 85%;

        }
    }

    .contact-details {
        p {
            font-weight: 500;
            &:nth-child(1) {
                font-size: 18px;
                margin-bottom: 12px;
                word-break: break-all;
            }

            &:nth-child(2) {
                font-size: 14px;
                color: #8A8A8A;
            }
        }
    }
    .recipient-details{
        .contact-card {
            background-color: #ffffff;
            span,a {
                display: inline-block;
                padding-bottom: 10px;
                border-bottom: 1px solid #222;
                font-weight: 700;
            }
        }
    }
}