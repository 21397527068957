.contact-bg {
    background: #fff;
    padding: 68px 0px;
    position: relative;
    @media (min-width:992px) {
        background: #efefef;
        height: 100vh;
        overflow-y: auto;
    }
    .goBack {
        position: absolute;
        top: 34px;
        right: 58px;
        cursor: pointer;
    }

    .contact-holder {
        width: calc(100% - 31%);
        margin: 0 auto;

        @media (max-width:767px) {
            width: calc(100% - 10%);
        }

        h1,
        p {
            font-weight: 700;
            font-size: 36px;
            @media (max-width:767px) {
                font-size: 24px;
                line-height: 31px;
            }
        }

        .get-in-touch {
            background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-bottom: 24px;
        }

        .contact-card {
            background: #FCFCFC;
            border: 2px solid #F8F8F8;
            box-sizing: border-box;
            border-radius: 24px;
            padding: 24px;
            text-align: left;

            @media (max-width:767px) {
                padding: 16px;
            }

            p {
                line-height: normal;
                margin-bottom: 0px;
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: #000000;
                background: none;
                text-align: left;

                @media (max-width:767px) {
                    font-size: 12px;
                }

                &:nth-child(1) {
                    font-size: 18px;
                    margin-bottom: 12px;

                    @media (max-width:767px) {
                        font-size: 14px;
                    }
                }

                a {
                    color: #000000;
                    text-decoration: none;
                    word-break: break-all;
                }
            }
        }
    }
}

.custom-box {
    background: #FFFFFF;
    border: 1px solid #C9C9C9;
    box-sizing: border-box;
    border-radius: 32px;
    padding: 48px 32px 36px;

    @media (max-width:767px) {
        padding: 32px 16px 24px;
    }



}

.contact-form {
    h5 {
        font-weight: 500;
        font-size: 26px;
        color: #000000;
        text-align: center;

        @media (max-width:767px) {
            font-size: 18px;
        }
    }

    .contact-line {
        border-top: 1px solid #000;
        opacity: 0.1;
        margin: 20px auto;
    }

    .line {
        border-top: 1px solid #000;
        opacity: 0.1;
        margin: 0px auto 8px;
    }
}

.or-text {
    margin: 24px auto;

    @media (max-width:767px) {
        margin: 12px auto;
    }

    p {
        font-weight: 500;
        font-size: 26px;
        color: #000000;
        margin-bottom: 0;

        @media (max-width:767px) {
            font-size: 18px !important;
        }
    }
}

.custom-input {
    input {
        padding: 0 !important;
        border: none !important;
        font-weight: 500;
        font-size: 18px;
        color: #000000;
        background: transparent;

        &::placeholder {
            opacity: 0.4;
        }
    }

    label {
        font-weight: 400;
        font-size: 14px;
        color: #000000;
        opacity: 0.5;
        margin-bottom: 0px;
    }
}

.card--bg {
    background: #FCFCFC;
    border: 2px solid #F8F8F8;
    box-sizing: border-box;
    border-radius: 24px;
    padding: 20px 24px;
}

.mb20 {
    margin-bottom: 20px;
}

.btn--send {
    padding: 20px 54px !important;
    background: #2D2D2D;
    border-radius: 24px !important;
    color: #FFFFFF;
    font-weight: 700 !important;
    font-size: 18px !important;
    width: auto;
    &:hover {
        color: #FFFFFF !important;
    }
}

.meet-us {
    margin: 24px 0px 50px;

    .contact-card {
        p {
            color: #000000;
            font-weight: 400;

            &:nth-child(1) {
                font-size: 18px;
                line-height: 23px;

                @media (max-width:767px) {
                    font-size: 16px;
                }
            }

            &:nth-child(2) {
                opacity: 0.6;
                font-size: 16px;

                @media (max-width:767px) {
                    font-size: 14px;
                }
            }
        }
    }
}

.error {
    font-size: 14px !important;
    text-align: left !important;
    color: red;
    margin-bottom: 0px;
    font-weight: 400;
    line-height: 20px !important;
    display: block;
}

.height-100vh {
    height: 100vh;
}

.no--wrap {
    @media (max-width:991px) {
        flex-wrap: nowrap !important;
        overflow-x: auto;
        padding: 0px 20px !important;
        margin: 0 !important;
    }

    @media (min-width:992px) {
        padding: 0px 8px;
    }
}