@import '../../variables';

.login {
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.auth-wrapper {
	background-color: #f6f6f6;
	margin: 0px;
}

.login-banner-content {
	background-image: url('../../../../assets/images/authImages/login-banner-bg.webp');
	background-size: cover;
	background-position: center;
	background-position: left top;
	background-repeat: no-repeat;
	display: flex;
	justify-content: center;
}

.vectors {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 1;

	img {
		width: 100%;
		height: 100%;
	}
}

.login-logo {
	img {
		display: block;
	}

	font-weight: $fontWeight-bold;
	margin-bottom: 44px;
}

.login-text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: #fff;
	max-width: 320px;
	z-index: 2;
}

.login-text-heading {
	font-size: 18px;
	font-weight: $fontWeight-normal;
}

.login-text-subheading {
	font-size: 48px;
	font-weight: $fontWeight-bold;
	line-height: 62px;
}

.login-auth-content-block {
	margin: 50px auto;
	max-width: 60%;
}

.login-auth-content {
	margin: 0px;
	padding: 0px;
}

.auth-left-icon {
	margin-bottom: 30px;
}

.auth-toggle-button {
	ul {
		background-color: #f1f1f1;
		border-radius: 24px;
		padding: 1px;

		li {
			flex: 1;
			text-align: center;
		}
	}

	ul.nav-pills {
		li {
			a.nav-link {
				border-radius: 24px;
				color: #606060;
				font-size: 22px;
				font-weight: $fontWeight-medium;
				padding: 13px 0px;
			}

			a.nav-link.active {
				background-color: $color-white;
				color: $color-black;
				font-weight: 500;
				border-radius: 24px;
			}
		}
	}
}

.card-box {
	background-color: #ffffff;
	box-shadow: 0px 0px 13px 2px #eee;
	padding: 48px 32px;
	border-radius: 42px;

	@media only screen and (max-width: 767px) {
		overflow: auto;
		//margin-left: -15%;
	}
}

.input-container {
	padding: 5px 20px;
	background-color: #FCFCFC;
	border: 2px solid #F8F8F8;
	border-radius: 15px;
	margin-bottom: 20px;

	input {
		width: 100%;
		border: none;
		border-bottom: 1px solid #e3e3e3;
		padding: 10px 0px;
		background-color: transparent;

		&:focus {
			background-color: none;
			outline: none;
		}

		&::placeholder {
			color: #979797;
			opacity: 1;
		}

		&:-ms-input-placeholder {
			color: #979797;
		}

		&::-ms-input-placeholder {
			color: #979797;
		}

		&:hover {
			outline: none;
		}

		&:disabled {
			background: #cccccc2b !important;
			padding: 5px;
		}
	}

	label {
		padding: 10px 0px;
		margin: 0px;
		font-size: 12px;
		color: #b0b0b0;
	}

	a {
		padding: 10px 0px;
		margin: 0px;
		font-size: 12px;
		color: #b0b0b0;
		text-decoration: none;
		color: blue;
	}

	label.error {
		color: red;
		font-size: inherit;
	}
}

.otp-btn-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.btn-common {
	background-color: #2d2d2d;
	color: #ffffff;
	border-radius: 20px;
	border: 0px;
	padding: 12px 40px;
	font-weight: 700;
}

.login-footer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.social-icon-cont {
		background-color: #f6f6f6;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		padding: 13px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0px 10px;
		cursor: pointer;
	}
}

.login-footer-icon {
	display: flex;
	margin-top: 10px;
}

.otp-timer {
	text-align: center;

	span {
		color: red;
	}
}

.modal-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}
.dateModal-wrapper{
	display: flex;
	flex-direction: column;
	// justify-content: center;
	align-items: center;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	z-index: 9999;
	// top: 0;
	
}

.overlay-modal {
	position: absolute;
	right: 0%;
	width: 309%;
	height: 135%;
	opacity: 0.4;
	background-color: #000000;
	z-index: 1;
}

.card-box-index {
	z-index: 2;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 460px;
	padding: 50px;
	position: absolute;
	right: 65%;

	@media (max-width:767px) {
		width: 90%;
		padding: 30px;
		position: absolute;
		right: 14px;
		left: 50%;
		top: 50%;
		/* margin: 0 20px; */
		transform: translate(-50%, -50%);
	}

	hr {
		width: 100%;
	}
}

.show {
	display: flex;
}

.hide {
	display: none;
}

@media only screen and (min-width : 768px) {
    .animated_only_mobile {
        /*CSS transitions*/
        -o-transition-property: none !important;
        -moz-transition-property: none !important;
        -ms-transition-property: none !important;
        -webkit-transition-property: none !important;
        transition-property: none !important;
        /*CSS transforms*/
        -o-transform: none !important;
        -moz-transform: none !important;
        -ms-transform: none !important;
        -webkit-transform: none !important;
        transform: none !important;
        /*CSS animations*/
        -webkit-animation: none !important;
        -moz-animation: none !important;
        -o-animation: none !important;
        -ms-animation: none !important;
        animation: none !important;
    }
};
 
.popup {
	visibility: visible !important;}

@media only screen and (max-width: 767px) {
	.login-banner-content {
		display: none;
	}

	.login-auth-content-block {
		margin: 50px auto;
		max-width: 95%;
		height: 100%;
	}

}

.outer-box {
	background: #fff;
	padding: 24px;
	border-radius: 24px;
	box-shadow: 0px 0px 13px 2px #eee;
	.inner-box {
		background: #F6F6F6;
		border: 1px solid #EBEBEB;
		box-sizing: border-box;
		border-radius: 12px;
		padding: 32px;
		@media screen and (max-width: 767px) {
			height: 200px;
		}
		
	}
	@media screen and (max-width: 767px) {
		.dates{
			height: 100px;
			overflow-y: auto;
		}
	}
	
	.overflow{
		overflow-y: scroll;
		height: 200px;
		padding: 0 10px 0 0;
		@media screen and (max-width: 767px) {
			height: 200px;
		}
	}
}