// Gift wrap box
.giftWrap-img {
    position: relative;

    .giftWrap-content{
        position: absolute;
        bottom: 0;
        left: 0;
        
    }

    .giftWrap-content span{
        color: #fff;
        font-weight: bold;
        
        display: block;
    }
    .giftWrap-content p{
        font-weight: bold;
        
        color: #FFFFFF;
    }
}  
@media screen and (max-width: 320px) {
    .giftWrap-img {
        .giftWrap-content {
            width: 182px;
            padding: 0 10px;
            margin-left: 5px;
        }
        .giftWrap-content span{
            font-size: 15px;
            line-height: 20px;
            margin-bottom: 5px; 
        }
        .giftWrap-content p{
            font-size: 10px;
            line-height: 15px;
            
        }
    }
}
@media screen and (min-width: 320px) {
    .giftWrap-img {
        .giftWrap-content {
            width: 200px;
            padding: 10px 20px;
            margin-left: 5px;
        }
        .giftWrap-content span{
            font-size: 15px;
            line-height: 20px;
            margin-bottom: 5px; 
        }
        .giftWrap-content p{
            font-size: 10px;
            line-height: 15px;
            
        }
    }
}
@media screen and (min-width: 411px) {
    .giftWrap-img {
        .giftWrap-content {
            width: 240px;
            padding: 10px;
            margin-left: 10px;
        }
        .giftWrap-content span{
            font-size: 20px;
            line-height: 26px;
            margin-bottom: 10px; 
        }
        .giftWrap-content p{
            font-size: 12px;
            line-height: 20px;
            
        }
    }
}
@media screen and (min-width: 500px) {
    .giftWrap-img {
        .giftWrap-content {
            width: 300px;
            padding: 40px;
            margin-left: 10px;
        }
        .giftWrap-content span{
            font-size: 26px;
            line-height: 31px;
            margin-bottom: 10px; 
        }
        .giftWrap-content p{
            font-size: 13px;
            line-height: 20px;
            
        }
    }
}

@media screen and (min-width: 768px) {
    .giftWrap-img {
        .giftWrap-content {
            width: 405px;
            padding: 40px;
            margin-left: 10px;
        }
        .giftWrap-content span{
            font-size: 36px;
            line-height: 47px;
            margin-bottom: 20px;
        }
        .giftWrap-content p{
            font-size: 20px;
            line-height: 26px;
            
        
        }
    }
}

@media screen and (min-width: 1024px) {
    .giftWrap-img {
        .giftWrap-content {
            width: 300px;
            padding: 40px;
            margin-left: 10px;
        }
        .giftWrap-content span{
            font-size: 26px;
            line-height: 31px;
            margin-bottom: 10px; 
        }
        .giftWrap-content p{
            font-size: 13px;
            line-height: 20px;
            
        }
    }
}

@media screen and (min-width: 1200px) {
   .giftWrap-img {
        .giftWrap-content {
            width: 405px;
            padding: 40px;
            margin-left: 10px;
        }
        .giftWrap-content span{
            font-size: 36px;
            line-height: 47px;
            margin-bottom: 20px;
        }
        .giftWrap-content p{
            font-size: 20px;
            line-height: 26px;
            
        }
    }
}




