

    .buying-holder{
        .main-wrapper{
            display: flex;
            flex-direction: row;
            
        }
        .left-wrapper {
            display: flex;
            flex-direction: column;
            flex: 1.5;
            margin-right: 15px;
        }
        .wrapper{
            display: flex;
            flex-direction: column-reverse;
        }
        .right-wrapper {
            display: flex;
            flex: 1;
            flex-direction: column;
            margin-left: 15px;

            .input-holder{
                padding: 10px 20px 0px;
            }
        }
        .form-group {
            margin-bottom: 1rem;
            width: 70%;
        }
        .checkout-mobile-button{
            display: none;
        }
        .purchase-details .buyer-details .input-holder .form-control:disabled{
            background-color: #e9ecef00;
            opacity: 1;
        }
        .input-holder {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #FFFFFF;
            border: 2px solid #F8F8F8;
            border-radius: 24px;
            padding: 10px 20px;
            margin-bottom: 26px;
        }
        .purchase-details .buyer-details {
            
            border: 1px solid #c9c9c9b8;
        }
        ::placeholder {
            color: #222;
            opacity: 1; /* Firefox */
          }
        .recipient-form {
            .mobile{
                display: none;
            }
            .input-holder{
                padding: 15px 20px;
            }
        }
        
    } 

    

    @media screen and (max-width: 768px) {
        .buying-holder{
            .main-wrapper{
                display: flex;
                flex-direction: column;
                
                
                
            }
            .left-wrapper{
                overflow: auto;
                width: 100%;
            }
            .wrapper {
                width: 100%;
                .purchase-details .buyer-details .recipient-form{
                    
                    width: 500px !important;
                    margin-right: 20px;
                }
                
                
                
            }
            .recipient-form {
                width: 400px;
                margin-right: 20px;
                border: 2px solid #E0E0E0;
                padding: 12px 15px 0 15px;
                border-radius: 32px;

                .desk{
                    display: none;
                }
                .mobile{
                    display: block;
                }

                .edit{
                    display: none;
                }
                
            }
            .giftVoucher-holder .gift-voucher {
                height: 490px;
                width: 261px;
            }
            .purchase-details .buyer-details {
                padding: 0;
                border: 0px solid #eee;
                margin-right: 0px;
                
            }
            
            .left-wrapper .wrapper{
                display: flex;
                flex-direction: row;
                overflow: auto;
                scroll-behavior: smooth;
                
                
            }
            .left-wrapper .wrapper::-webkit-scrollbar {
                display: none;
            }
            /* For Firefox and IE */
            .left-wrapper .wrapper {
                scrollbar-width: none;
                -ms-overflow-style: none;
            }
            .left-wrapper .wrapper .giftVoucher-holder{
                
                width: 400px !important;
                
            }

            .right-wrapper {
                display: flex;
                flex-direction: column-reverse;
                width: 100%;
                margin-top: 80px;
                margin-left: 0px;
                .profile-details{
                    border: 1px solid #E0E0E0;
                    padding: 32px 20px 15px;
                }
                .edit{
                    margin-left: 15px;
                }
                
            }
            
            .form-group {
                margin-bottom: 1rem;
                width: 100%;
            }
            .purchase-details .buyer-details .input-holder {
                
                padding: 15px;
                
            }
            .giftVoucher-holder {
                margin: 0px auto;
            }
            .mobile-buttons {
                display: block;
            }
            .checkout-desk-button{
                display: none
            }
            .checkout-mobile-button{
                display: block;
                bottom: 5%;
                position: fixed;
                right: 20%;
                left: 20%;
                z-index: 1;
                // transform: translate(50%, 50%);
                width: 60%;
                // margin: 20px 50px;

                .button{
                    // padding: 0px 20px;
                    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.2);
                    border-radius: 20px;
                    background: linear-gradient(91.61deg, #600ABD 3.87%, #115297 100%);
                    color: #fff;
                    display: block;
                    right: 0;
                    // margin: 0px 10px;
                    text-align: center;
                }
                .button button{
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 26px;
                }
            }
        }    

    }

    @media screen and (max-width: 411px) {
        .buying-holder{
            .recipient-form {
                width: 290px;
            }
        }   
    }
    @media screen and (max-width: 320px) {
        .buying-holder{
            .recipient-form {
                width: 250px;
            }
            .checkout-mobile-button .button button {
                
                font-size: 15px;
                line-height: 12px;
            }
            .purchase-details h1 {
                
                font-size: 30px;
                line-height: 40px;
                color: #000;
            }            
        }   
    }



// Checkout-form  
.buying-holder{
    .checkout-form{
        width: 100%;
         .profile-details {
            padding: 0;
            background: #FFFFFF;
            border: 0px solid #F8F8F8;
            border-radius: 24px;

            .input-holder {
                opacity: 0.9;
                padding: 5px 20px;
                margin-bottom: 15px;
                .form-group{
                    width: 100%;
                }

                input{
                    font-size: 16px;
                    border-bottom: 1px solid #eee;
                    font-weight: 500;
                }
                label{
                    opacity: 0.8;
                    font-size: 13px;
                }
            }
        }
        
    }
}
