.notes {
  margin: 30px 0px;
  padding: 20px 16px;
  // background: var(linear-gradient(98.07deg, #115297 5.22%, 101.6%));
  cursor: pointer;
  border: 4px solid #600abd;
  border-radius: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 90px;
  width: 638px;
  background: #ffffff;
  margin-top: 24px;
  margin-bottom: 24px;
  box-sizing: border-box;
}
.notes h4 {
  position: static;
  width: 46px;
  height: 26px;
  left: 0px;
  top: 0px;
  font-family: DM Sans;
  font-style: normal;
  
  line-height: 26px;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 4px 0px;
}
.notes p {
  position: static;
  width: auto;
  height: auto;
  left: 0px;
  top: 30px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 4px 0px;
  margin-bottom: 8px;
}
.InfoCircle {
  position: absolute;
  left: 6.25%;
  right: 6.25%;
  top: 6.25%;
  bottom: 6.25%;
  height: 40px;
  width: 40px;
  background: linear-gradient(98.07deg, #115297 5.22%, #600abd 101.6%);
}
.row-note {
  display: flex;
}
.desk-prevent{
  display: none;
}
@media only screen and (max-width: 767px){
  .mob-prevent{
    display: none;
}
.desk-prevent{
  display: block;
}
.InfoCircle{
  height: 30px;
  width: 30px;
}
.notes{
  padding: 11px 12px;
}
.notes img{
  height: 22px;
}
}
