.toggle {
    height: 24px;
    width: max-content;
    padding: 4px 8px;
    background-image: linear-gradient(to right, #04D48F, #68C9FF);
    border-radius: 4px;
    text-align: center;
    padding-top: 1px;
    font-size: 95%;
    margin-bottom: 10px;
    margin-right: 7px;
     .text {
        font-weight: bold;
        color: white;
        font-size: 14px;
       //font-size: 95%;
      }
      img{
          margin-top: -3px;
      }
  }
  
  * {
    box-sizing: border-box;
  }
  .rowww{
    border: 4px solid transparent;
    margin: 30px 0px;
    padding: 20px;
    // border-image: linear-gradient(to right, #19c25f, #42d4f5);
    // border-image-slice: 1;
    cursor: pointer;
    border: 4px solid #6DFFCE !important;
    border-radius: 32px;

    .columnsss {
        float: left;
        width: 33%;
        padding: 0px;
        height: 80px;
        overflow: hidden; 
      }
      .firstss{
        padding-top: 20px;
        width: 10%;
      }
      .secondss{
          
        padding-top: 0px;
        width: 80%;
      }
      .thirdss{
        padding-top: 20px;
        text-align: center;
        width: 10%;
      }
  }
 
  .rowww:after {
    content: "";
    display: table;
    clear: both;
  }

  .model-content {
    width: 800px;
  }
  .up {
    background-color: white;
    height: 200px;
    border-radius: 30px 30px 0px 0px;
    float: up;
    padding: 48px 40px 20px;
    
  }
  .child {
    float: left;
    height: 430px;
    padding: 20px 10px 40px 10px;
    background-color: white;
    @media only screen and (max-width: 1024px){
        height: 550px;
        padding-top: 30px;
    }
  }
  .down .fir {
    
    width: 50%;
    border-radius: 0px 0px 0px 30px;
    padding-left: 40px;
  }
  .down .sec {
    width: 50%;
    border-radius: 0px 0px 30px 0px;
    padding-right: 40px;
    
  }
  
  .model {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 9999; /* Sit on top */
    padding-top: 50px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }
  
  /* Model Content */
  .model-content {
    width: 800px;
    margin: auto;
    padding: 20px;
    width: 70%;
    @media only screen and (max-width: 1024px){
       width: 90%;
       height: fit-content;
    }
  }
  
  /* The Close Button */
  .closer {
    color: black;
    float: right;
    font-size: 40px;
    font-weight: 20px;
  }
  
  .closer:hover,
  .closer:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  /*Mob view*/
  .desk-prevent{
      display: none;
  }
  .rowww-down-jr{
      height: 75px;
      clear:both;
  }
  @media only screen and (max-width: 767px){
      .mob-prevent{
          display: none;
      }
      .desk-prevent{
        display: block;
    }
      .columnsss{
          height: 35px !important;
      }
      .firstss, .secondss, .thirdss{
          padding-top: 0px !important;
          
      }
      .rowww{
          padding: 17px !important;
      }
      .firstss img{
          width: 22px;
          margin-left: 8px;
         
      }
  }
  @media only screen and (max-width: 767px){
      .down{
          background-color: white;
      }
      .model-content {
        background-color: white;
        margin: auto;
        padding: 30px !important;
        width: 100%;
        border-radius: 25px;
      }
      .model{
          z-index: 9999 !important;
      }
      .model-content p{
          margin-left: 10px;
          font-size: 93%;
          margin-bottom: 5px;
      }
      .down p{
          margin-left: 0px;
      }
      .model-content h5{
        margin-top: 33px;
        margin-bottom: 13px;
    }
  }
  
  