@import '../../variables';
@import '../../mixins';

.home-search{
    width: 500px;
    margin: 0 auto;
    position: relative;
    input.react-autosuggest__input {
        background-color: #fff !important;
        color: #263238 !important;
        opacity: .85 !important;
    }
    .search_icon{
        color: #222 !important;
        z-index: 999;
    }
    .search-button{
        button{
            background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
            border: 2px solid #FFFFFF;
            box-sizing: border-box;
            border-radius: 20px;
            color: #fff;
            padding: 10px 18px;
            margin-left: 8px;
            font-weight: 500;
        }
    }
}
.main-header-search{
    margin-right: 40px;
    position: relative;
    width: 400px;
    @media (max-width:768px) {
        width: 300px;
        margin-right: 60px;
        margin-top: 5px;
    }
}
.padding{
    input.react-autosuggest__input {
        
        padding: 10px 20px 10px 20px !important;

        
    }
}
.globalSearch{

    .clear-button{
        position: absolute;
        right: 115px;
        top: 8px;
        font-size: 27px;
        font-weight: 400;
        height: 30px;
        width: 30px;
        padding: 13px 10px;
        line-height: 0;
        border-radius: 50px;
        cursor: pointer;
        color: #000;
        z-index: 999;
        // background-color: #fff;
        transform: translateX(2px);
    }
    
    .search_icon{
        position: absolute;
        top: 10px;
        left: 20px
    }
    input.react-autosuggest__input {
        background-color: transparent;
        padding: 10px 20px 10px 45px;
        border: 2px solid rgba(255, 255, 255, 0.25);
        box-sizing: border-box;
        border-radius: 16px;
        width: 100%;
        color: #fff;
        @media (max-width:768px) {
            // border: none;
        }

        
    }
    
    input::placeholder {
        color: #c4c4c4;
        opacity: 1;
      }
    
    .react-autosuggest__container {
        position: relative;
        z-index: 999;
        
        // &:after {
        //     content: '';
        //     background: url('../../../images/corporates/search.png');
        //     background-repeat: no-repeat;
        //     width: 20px;
        //     height: 22px;
        //     display: block;
        //     margin: 10px;
        //     right: 10px;
        //     top: 5px;
        //     position: absolute;
        // }
        .react-autosuggest__suggestion::before {
            display: none;
        }
        .suggestion-content  {
            padding: 10px;
            padding-left: 5px;
            width: 100%;
            padding: 10px 20px;
            background-color: #FBFBFB;
            border-radius: 24px;
            .title{
                text-transform: initial;
            }
        }
        .react-autosuggest__suggestion {
            background: #FFF !important;
            border: 0px solid #EBEBEB !important;
            box-sizing: border-box;
            border-radius: 0px !important;
            padding: 2px;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 5px;
            .info {
                p{
                    color: #797979;
                    font-size: 18px;
                    line-height: 26px;
                    font-weight: 400;
                    margin-bottom: 0;
                    text-align: left;
                    @media (max-width: 768px){
                        font-size: 14px;
                        line-height: 22px;
                    }
                    span{
                        background:linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%) ;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        -webkit-box-decoration-break: clone;
                        
                    }
                    
                    :hover{
                        color: #fff !important;
                    }
                }
                
            }
        }
        .react-autosuggest__suggestions-container--open {
            display: block;
            position: absolute;
            width: 100%;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
            border-radius: 32px;
            
            padding: 15px 0px;
            max-height: unset;
            overflow-y: hidden;
            z-index: 10000;
            ul {
                overflow-y: auto;
                max-height: 300px;
                margin: 5px auto;
                width: 100%;
                position: relative;
                @media (min-width: "768px"){
                    padding: 0 15px;
                }
            }
        }
    }
}
.search_btn {
    position: absolute;
    z-index: 9;
    border: none;
    top: 15px;
    left: 20px;
    border-radius: 76%;
    padding: -17px;
    width: 48px;
    height: 48px;
    @media (min-width: "768px"){
        right: 110px;
        top: 22px;
        left: auto;
    }
}

.mobile-search  {
    position: absolute;
    left: 23px;
    top: -8px;
    width: 69%;
    @media (min-width: "768px"){
        width: 25%;
        right: 110px;
        left: auto;
        top: 0px;
    }
    input.react-autosuggest__input {
        background: #ffffff;
        color: #222;
        border-radius: 30px;
        padding-left: 45px
    }
}

.search-icon-mobile {
    position: relative;
    top: 35px;
    left: 18px;
    z-index: 9999;
}

.highlight {
    background: #cbf2fc;
    font-weight: bold;
  }
  
  .react-autosuggest__suggestion--focused .highlight {
    color: #120000;
  }

.sugg-highlighted {
    background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
    width: 100%;
    border-bottom: 1px solid rgb(240, 240, 240);
    border-radius: 24px;
    .info{
        p{
            color: #fff !important;
            span{
                background: transparent;
                -webkit-text-fill-color: #fff !important;
                -webkit-background-clip: unset;
            }
        }
        
    }
    .highlight {
        background: transparent;
        font-weight: bold;
        color: #fff;
      }
    color: #fff !important;

    
}
.gradient-text{
    p{
    background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    }
}
.gradient-text{
    
    background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    
}

.searchPage-wrapper{
    margin-bottom: 50px;
    display: flex;
    position: relative;
    z-index: 1;
    .globalSearch input.react-autosuggest__input {
        background-color: transparent;
        padding: 18px 33px;
        // background: #FBFBFB;
        border: 1px solid #e0e0e0;
        // box-sizing: border-box;
        border-radius: 24px;
        box-sizing: border-box;
        // border-radius: 16px;
        width: 504px;
        color: #000;
        @media(max-width: '768px'){
            width: 100%;
            padding: 14px 20px;
        }
    }
    .input{
        @media(max-width: '768px'){
            flex-grow: 2;
        }
    }
    button{
        background: #fbfbfb;
        border: 1px solid #ebebeb;
        box-sizing: border-box;
        border-radius: 24px;
        color: #555;
        font-weight: 500;
        font-size: 18px;
        padding: 15.5px 23px;
        width: -webkit-max-content;
        width: max-content;
        @media(max-width: '768px'){
            padding: 12px 20px;
        }
    }
}