@import '../../variables';
@import '../../mixins';

.purchase-details {


    @media (max-width:767px) {
        margin-top: 84px;
    }

    h1 {
        text-align: center;
        font-weight: $fontWeight-medium;
        font-size: 42px;
        line-height: 55px;
        color: $color-black;
        margin-bottom: 0px;

        @media (max-width:767px) {
            font-size: 32px;
            margin-bottom: -15px;
        }
    }

    .line {
        opacity: 0.05;
        border-top: 1px solid #000000;
        margin: 32px 0px 24px;

        @media (max-width:767px) {
            margin: 16px 0px;
        }
    }

    .price-holder {
        @include hammock-custom-border;
        padding: 46px 32px 42px;

        @media (max-width:767px) {
            padding: 46px 24px 32px;
        }

        h4 {
            margin-bottom: 16px;
        }

        .price-line {
            opacity: 0.04;
            border-top: 1px solid #000000;
            width: 230px;
            margin: 16px 0px 21px;

        }

        .checkout-title {
            font-size: 18px;
            line-height: 20px;
            text-align: center;
            color: $color-black;
            font-weight: 700;
            background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .total-cost {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;

            .total {
                p {
                    font-weight: $fontWeight-bold;
                    font-size: 16px;
                    color: $color-black;
                    margin-bottom: 0;
                }
            }

            .amount {
                p {
                    font-weight: $fontWeight-bold;
                    font-size: 18px;
                    color: $color-black;
                    margin-bottom: 0;
                }
            }
        }

        .note {
            color: rgba(0, 0, 0, 0.6);
            font-size: 12px;
        }

        .rewards-holder {
            padding: 35px 27px;
            background: #F1F1F1;
            border-radius: 24px;

            .my-rewards {
                display: flex;
                justify-content: space-between;
            }

            .MuiSlider-rail {
                background: #C4C4C4;
                height: 14px;
                border-radius: 22px;
            }

            .MuiSlider-track {
                background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%) !important;
                border-radius: 22px;
                height: 14px;
            }

            .MuiSlider-thumb {
                display: none;
            }

            .MuiGrid-item {
                margin-left: -8px;
            }

            .my-rewards {
                display: flex;
                align-items: center;

                .rewards {
                    font-weight: $fontWeight-bold;
                    font-size: 18px;
                    color: #000000;
                    margin-bottom: 0px;
                }

                .pts {
                    font-weight: $fontWeight-bold;
                    font-size: 18px;
                    margin-bottom: 0px;
                }
            }

            .points-left {
                font-weight: 500;
                font-size: 14px;
                margin-bottom: 25px;
                @include brand-gradient
            }
        }
    }

    .use-rewards {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .reward-points {
            margin-bottom: 0px;
            font-weight: 500;
            font-size: 16px;
        }

        .switch {
            position: relative;
            display: inline-block;
            width: 60px;
            height: 28px;
        }

        .switch input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: .4s;
            transition: .4s;
        }

        .slider:before {
            position: absolute;
            content: "";
            height: 20px;
            width: 20px;
            left: 7px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
        }

        input:checked+.slider {
            background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
        }

        input:focus+.slider {
            box-shadow: 0 0 1px linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
        }

        input:checked+.slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
        }

        /* Rounded sliders */
        .slider.round {
            border-radius: 34px;
        }

        .slider.round:before {
            border-radius: 50%;
        }
    }

    .balance-holder {
        margin-top: 33px;
        margin-bottom: 41px;

        p {
            font-weight: $fontWeight-medium;
            font-size: 16px;
        }
    }

    .product-holder {
        display: flex;
        margin-bottom: 24px;

        .prod-img {
            // border: 1px solid #E0E0E0;
            border-radius: 32px 0px 0px 32px;
            height: 228px;

            img {
                object-fit: cover;
                border-radius: 32px 0px 0px 32px;
                width: 244px;
                height: 228px;
            }
        }

        .prod-info {
            padding: 24px;
            background: #FDFDFD;
            border: 1px solid #EDEDED;
            border-radius: 0px 32px 32px 0px;
            width: 100%;
        }

        .prod-info {
            h5 {
                font-weight: $fontWeight-medium;
                font-size: 20px;
                color: $color-black;
            }

            .line {
                margin: 10px 0px;
            }

            .details {
                p {
                    font-weight: $fontWeight-normal;
                    font-size: 14px;
                    color: $color-black;
                    margin-bottom: 10px;
                }
            }
        }

    }

    .product-holder-mobi {
        @media (max-width:767px) {
            display: block !important;
            margin-right: 16px;

            .prod-img {
                img {
                    width: 266px;
                    border-radius: 32px 32px 0px 0px;
                }
            }

            .prod-info {
                border-radius: 0px 0px 32px 32px;
                width: 266px;
                padding: 20px;

                h5 {
                    font-size: 18px !important;
                }

                // .details p {
                //     font-weight: 700;
                // }
            }
        }
    }

    .product-holder-details-mobi {
        @media (max-width:767px) {
            display: block !important;

            .prod-img {
                img {
                    width: 100%;
                    border-radius: 32px 32px 0px 0px;
                }
            }

            .prod-info {
                border-radius: 0px 0px 32px 32px;
                width: 100%;
                padding: 20px;

                h5 {
                    font-size: 18px !important;
                }

                // .details p {
                //     font-weight: 700;
                // }
            }
        }
    }

    .mobi-overflow {
        @media (max-width:767px) {
            overflow-x: auto;
        }
    }

    .buyer-details-mobi {
        @media (max-width:767px) {
            min-width: 274px;
            max-width: 274px;
            padding: 20px !important;
        }
    }

    .buyer-details {
        padding: 48px 42px 20px;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        border-radius: 24px;
        margin-bottom: 15px;

        @media (max-width:767px) {
            padding: 32px 24px 0px;

            .form-group {
                margin-right: 10px;
            }
        }

        h6 {
            font-weight: normal;
            font-size: 14px;
            color: #000000;
            text-align: center;
            margin-bottom: 26px;
        }

        .input-holder {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #FFFFFF;
            border: 2px solid #F8F8F8;
            border-radius: 24px;
            padding: 24px;
            margin-bottom: 26px;
            @media (min-width: 767px){
                position: relative;
            }
            .calender-modal {
                @media (max-width: 767px){
                    top: 10%;
                    left: 0;
                    width: auto;
                    margin: 0 10px;
                }
            }

            input {
                border: none;
                border-bottom: 1px solid #000;
                border-radius: 0px;
                font-weight: $fontWeight-bold;
                font-size: 18px;
                color: #000000;
                padding: 0;

                @media (max-width:767px) {
                    font-size: 16px;
                }
            }

            label {
                margin-bottom: 0px;
                font-weight: $fontWeight-normal;
                font-size: 14px;
                color: $color-black;
                margin-top: 8px;
            }

            .edit {
                cursor: pointer;
            }

            .form-control:disabled,
            .form-control[readonly] {
                background-color: #e9ecef4a;
                opacity: 1;
                padding: 5px !important;
            }

            .details {
                p {
                    color: $color-black;
                    margin-bottom: 0;

                    &:nth-child(1) {
                        font-weight: $fontWeight-bold;
                        font-size: 18px;
                        line-height: 23px;
                        border-bottom: 2px solid black;
                        width: max-content;
                        margin-bottom: 10px;
                        padding-bottom: 5px;

                        @media (max-width:767px) {
                            width: auto !important;
                            font-size: 14px;
                            word-break: break-all;
                        }


                    }

                    &:nth-child(2) {
                        font-weight: $fontWeight-normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
            }
        }
    }

    .packageInfo {
        padding: 24px !important;

        .input-holder {
            margin-bottom: 16px;
        }
    }

    .user-details {
        margin-top: 24px;

        .input-holder {
            background: #FFFFFF;
            border: 2px solid #F8F8F8;
            border-radius: 24px;
            padding: 24px;
            margin-bottom: 16px !important;

            .details {
                p {
                    &:nth-child(1) {
                        font-weight: $fontWeight-medium;
                        font-size: 16px;
                        color: $color-black;
                        margin-bottom: 10px;
                        padding-bottom: 6px;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.03);
                        @media (max-width:767px){
                            word-break: break-all;
                        }
                    }

                    &:nth-child(2) {
                        font-weight: $fontWeight-normal;
                        font-size: 13px;
                        color: $color-black;
                        opacity: 0.6;
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
}

.checkoutProd-img {
    img {
        width: 244px !important;
        object-fit: cover;
        border-radius: 32px 0px 0px 32px;
        height: 228px;
    }
}

.edit-package-dates {
    padding: 24px 24px 6px !important;

    @media (max-width:767px) {
        min-width: 330px;
        margin-top: 0 !important;
        padding: 20px 22px 6px !important;
        margin-bottom: 24px !important;
    }
}


.edit-specific-request {
    .form-group {
        border: 2px solid #F8F8F8;
        box-sizing: border-box;
        border-radius: 24px;
        padding: 20px 40px;
        text-align: center;
        margin-bottom: 24px;
        position: relative;

        @media (max-width:767px) {
            padding: 20px 25px;
        }

        label {
            margin-bottom: 0;
            font-weight: $fontWeight-medium;
            font-size: 18px;
            line-height: 23px;
            color: #000000;
            margin-top: 10px;
        }

        input {
            border-bottom: 1px solid #000000;
            border-top: none;
            border-right: 0;
            border-left: 0;
            border-radius: 0px;
            font-weight: $fontWeight-bold;
            font-size: 24px;
            line-height: 31px;
            text-align: center;
            color: #000000;

            &::placeholder {
                opacity: 0.5;
            }

        }
    }

    .specific-request {
        background: #F7F7F7;

        label {
            margin-bottom: 20px;
        }

        .custom-request {
            height: 111px;
            background: $color-white;
            border-radius: 16px;
            width: 100%;
            border: none;
            text-align: left;
            padding: 18px 12px;

            input {
                font-weight: $fontWeight-normal;
                font-size: 18px;
                padding: 0;
                line-height: 23px;
                color: #000;
                text-align: left;
                border-bottom: none;

                &::placeholder {
                    opacity: 0.5;
                }

                &:focus {
                    outline: 0;
                }
            }
        }

        .example {
            margin-top: 20px;

            p {
                margin-bottom: 0px;
                font-size: 12px;
                color: #000000;
                opacity: 0.5;
                line-height: 16px;
            }
        }
    }
}

.locations-list {
    p {
        font-size: 14px;
        margin-bottom: 10px;
    }
}

//events checkout

.events-checkout-page {
    .non-corporate-holder {
        .non-corporate-price {
            display: flex;
            justify-content: space-between;

            p {
                font-size: 14px;
                margin-bottom: 0px;
                color: $color-black;
            }
        }
    }

    .line {
        margin: 16px auto;
        width: 230px;
    }

    .total-price {
        color: $color-black;
        margin-bottom: 16px;

        .total {
            font-weight: 500;
        }

        .amount {
            font-weight: 700;
            font-size: 18px;
        }
    }

    .additional-charges {
        font-weight: 400;
        font-size: 12px !important;
        line-height: 16px;
        text-align: center;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 40px;
    }
}

.btn-fixed-mobi {
    @media (max-width:767px) {
        position: fixed;
        bottom: 23px;
        width: 85% !important;
        margin: 0 auto;
        right: 0;
        left: 0;
    }
}

.payment-mode-checkbox {
    margin-top: -35px !important;

    label {
        width: 100%;
        cursor: pointer;
    }
    .border-blue {
        border: 2px solid #600ABD;
        border-radius: 24px;
        margin-bottom: 16px;
        .check-btns {
            border: none;
            margin-bottom: 0px;
        }
    }

    .check-btns {
        background: #F8F8F8;
        border: 2px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 24px;
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        padding: 18px 24px;

        .checkBtn-info {
            margin-left: 34px;

            p,
            h5 {
                margin-bottom: 0px;
            }

            h5 {
                font-weight: 700;
                font-size: 18px;
                line-height: 23px;
                color: #000000;
                margin-bottom: 8px;

                @media (max-width:767px) {
                    font-size: 16px;
                }
            }

            p {
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: rgba(0, 0, 0, 0.5)
            }
        }

    }

    .check-btns {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .check-btns input {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        cursor: pointer;
        height: 28px;
        width: 28px;
        z-index: 999;
    }

    .checkmark {
        position: absolute;
        top: 50%;
        left: 20px;
        height: 28px;
        width: 28px;
        background-color: #C9C9C9;
        border-radius: 50%;
        transform: translateY(-50%);
    }

    .check-btns:hover input~.checkmark {
        background-color: #ccc;
    }

    .check-btns input:checked~.checkmark {
        background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
    }

    // .check-btns input:checked   {
    //     border: 2px solid blue !important;
    // }    

    .checkmark:after {
        content: "";
        position: absolute;
        // display: none;
    }

    .check-btns input:checked~.checkmark:after {
        display: block;
        background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
    }

    .check-btns .checkmark:after {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #ccc;
        border: 2px solid #fff
    }

}
.textOfUser{
    display: none;
}