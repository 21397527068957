@import './variables';
@import './mixins';

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
label,
select,
option,
input,
textarea,
button,
a,
::placeholder,
body {
    font-family: $font-DMsans;
}

html,
body {
    overflow-x: hidden !important;
    @media (min-width:767px) {
        display: unset;
    }
}

body {
    width: 100%;
}

// buttons
button:focus {
    outline: none !important;
}

.btn:focus {
    box-shadow: none !important;
}

.primary--btn {
    background: $color-dark !important;
    border-radius: 24px !important;
    color: $color-white !important;
    font-size: $fontSize-smaller + 2 !important;
    text-align: center !important;
}

.submit--btn {
    background: linear-gradient(91.61deg, #600ABD 3.87%, #115297 100%) !important;
    border-radius: 24px !important;
    text-align: center !important;
    color: $color-white !important;
    font-size: $fontSize-large !important;
}

.dropdown--btn {
    background: #FBFBFB !important;
    border: 1px solid #EBEBEB !important;
    box-sizing: border-box;
    border-radius: 24px !important;
    font-size: 18px;
    line-height: 23px !important;
    text-align: center;
    color: #000000 !important;
    height: 60px;
    padding: 0px 20px 0px 27px;
    display: flex !important;
    align-items: center;
}

.outline--btn {
    border-radius: 16px;
    font-weight: $fontWeight-medium;
    font-size: $fontSize-smaller;
    border: 2px solid $color-whitesBase;
    color: $color-whitesBase;
    padding: 8px 16px;

    &:hover {
        color: $color-whitesBase;
    }
}

.signup--btn {
    border-radius: 16px;
    font-weight: $fontWeight-medium;
    font-size: $fontSize-smaller;
    background: $color-whitesBase;
    border: 2px solid $color-whitesBase;
    color: $color-black !important;
    padding: 8px 16px;

    &:hover {
        color: $color-black;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.owl-theme .owl-nav [class*='owl-']:hover {
    background: none !important;
}

.owl-theme .owl-nav .disabled {
    display: none !important;
}

//
.form-control:focus {
    outline: none !important;
    box-shadow: none !important;
}

.back-button {
    background: #FBFBFB;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media (max-width:767px) {
        width: 48px;
        height: 48px;

    }
}

.back-button-mobi {
    @media (max-width: 767px) {
        position: absolute;
        top: 16px;
        left: 16px;
        z-index: 9;
    }
}

.select-points {
    label {
        background: #FBFBFB;
        border: 1px solid #EBEBEB;
        box-sizing: border-box;
        border-radius: 24px;
        text-align: center;
        width: auto;
        padding: 18px 28px;
        margin-right: 12px;
        font-weight: $fontWeight-medium;
        font-size: 18px;
        color: $color-black;
        cursor: pointer;

        @media (max-width:767px) {
            font-size: 14px;
            padding: 12px 20px;
        }
    }

    input[type="radio"] {
        display: none;

        &:checked {
            + {
                label {
                    background: #E9E9E9;
                    border: 2px solid #000000;
                }
            }
        }
    }
}
.failure-text{
    font-weight: 700;
    text-transform: capitalize;
}
.innerPage-header {
    .header-holder {
        position: absolute;
        background: #fff;
        box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.04);
        padding: 15px 5%;
        height: 75px;
        .main-header-search{
            input {
                color:#263238;
                border: 1px solid #d3d3d3;
            }
        }

        @media (min-width:768px) and (max-width:1199px) {
            padding: 28px 5%;
        }
        @media (min-width:768px) {
            position: fixed;
        }

    }
    input.react-autosuggest__input {
        border: 1px solid #ddd;
    }
    .bm-burger-button {
        position: fixed;
        border: 1px solid #ddd;
    }
    .search_btn{
        @media only screen and (min-width: 767px) and (max-width: 991px) {
        top:15px;
        }
        background: transparent;
        border-radius: 50px;
        border: 1px solid #ddd;
        
    }
}

.innerPage-container {
    margin-top: 140px;
}

.innerPage-container-mobi {
    @media (max-width:767px) {
        margin-top: 0px;
    }
}

.proceed-to-book {
    background: linear-gradient(91.61deg, #600ABD 3.87%, #115297 100%);
    border-radius: 24px;
    padding: 24px 46px;
    font-size: 24px;
    line-height: 31px;
    color: #FFFFFF;
    font-weight: $fontWeight-bold;

    &:hover {
        color: #FFFFFF;
    }

    @media (max-width:545px) {
        font-size: 20px;
        padding: 18px 44px;
    }
}

.book-now {
    background: linear-gradient(91.61deg, #600ABD 3.87%, #115297 100%);
    border-radius: 24px;
    padding: 15px;
    font-size: 18px;
    line-height: 31px;
    color: #FFFFFF;
    font-weight: $fontWeight-bold;

    &:hover {
        color: #FFFFFF;
    }

    @media (max-width:991px) {
        display: none;
    }
}

.sticky-contact {
    position: sticky;
    top: 120px;
}

.errorMsg {
    color: red;
    font-size: 12px !important;
    margin-bottom: 0px;
    font-weight: 400 !important;
    text-align: left;
    display: block;
}

.payment-status {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    .payment-details {
        margin-top: 36px;

        p {
            margin-bottom: 10px;
            color: $color-black;
        }

        .status {
            font-weight: $fontWeight-bold;
            font-size: 20px;
        }

        .booking-for {
            font-weight: $fontWeight-normal;
            font-size: 16px;

            span {
                font-size: 18px;
            }
        }

        .transactionId {
            font-weight: $fontWeight-normal;
            font-size: 16px;
            opacity: 0.5;
        }

        .message {
            font-weight: $fontWeight-medium;
            font-size: 18px;
            line-height: 23px;

            @media (max-width:767px) {
                font-size: 16px;
                line-height: 23px;
                width: 90%;
                margin: 0 auto;
            }
        }
    }
}

.w-100-vw {
    width: 100vw;
}

.login-page-otp {
    .input-container {
        a {
            @include brand-gradient;
            font-weight: 400 !important;
            cursor: pointer;
        }
    }
}

.btn-goto-home {
    background: #000000;
    border-radius: 24px;
    box-shadow: none;
    border: 1px solid transparent;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #FFFFFF;
    font-weight: 700;
    padding: 16px 60px;
    margin-top: 50px;
}

// loader styles

.lottie-loader {
    height: 100vh;
    position: relative;
    background: #fff;
    z-index: 9999;

    .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

// date picker custom styles

.calendar--days span.start,
.calendar--days span.end {
    background: #000 !important;
}
.calendar--days span.start-specific {
    background: #000;
    border-radius: 12px !important;
    color: #fff !important;
}

 .enable-specific {
    cursor: pointer !important;
    color: #000 !important;
    pointer-events: auto !important;
  }

  .calendar--days span.flexi-date {
    background: #000;
    border-radius: 12px !important;
    color: #fff !important;
}

 .flexi-date {
    cursor: pointer !important;
    color: #000 !important;
    pointer-events: auto !important;
  }

//

.form-control:focus {
    background-color: transparent !important;
}

// terms and conditions

.terms-conditions-content {
    .content-wrapper {
        h4 {
            font-weight: 700;
            font-size: 26px;

            @media (max-width:767px) {
                font-size: 22px;
            }
        }
    }
}

// mobile view forms

.openModal-mobile {
    top: 0;
    left: 0;
    position: fixed;
    overflow: auto;
    background: #22222275;
    z-index: 22;
    height: 100%;
    width: 100%;

    .openModal-holder {
        background-color: #ffffff;
        position: relative;

        .closeIcon-holder {
            position: absolute;
            top: 22px;
            right: 25px;
        }

        @media (max-width:767px) {
            margin-top: 150px;
        }
    }

    @media (min-width:768px) {
        display: none;

    }

    @media (max-width:767px) {
        display: block;
    }
}

.bookThis-desktop {
    @media (min-width:768px) {
        display: block;
    }

    @media (max-width:767px) {
        display: none;
    }
}


.padding-20-mobi {
    @media (max-width:545px) {
        padding: 20px !important;
    }
}

.owl-dots {
    display: flex;

    .owl-dot {
        width: 10px;
    }
}

.save--btn {
    background: #F8F8F8;
    border: 1px solid #E0E0E0;
    border-radius: 16px;
    font-weight: 500 !important;
    font-size: 16px !important;
    color: #000;
    padding: 6px 16px;
    width: auto;
    margin-bottom: 0px !important;
    cursor: pointer;

    @media (max-width:767px) {
        font-size: 12px !important;
    }
}

.employee-sigup {
    background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    border: 2px solid #600ABD !important;
}

.open-maps {
    margin-left: 20px;
    color: #1081FA;
    cursor: pointer;
    @media (max-width:767px){
        margin-left: 12px;
    }
    img {
        margin-left: 3px;
    }
}
.px-dummy {
   @media (max-width:767px){
       padding: 0px 6px;
   }
}

.intl-phone-input {
    padding: 0px !important;
    border: none !important;
    margin-bottom: 0 !important;
}
.intl-phone-input > .PhoneInput {
    padding: 0 !important;
    border: none !important;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
    background: transparent;
    height:calc(1.5em + .75rem + 2px);
}

.intl-phone-input > label {
    padding:0px  !important;
}

.confirmation-btn {
    display: flex;
    justify-content: space-between;
    button{
        padding: 10px 30px;
        border: 0px solid;
        background-color: #000000;
        color: #fff;
        border-radius: 25px;
        font-size: 15px;
        width: 50%;
        margin: 0 5px;
    }
    .corporate{
        background-color: transparent;
        color: #000;
        border: 2px solid #000;
    }
}

.shake {
    animation: shake 1s 1;
  }
  
  @keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }


//   0%, 100% {transform: translateX(0);} 
//    10%, 30%, 50%, 70%, 90% {transform: translateX(-3px);} 
//    20%, 40%, 60%, 80% {transform: translateX(3px);} 
  }

.animate-fadein {animation: 0.5s ease-out 0s 1 FadeIn;}

@keyframes FadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
}

.owl-carousel a:hover, .corporatePackages-holder a:hover {
    text-decoration: none !important;
}

.ham-nav {
    margin-top: 15px;
}
.ham-nav li {
    list-style-type:none;
}
.ham-nav li a{
    color: black;
    padding: 8px 5px;
    font-weight: bold;
    font-size: 20px;
    display: block;
}
.ham-nav li a:hover{
    text-decoration: none;

}

.hamm-menu .ham-logout-btn:hover, .hamm-menu .yo2:hover {
    color:white;
}
.hamm-menu .yo1:hover {
    background:white;
    color:#212529;
}
.ham-nav ul, .ham-nav li {
    margin:0;
    padding: 0 14px;
    list-style: none;
    margin-bottom: 16px;
    margin-left: 2px;
}
.thin-list li a {
    font-weight: lighter;
}

.ham-contact-btn {
    background-color: #FAFAFA;
    width: 265px;
    height: 63px;
    color: #115297;
    text-transform:uppercase;
    font-weight: bold;
    border-radius: 15px;
    margin-bottom: 15px;
    
}

.ham-mybookings {
    background: #FAFAFA;
    padding: 5px 8px;
    position: static;
    left: 36px;
    margin: 9px 20px !important;
    border-radius: 20px;
    font-weight: bold;
}
.ham-logout-btn {
    background-color: #000;
    width: 265px;
    height: 63px;
    color: #ffffff;
    border-radius: 15px;
    margin-bottom: 30px;
}
.profile-ham-menu {
    background: #FAFAFA;
    background: #FAFAFA;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    position: static;
    height: 94px;
    left: 36px;
    top: 0px;
    margin: 0 20px;
    margin-top: 20px;
    border-radius: 20px;

}


.ham-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    background-color: white;
    height: 48px;
    width: 48px;
    z-index: 9998;
    color: black;
    padding: 5px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;

    i {
        color:black
    }
}

.hamm-menu {
    position: fixed;
    top: 0;
    visibility: visible;
    margin-bottom: 40px;
}

// .ham-menu {
//     z-index: 9999;
//     position: fixed;
//     top:0;
//     right:0;
//     background-color: white;
//     width: 100%;
//     height: 100vh;
// }




/* Position and sizing of burger button */
.bm-burger-button {
    position: absolute;
    width: 48px;
    height: 48px;
    // right: 15px;
    // top: 15px;
    z-index: 999 !important;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    top: 16px;
    @media (min-width: 1024px){
        top: 22px;
    }
    right: 40px;
  }
  
  /* Color/shape of burger icon bars */
//   .bm-burger-bars {
//     background: black;
//     margin: 5px;
//   }
  
//   /* Color/shape of burger icon bars on hover*/
//   .bm-burger-bars-hover {
//     background: black;
//   }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
    display: none !important;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
    display: none;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
//   .bm-menu-wrap {
//     position: fixed;
//     height: 100%;
//   }
  
.bm-menu-wrap {
    transition: all 0.180s ease 0s !important;
  }
  /* General sidebar styles */
  .bm-menu {
    background: #ffffff;
    // padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #ffffff;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top:0;
    overflow: hidden
  }

  .grad-text {
    background: linear-gradient(
        98.07deg, #115297 5.22%, #600ABD 101.6%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .ham-icon {
    
    top: 25px;
    border: 1px solid #cbcbcb;
    font-size: 16px;
    width: 48px;
    height: 48px;
  }

  .bm-cross-button {
    right: 35px !important;
    top: 25px !important;
    border: 1px solid #cbcbcb;
    font-size: 16px;
    width: 25px !important;
    height: 25px !important;
    border-radius: 50%;
    background: linear-gradient(
        98.07deg, #115297 5.22%, #600ABD 101.6%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .form-control[readonly] {
      background: none !important;
  }

  jodit, jodit > iframe {
      max-width: 100%;
  }

  .subscribe-holder{
      padding: 60px 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #115297;
      opacity: 0.9;
      border-radius: 20px;
      margin-top: 112px;
      @media (min-width:300px) and (max-width:411px) {
          padding: 40px 15px;
          flex-direction: column;
      }
      @media (min-width:411px) and (max-width:767px) {
          flex-direction: column;
          padding: 50px 30px;
          margin-top: 65px;
          border-radius: 32px;
      }

      .heading{
        color: #fff;
        @media(max-width: 767px){
            text-align: center;
        }
        h1{
            font-weight: 700;
            font-size: 26px;
            line-height: 34px;
            @media (min-width:300px) and (max-width:411px) {
                font-size: 22px;
                line-height: 28px;
                font-weight: 600;
            }
            @media (min-width:411px) and (max-width:767px) {
                font-size: 24px;
                line-height: 28px;
                font-weight: 600;
            }
        }
        p{
            font-weight: 400;
            font-size: 16px;
            line-height: 21px;
            margin-bottom: 0;
        }
      }
      .subscribe-button{
            padding: 16px 50px;
            cursor: pointer;
            background: #FBFBFB;
            border-radius: 24px;  
            @media(max-width: 767px){
                margin-top: 30px;
                padding: 14px 40px;
            }
        span{
            
            font-weight: 500;
            font-size: 20px;
            line-height: 26px;
            :hover{
                box-shadow: 1px 10px 60px rgb(242, 16, 16);
            }
          }
      }
  }

  .subscribe-modal{
      .modal-content{
          border: none;
          border-radius: 8px;
      }
  }
  .subscribe-popup {


    
        h1{
            font-weight: 700;
            font-size: 22.496px;
            line-height: 29px;
        }
        p {
            font-weight: 400;
            font-size: 13.8437px;
            line-height: 18px;
        }
    
    .form-group{
        input{
            border: 0.865232px solid #E0E0E0 !important;
            border-radius: 20.7656px;
            width: 100% !important;
            padding: 10px 25px !important;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #302B2B !important;
            opacity: 1;
            height: 50px;
        }
    }
    .btn{
        background: linear-gradient(91.61deg,#600abd 3.87%,#115297);
        border-radius: 20px;
        padding: 14px 25px;
        font-weight: 700;
        font-size: 15.5742px;
        line-height: 20px;
        color: #fff;
        font-weight: 700;
        width: 100%;
    }
    .errorMsg{
        margin-top: 10px;
        padding-left: 10px;
    }
    

    
}
.close-wrap{
    display: inline-flex;
    position: absolute;
    right: 0;
    cursor: pointer;
    img{
        width: 25px;
        height: 25px;
    }

    .cross-icon{
        background: #FFFFFF;
        border: 2px solid #F7F7F7;
        border-radius: 60px;
        padding: 5px;
        margin: 10px;
    }
}