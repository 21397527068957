// Font sizes
// -----------------------------------------------------------------------------

$fontSize-base:                 1rem; 
$fontSize-hero:                 50px;
$fontSize-largest:              28px;
$fontSize-larger:               26px;
$fontSize-large:                24px;
$fontSize-small:                20px;
$fontSize-smaller:              16px;
$fontSize-smallest:             14px;
$fontSize-tiny:                 12px;


// Line heights
// -----------------------------------------------------------------------------

$lineHeight-base:               1.5; // 3
$lineHeight-hero:               50px;
$lineHeight-largest:            37px;
$lineHeight-larger:             32px;
$lineHeight-large:              29px;
$lineHeight-small:              24px;
$lineHeight-smaller:            19px;
$lineHeight-smallest:           15px;
$lineHeight-tiny:               18px;


// Font weights
// -----------------------------------------------------------------------------
$fontWeight-normal:             400;
$fontWeight-medium:             500;
$fontWeight-bold:               700;

// Letter spacing
// -----------------------------------------------------------------------------

$letterSpacing-base:            0;
$letterSpacing-largest:         0.25px;
$letterSpacing-larger:          0.25px;
$letterSpacing-large:           0.25px;
$letterSpacing-small:           0.25px;
$letterSpacing-smaller:         0.25px;
$letterSpacing-smallest:        0.25px;

// Spacing Rhythm
// -----------------------------------------------------------------------------

// Base Spacing
$spacingRhythm:                 $fontSize-base * $lineHeight-base !default; //24px
 
$spacing-base:                  1rem !default; //16px
$spacing-single:                $spacingRhythm !default; //24px
$spacing-double:                $spacingRhythm * 2 !default; //48px
$spacing-half:                  $spacingRhythm / 2 !default; //12px
$spacing-third:                 $spacingRhythm / 3 !default; //8px
$spacing-quarter:               $spacingRhythm / 4 !default; //6px
$spacing-fifth:                 $spacingRhythm / 5 !default; //4.8px
$spacing-sixth:                 $spacingRhythm / 6 !default; //4px
$spacing-eighth:                $spacingRhythm / 8 !default; //3px



// =============================================================================
// COLORS
// =============================================================================


// Basics
// -----------------------------------------------------------------------------

$color-black:                       #000 !default;
$color-white:                       #fff !default;

$color-whitesBase:                  #FBFBFB !default;

$color-grey:                        #798289 !default;
$color-greyDarkest:                 #232831 !default;
$color-greyDarker:                  #282c30 !default;
$color-greyDark:                    #556273 !default;
$color-greyMedium:                  #acb3be !default;
$color-greyLight:                   #e1e6eb !default;
$color-greyLighter:                 #f2f4f6 !default;
$color-greyLightest:                #f7f8f9 !default;

// Brand colors
// -----------------------------------------------------------------------------

$color-primary:                     #115297 !default;
$color-secondary:                   #600ABD !default;
$color-success:                     #47E6B1 !default;
$color-error:                       #E03A3A !default;
$color-dark:                        #2D2D2D !default;


// Font family
// -----------------------------------------------------------------------------

$font-DMsans: 'DM Sans', sans-serif !important;
