.login{
   display: flex;
   justify-content: center;
   align-items: center;
}
.auth-wrapper {
   background-color: #f6f6f6;
   margin: 0px;
}
.login-banner-content{
   background-image: url('../../assets/images/authImages/login-banner-bg.jpg');
   background-size: 100%;
   background-position: center;
   height: 100vh;
   min-height: 600px;
   display: flex;
   justify-content: center;
   
}
.vectors{
   position: absolute;
   left: 0;
   top: 0;
   right: 0;
   bottom: 0;
   width: 100%;
   height: 100%;
   z-index: 1;
}
.vectors img{
   width: 100%;
   height: 100%;
}
.login-logo img{
   display: block;
}
.login-logo{
   font-weight: 800;
   margin-bottom: 44px
}
.login-text{
   display: flex;
   flex-direction: column;
   justify-content: center;
   color: #fff;
   max-width: 245px;
   z-index: 2;
}
.login-text-subheading{
   font-size: 28px;
   font-weight: 800;
}
.login-auth-content-block{
   margin: 20px auto;
   max-width: 50%;
}
/* .auth */
.login-auth-content{
   margin: 0px;
   padding: 0px;
}
.auth-left-icon{
  margin-bottom: 30px;
}
.auth-toggle-button ul{
   background-color: #f1f1f1;
   border-radius: 15px;
   padding: 3px;
}
.auth-toggle-button ul li{
   flex: 1;
   text-align: center;
}
.auth-toggle-button ul.nav-pills li a.nav-link{
   border-radius: 15px;
   color: #606060;
   font-size: 24px;
}
.auth-toggle-button ul.nav-pills li a.nav-link.active{
   background-color: #ffffff;
   color: #000000;
   font-weight: 500;
}
/* input form */
.card-box{
   background-color: #ffffff;
   box-shadow: 0px 0px 13px 2px #eee;
   border-radius: 20px;
   padding: 35px 15px;
}
.input-container{
   padding: 5px 20px;
   background-color: #f1f1f1;
   border-radius: 15px;
   margin-bottom: 20px;
}
.input-container input{
   width: 100%;
   border: none;
   border-bottom: 1px solid #e3e3e3;
   padding: 10px 0px;
   background-color: transparent;
}

.input-container input:focus{
   background-color: none;
}

.input-container input::placeholder { 
   color: #979797;
   opacity: 1;
 }
 
.input-container input:-ms-input-placeholder { 
   color: #979797;
 }
 
.input-container input::-ms-input-placeholder { 
   color: #979797;
}
.input-container input:focus, .input-container input:hover{
   outline: none;
}
.input-container label{
   padding: 10px 0px;
   margin: 0px;
   font-size: 12px;
   color: #b0b0b0;
}
.input-container a{
   padding: 10px 0px;
   margin: 0px;
   font-size: 12px;
   color: #b0b0b0;
   text-decoration: none;
   color: blue;
}
.otp-btn-container{
   display: flex;
   justify-content: center;
   align-items: center;
}
.btn-common{
   background-color: #2d2d2d;
   color: #ffffff;
   border-radius: 20px;
   border: 0px;
   padding: 12px 40px;
}
.login-footer{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}
.login-footer-icon{
   display: flex;
   margin-top: 10px;
}
.login-footer .social-icon-cont{
   background-color: #f6f6f6;
   width: 50px;
   height: 50px;
   border-radius: 50%;
   padding: 13px;
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 0px 10px;
   cursor: pointer;
}

.otp-timer{
   text-align: center;
}
.otp-timer span{
   color: red;
}
.modal-wrapper{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   position: absolute;
   width: 100%;
   height: 100%;
   left: 0;
   top: 0;
}
.overlay-modal{
   position: absolute;
    right: 0%;
    width: 309%;
    height: 135%;
    opacity: 0.4;
    background-color: #000000;
    z-index: 1;
}
.card-box-index{
   z-index: 2;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 460px;
   padding: 50px;
   position: absolute;
   right: 65%;
}
.card-box-index hr{
   width: 100%;
}

.show {
   display: flex;
}

.hide {
   display: none;
}
.input-container label.error{
   color: red;
   font-size: inherit
}
/* .overflow-image{
   position: absolute;
   left: 0;
   right: 0;
   bottom: 0;
   top: 0;
   width: 100%;
   height: 100%;
   z-index: 0
}
.overflow-image img{
   width: 100%;
   height: 100%;
}
.banner-content{
   z-index: 1;
   height: 100vh;
   display: flex;
} */

