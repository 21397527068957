@import '../../variables';
@import '../../mixins';

.userProfile-holder {
    .profile-holder {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50%;
        z-index: 99;
        .checkout-title{
            width: 100%;
    margin-top: 22px;
    font-size: 40px;
    font-weight: 700;
    color: #ffff;

        }
        .image-wrapper {
            position: relative;
            width: max-content;
            margin: 0 auto;
            .corp-logo{
                img{
                    width: 50px ;
                    margin-bottom: 5px;
                    border-radius: 20px;
                    @media (max-width: 767px){
                        margin-bottom: 70px;
                        width: 60px ;
                    }
                }
            }
            .edit-image {
                position: absolute;
                top: 50%;
                left: 50%;
                cursor: pointer;
                transform: translate(-50%, -50%);
                display: none;

                .upload-file {
                    position: absolute;
                    left: 0;
                    top: 0;
                    opacity: 0;
                    cursor: pointer;
                    width: 50px;
                    height: 50px;
                    z-index: 9999;
                }
            }

            &:hover {
                .edit-image {
                    display: block;
                }
            }
        }

        .profile-image {
            background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
            border-radius: 100%;
            padding: 4px;
            width: 75px;
            height: 75px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto 0px;
            @media (max-width:767px){
                width: 72px;
                height: 72px;
                margin: 10px auto 5px;
            }
            img {
                width: 65px;
                height: 65px;
                border-radius: 100%;
                object-fit: cover;
                @media (max-width:767px){
                    width: 62px;
                    height: 62px;
                }
            }

        }

        .character-gradient {
            background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
            border-radius: 100%;
            padding: 4px;
            width: 75px;
            height: 75px;
            margin: 0 auto 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width:767px){
                 width: 72px;
                 height: 72px;
                 margin: 10px auto 5px;
            }

            .image-char {
                height: 65px;
                width: 65px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #F8F8F8;
                border-radius: 100%;
                margin: 0 auto;
                @media (max-width:767px){
                    width: 62px;
                    height: 62px;
                }

                p {
                    margin: 0;
                    font-size: 34px;
                    font-weight: 700;
                    background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
                    -webkit-background-clip: text;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }

        .fullName {
            @media (min-width: 1200px){
                .form-group{
                    margin-bottom: 0;
                }
            }
            
            input {
                @include main-heading;
                font-size: 40px;
                text-align: center;
                color: $color-white;
                margin-bottom: 0px;
                background: transparent;
                border: none;
                // height: 60px;
                text-decoration: underline;
                text-underline-position: under;
                @media (max-width:767px){
                    font-size: 22px;
                    height: 35px;
                }
                span {
                    @include light-gradient;
                }
            }

            .form-control:disabled,
            .form-control[readonly] {
                text-decoration: unset !important;
            }

            .edit-icon {
                text-align: center;
                @media (max-width:767px){
                    margin-top: -10px;
                }
            }

            .editName-btn {
                background: #FBFBFB;
                border-radius: 16px;
                font-weight: $fontWeight-medium;
                font-size: 16px;
                color: $color-black;
                padding: 10px 20px;
                width: auto;
            }
        }
    }
}

.profile-tabs {
    margin-top: 40px;
    @media (max-width:767px){
        margin-top: 10px;
    }
    .nav-tabs {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        margin-bottom: 53px;
        @media (max-width:767px){
            margin-bottom: 40px;
        }
    }

    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
        background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        opacity: 1;
        border: none;
        border-bottom: 2px solid #600ABD;
    }

    .nav-tabs .nav-link {
        font-size: 28px;
        font-weight: $fontWeight-medium;
        line-height: 36px;
        text-align: center;
        color: #000;
        opacity: 0.3;
        padding: 8px 31px 20px;
        @media (max-width:767px){
            font-size: 18px;
            padding: 8px 16px 10px;
        }
    }
}

.profile--details {
    .contact-info {
        @include hammock-custom-border;
        padding: 58px 48px 42px;
        @media (max-width:767px){
            padding: 36px 20px;
        }
        h5 {
            font-weight: $fontWeight-bold;
            font-size: 24px;
            line-height: 31px;
            color: $color-black;
            @media (max-width:767px){
                font-size: 22px;
                font-weight: 500;
                opacity: 0.6;
                text-align: center;
            }
           
        }

        .line {
            margin: 16px 0px;
        }

        .details-card {
            @include profile-card-bg;
            margin-bottom: 16px;

            .details {
                width: 85%;

                p {
                    color: $color-black;

                    &:nth-child(1) {
                        font-weight: $fontWeight-bold;
                        font-size: 18px;
                        margin-bottom: 12px;
                        @media (max-width:767px){
                            word-break: break-all;
                            font-size: 16px;
                        }
                    }

                    &:nth-child(2) {
                        font-weight: $fontWeight-medium;
                        font-size: 14px;
                        line-height: 18px;
                        margin-bottom: 0
                    }
                }
            }
            .editIcon {
                @media (max-width:767px){
                    width: 34px;
                    height: 34px;
                }
            }
        }

        .address-card {
            @include profile-card-bg;
            margin-bottom: 16px;

            .details {
                p {
                    color: $color-black;
                    font-weight: $fontWeight-normal;

                    &:nth-child(1) {
                        font-size: 18px;
                        margin-bottom: 12px;
                    }

                    &:nth-child(2) {
                        opacity: 0.6;
                        font-size: 14px;
                        margin-bottom: 0
                    }

                }
            }
        }

        .add-address {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 14px 24px !important;
            @include profile-card-bg;

            .new-address {
                margin-bottom: 0px;
            }
        }
    }

    .my-rewards {
        @include hammock-custom-border;
        padding: 58px 54px 62px;
        margin-bottom: 16px;
        @media (max-width:767px){
            padding: 42px 20px;
        }
        h5 {
            font-weight: $fontWeight-medium;
            font-size: 24px;
            line-height: 31px;
            color: $color-black;
            margin-bottom: 56px;
            @media (max-width:767px){
                opacity: 0.6;
                margin-bottom: 40px;
            }
        }

        .balance {
            p {
                &:nth-child(1) {
                    color: $color-black;
                    font-weight: $fontWeight-medium;
                    font-size: 24px;
                    margin-bottom: 12px;
                    @media (max-width:767px){
                        font-size: 20px;
                    }
                }

                &:nth-child(2) {
                    color: #600ABD;
                    font-weight: $fontWeight-medium;
                    font-size: 18px;
                    @media (max-width:767px){
                        font-size: 16px;
                        margin-bottom: 32px;
                    }
                }
            }
        }

        .line {
            margin: 56px 0px 32px;
            @media (max-width:767px){
                display: none;
            }
        }
    }
}

.custom-modal {
    .modal-dialog {
        top: 50%;
        transform: translateY(-55%) !important;
    }

    .modal-content {
        padding: 48px 32px;
        @include hammock-custom-border
    }

    .modal-header {
        padding: 0px 0px 24px 0px;
    }

    .modal-title {
        font-weight: $fontWeight-medium !important;
        font-size: 26px !important;
    }

    .modal-body {
        padding: 0;
        margin-top: 24px;
    }

    .custom-input {
        @include hammock-border-bg;
        padding: 14px 24px;

        textarea,
        input {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
            border-radius: 0;
            border-top: transparent;
            border-right: transparent;
            border-left: transparent;
            background-color: transparent;
            padding: 0px;
            font-size: 18px;
            color: #000000;
        }

        label {
            font-weight: $fontWeight-normal;
            font-size: 14px;
            color: $color-black;
            opacity: 0.5;
            margin-top: 12px;
        }
    }

    .btn-delete {
        background: $color-white;
        border-radius: 24px;
        padding: 16px 40px;
        font-weight: $fontWeight-bold;
        font-size: 18px;
        line-height: 23px;
        color: #2D2D2D;
        border-color: #2D2D2D;

        &:hover,
        &:active {
            background: $color-white !important;
            border-color: #2D2D2D !important;
            color: #2D2D2D !important;
        }
        @media only screen and(max-width: 767px){
            margin-bottom: 10px;
            padding: 10px 40px;
            border-radius: 20px;

        }
       
    }

    .btn-done {
        background: #2D2D2D;
        border-radius: 24px;
        padding: 16px 40px;
        font-weight: $fontWeight-bold;
        font-size: 18px;
        line-height: 23px;
        border-color: #2D2D2D;
        color: $color-white;
        width: auto;

        &:hover,
        &:active {
            background: #2D2D2D !important;
            color: $color-white;
        }
        @media only screen and (max-width: 767px){
            border-radius: 20px;
            padding: 10px 40px;
        }
    }
}

.my-bookings {
    .card-wrapper {
        margin-bottom: 24px;
        height: 255px;
        @media (max-width:767px){
            display: block !important;
            height: auto;
            margin-left: 0px;
        }
        .img-holder {
            border-radius: 32px 0px 0px 32px;
            width: 244px;
            @media (max-width:767px){
                border-radius: 32px 32px 0px 0px;
                width: 100%;
            }
            img {
                border-radius: 32px 0px 0px 32px;
                height: 255px;
                object-fit: cover;
                @media (max-width:767px){
                    border-radius: 32px 32px 0px 0px;
                    width: 100%;
                    height: 274px;
                }
            }
        }
       .internal-info{
        border-radius: 32px !important;
        cursor: auto !important;
       }
        .info {
            background: #FDFDFD;
            border: 1px solid #EDEDED;
            border-radius: 0px 32px 32px 0px;
            width: 100%;
            padding: 22px 24px;
            overflow-y: auto;
            @media (max-width:767px){
                border-radius: 0px 0px 32px 32px;
                width: 100%;
                padding: 20px;

            }
            h5 {
                font-weight: $fontWeight-medium;
                font-size: 18px;
                color: $color-black;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }

            p {
                font-weight: $fontWeight-normal;
                font-size: 14px;
                color: #000000;
                margin-bottom: 8px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }

            .line {
                margin: 10px 0px;
            }

            .status {
                font-weight: normal;
                font-size: 14px;
                color: #600ABD;
                margin-bottom: 8px;

                span {
                    color: $color-black;
                    text-transform: capitalize;
                }
            }

            .price {
                font-weight: $fontWeight-bold;
                font-size: 14px;
                color: $color-black;
            }
        }
    }
}
@media only screen and (max-width: 767px){
    .mob-custom-modal .modal-content{
       margin-top: 30%;
    }
}

.booking-image {
    @media (max-width:767px){
        margin: 0px -15px 32px;
    }
}
.bookings-contact {
    .closeIcon {
        @media (max-width:767px){
            display: none !important;
        }
    }
}
