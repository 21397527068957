@import '../variables';
@import '../mixins';

.slider-holder {
    margin-top: 100px;

    h4 {
        font-weight: $fontWeight-medium;
        font-size: 42px;
        margin-bottom: 28px;

        span {
            @include brand-gradient;
        }
    }

    .carousel-wrapper {
        overflow: hidden;
        

        .card-wrapper {
            border-radius: 48px;
            width: 100%;
            img {
                border-radius: 48px 48px 0px 0px;
                height: 352px;
                object-fit: cover;
                @media(max-width: 767px){
                    height: 250px;
                    border-radius: 32px 32px 0px 0px;
                }
            }

            .info {
                background: #F8F8F8;
                text-transform: capitalize;
                padding: 18px 28px;
                border-radius: 0px 0px 48px 48px;
                height: 134px;
                @media(max-width: 767px){
                    height: 116px;
                    border-radius: 0px 0px 32px 32px;
                    padding: 18px 20px 18px;
                }

                p {
                    font-weight: $fontWeight-normal;
                    color: $color-black;

                    &:nth-child(1) {
                        font-size: 20px;
                        line-height: 28px;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        @media(max-width: 767px){
                            font-size: 18px;
                            margin-bottom: 0;
                            line-height: 26px;
                        }

                    }

                    &:nth-child(2) {
                        font-size: $fontSize-smallest;
                        opacity: 0.5;
                        line-height: 18px;
                    }
                }

     
                }
        }

        .see-all {
            border: 4px solid #E0E0E0;
            box-sizing: border-box;
            border-radius: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 486px;
            cursor: pointer;
            @media (max-width: 767px) {
                height: 366px;
            }
            p {
                margin-bottom: 0px;
                font-weight: 500;
                font-size: 20px;
                color: #000000;
                text-align: center

            }
        }

        .owl-carousel .owl-stage-outer {
            @include container-paddingLeft;
            
        }

        .owl-next {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(50%, -50%);
        }

        .owl-prev {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(-50%, -50%);
        }
       
    }

    .owl-stage {
        padding-left: 0px !important;
        @media (max-width:767px){
            margin-left: -7px;
        }
    }
}
@media screen and (max-width: 767px) {
.slider-holder h4{
    font-size: 30px;
}
.slider-holder{
  margin-top: 0px;
  margin-bottom: 70px;
}
// .owl-carousel .owl-stage-outer {
//    margin-left: 12px;
    
// }
}