



// Send Gift voucher Form
.giftForm-holder{
    margin-top: 60px;

    h1{
        background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        font-weight: 700 !important;
        font-size: 36px !important;
        margin-bottom: 15px;
    }
    h2{
        background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        font-weight: 600 !important;
        font-size: 14px !important;
        margin-bottom: 15px;
    }

    .info-box{
        display: flex;
        justify-content: center;
        margin: 25px auto;
        
        
    }
    .info-box h6{
        background: #F7F7F7;
        border-radius: 12px;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        padding: 12px 12px;
    }
    .input-holder{
        background: #FFFFFF;
        border: 2px solid #F8F8F8;
        box-sizing: border-box;
        border-radius: 24px;
        padding: 10px 50px;
        margin-bottom: 20px;
    }
    .input-holder .custom-input input {
        font-weight: bold;
        font-size: 24px;
        line-height: 31px;
        text-align: center;
        color: #adb5bd;
        
    }
    .input-holder .custom-input{
        border-bottom: 1px solid #999;
        
    }
    .input-holder label{
        left: 0;
        right: 0;
        margin: 0px  110px;
    }
}

.giftForm-holder{
    .card--bg2{
        background: #F7F7F7;
        border: 2px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 24px;
        padding: 20px 20px;

    }
    .card--bg2 p{
        text-align: center;
    }
    .input-holder .custom-input  .inner-card input{
        text-align: left ; 
        border-bottom: none;
        font-size: 18px;
        font-weight: normal;
        color: #000;
    }
    .input-holder .custom-input  .inner-card label{
        text-align: left ; 
        font-size: 12px;
        line-height: 16px;
        margin: 0;
        color: #000;
    }
    .card--bg2 .custom-input {
        border-bottom: 0px solid #999;
    }
    .input-holder .custom-input  .inner-card{
        background: #FFFFFF;
        border-radius: 16px;
        padding: 18px 12px;
    }

    .gift-send-btn{
        background: linear-gradient(98.07deg, #600ABD 5.22%,  #115297 101.6%);
        width: 100%;
        font-weight: bold;
        font-size: 24px;
        line-height: 31px;
    }

    .price-slider{
        margin: 10px auto;
    }

    .price-slider input{
        width: 100%;
    }
    

}



.voucher-selected{
    background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%) !important;
    padding: 4px;
    border-radius: 34px;
    position: relative;

    
}
.voucher-selected{
    .select-icon{
        position: absolute;
        left : 20px;
        top: 15px;
    }
}


//Range slider 
.MuiSlider-track {
    height: 10px !important;
    border-radius: 34px !important;
    background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%) !important;
    border-radius: 22px;
    
}
.MuiSlider-rail {
    width: 100%;
    height: 10px !important;
    border-radius: 34px !important;
    background-color: #c3c2c2 !important;
}
.MuiSlider-thumb {
    width: 30px !important;
    height: 30px !important;
    margin: -10px !important;
    box-shadow: 0px 0px 0px 4px #600ABD;
    background-color: #fff !important;
    // border: 4px solid #222 !important;
}
.MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover {
    width: 30px !important;
    height: 30px !important;
    margin: -10px !important;
    box-shadow: 0px 0px 0px 4px #600ABD !important;
    background-color: #fff !important;
    
}

@media screen and (min-width: 991px) {
    .sendGift-holder{
        .desk {

            display: block;
        }
        .mobile {
            display: none;
        }
    }
    
}
@media screen and (max-width: 991px) {
    .sendGift-holder{
        .desk {

            display: none;
        }
        .mobile {
            display: block;
            margin-top: 25px;
        }
        .giftForm-wrapper{
             .input-holder {
                background: #FFFFFF;
                border: 2px solid #F8F8F8;
                box-sizing: border-box;
                border-radius: 24px;
                padding: 10px 50px;
                margin-bottom: 20px;
            }
            .input-holder .custom-input {
                border-bottom: 1px solid #999;
            }
            .input-holder .custom-input input {
                font-weight: bold;
                font-size: 24px;
                line-height: 31px;
                text-align: center;
                color: #adb5bd;
            }
            .card--bg2 {
                background: #F7F7F7;
                border: 2px solid #E0E0E0;
                box-sizing: border-box;
                border-radius: 24px;
                padding: 20px 20px;
            }
            .card--bg2 .custom-input {
                border-bottom: 0px solid #999;
            }
            .input-holder .custom-input .inner-card {
                background: #FFFFFF;
                border-radius: 16px;
                padding: 18px 12px;
            }
            .input-holder .custom-input .inner-card input {
                text-align: left;
                border-bottom: none;
                font-size: 18px;
                font-weight: normal;
                color: #000;
            }
            .gift-send-btn {
                background: linear-gradient(98.07deg, #600ABD 5.22%, #115297 101.6%);
                width: 100%;
                font-weight: bold;
                font-size: 24px;
                line-height: 31px;
            }
        }
    }
    
}
@media screen and (max-width: 360px) {
    .sendGift-holder .giftForm-wrapper .gift-send-btn {
        
        font-size: 20px;
        line-height: 15px;
    }
}