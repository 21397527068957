@import '../../variables';
@import '../../mixins';

.filter-holder {
    .see-all-events {
        border: 2px solid #EDEDED;
        border-radius: 54px;
        width: 165px;
        display: flex;
        height: 227px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        margin-right: 24px;
        cursor: pointer;
        @media only screen and (min-device-width: 0px) and (max-device-width: 360px) {
            height: 130px;
            width: 130px;
            border-radius: 38px;
            margin-right: 8px;
        }
        @media only screen and (min-device-width: 361px) and (max-device-width: 414px) {
            height: 140px;
            width: 140px;
            border-radius: 38px;
            margin-right: 10px;
        }
        @media only screen and (min-device-width: 415px) and (max-device-width: 767px) {
            height: 150px;
            width: 150px;
            border-radius: 48px;
            margin-right: 12px;
        }

        p {
            font-weight: 500;
            font-size: 20px;
            line-height: 26px;
            text-align: center;
            color: $color-black;
            margin-bottom: 0px;
            @media (max-width: 767px) {
                font-size: 18px;
            }
        }
    }

    .active-see-all {
        width: 165px;
        border: 8px solid #600ABD;
        border-radius: 54px;
        overflow: hidden;
        background: #fff;
        @media only screen and (min-device-width: 0px) and (max-device-width: 360px) {
            height: 130px;
            width: 130px;
            border-radius: 38px;
            border: 6px solid #600ABD;
        }
        @media only screen and (min-device-width: 361px) and (max-device-width: 414px) {
            height: 140px;
            width: 140px;
            border-radius: 38px;
            border: 6px solid #600ABD;
        }
        @media only screen and (min-device-width: 415px) and (max-device-width: 767px) {
            height: 150px;
            width: 150px;
            border-radius: 48px;
            border: 7px solid #600ABD;
        }
    }
}

// events details page

.event-details-page {
    .package-holder {
        .package-title {
            margin-bottom: 42px;

            @media (max-width:767px) {
                margin-bottom: 24px;
            }
        }

        .line {
            margin: 32px 0px;
        }
        .package-image {
            border-radius: 42px;
            margin-bottom: 32px;

            img {
                border-radius: 42px;
                height: 413px;
                object-fit: cover;

                @media (max-width:767px) {
                    height: 373px;
                    border-radius: 0px;
                }
            }
        }
        .video_mask{
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:413px;
            z-index:999;
            opacity:0;
            cursor: pointer;
            @media (max-width: "768px") {
                width: 0;
                height: 0;
                display: none;
            }
            // background: #000;
            // opacity: 0.5;
          }
        .mobile_video_mask{
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:413px;
            z-index:999;
            opacity:0;
            cursor: pointer;
            @media (min-width: "768px") {
                width: 0;
                height: 0;
                display: none;
            }
            // background: #000;
            // opacity: 0.5;
          }
        .package-video {
            border-radius: 42px;
            margin-bottom: 32px;
            // background: #000000;
            @media (max-width:767px) {
                border-radius: 0px;

            }
            iframe {
                border-radius: 42px;
                height: 413px;
                width: 100%;
                @media (max-width:767px) {
                    height: 373px;
                    width: 100%;
                    border-radius: 0px;
                    background-color: #000000;

                }
            }
            video {
                border-radius: 42px;
                height: 413px;
                width: 100%;
                @media (max-width:767px) {
                    height: 373px;
                    width: 100%;
                    border-radius: 0px;
                    background-color: #000000;

                }
            }
        }
    }

    .select-points {
        .event-price {
            background: #FBFBFB;
            border: 1px solid #EBEBEB;
            border-radius: 24px;
            width: 145px;
            padding: 18px 28px;
            text-align: center;

            p {
                @include brand-gradient;
                margin-bottom: 0px;
                font-weight: 500;
                font-size: 18px;

            }
        }
    }

    .card-box {
        width: 650px !important;
    }

    .dates-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #F8F8F8;
        border-radius: 28px;
        border-radius: 28px;
        padding: 17px 35px;
        margin-bottom: 12px;
        height: 76px;
        cursor: pointer;

        p {
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 0px;
        }
    }

    .active-date {
        background: #747474;
        color: #FFFFFF;
    }

    .start-date {
        p {
            font-size: 16px;
            line-height: 21px;
            color: #000000;
            margin-bottom: 10px;
        }
    }

    .selected-date {
        p {
            font-weight: bold;
            font-size: 18px;
            line-height: 23px;
            color: #000000;

        }
    }

    .submit {
        position: absolute;
        bottom: 20px;

        button {
            background: #000000;
            border-radius: 24px;
            color: #FFFFFF;
            font-weight: 700;
            font-size: 18px;
            width: 166px;
            height: 55px;
        }
    }
}

.hide-header-mobi {
    @media (max-width:767px) {
        // display: none;
        visibility: hidden
    }
    
}
.bm-burger-button {
    @media (max-width:767px) {
        visibility: visible;
        right: 16px !important;
        position: absolute  !important;
    }
}

.events-checkout-page {
    .purchase-details {
        @media (max-width:767px) {
            margin-top: 84px;

            h1 {
                font-size: 32px;
                margin-bottom: -15px;
            }
        }
    }
}

.eventTime {
    font-weight: bold;
    font-size: 19px;
    line-height: 31px;
    text-align: center;
    color: #000000;
    @media (max-width:767px){
        font-size: 18px;
    }
}

.events-login-holder {

    .event-card-box {
        border-radius: 0px !important;
        box-shadow: none !important;
    }
    .nav-tabs {
        justify-content: center;
    }
    .card-box-index {
        @media (max-width:767px){
            transform: translate(-50%, -75%);
        }
    }
}
.events-auth-tabs {
    .nav-tabs {
        background-color: #f1f1f1;
        border-radius: 24px;
        padding: 1px;
        margin: 49px 25px 0px;
    }
    .nav {
        justify-content: center;
    }
    .nav-link {
        border-radius: 24px;
    color: #606060;
    font-size: 22px;
    font-weight: 500;
    padding: 13px 14px;
    width: 50%;
    text-align: center;
    }
}

.events-bookThis {
    .free-tagline{
        p{
        background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-box-decoration-break: clone;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        }
    }
    .booking-holder {
        padding: 36px 30px;
        .form-group{
            padding: 15px 15px;
        }
        .proceed-to-book{
            padding: 14px 26px;
            font-size: 21px;
        }
        .proceed-to-book.disabled{
            background: #C9C9C9 !important;
        }
    }
    .contact-holder{
        padding: 36px 30px;
        .contact-card{
            padding: 20px;
            p:nth-child(1) {
                font-size: 14px;
            }
        }
    }
    @media (min-width:992px) {
        height:100%
    }
}

.testimonial-block {
    height: 72px;
    overflow-y: hidden;
    position: relative;
}

.testimonial-readmore {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    text-align: right;
    // background: linear-gradient(#ffffff00, #fcfcfc);
   
}
// .testimonial-readmore span {
//     background:linear-gradient(128deg, rgb(255 255 255 / 0%) 0%, rgb(252 252 252) 31%, rgba(255,255,255,1) 100%);
// }

.readmore-span {
    background: linear-gradient(
128deg, #ffffff00, #ffffffa8, 31%, #fcfcfc);
    width: 150px;
    height: 24px;
    display: inline-block;
}

