.wedding-holder{
  .about 
  {
      justify-content: space-between;
      align-items: center;
  }
   .img1 img {
      border-radius: 54px;
      margin: 0 12px;
  }
  .img2 img {
      border-radius: 54px;
      margin: 0 12px;
  }
  .img1 {
      margin-bottom: 100px;
  }
  .img2 {
      margin-top: 100px;
  }
  .about-info {
  padding-left: 30px;
  margin-top: -50px;
  }
  
  .about-info span {
  font-weight: 500;
  line-height: 55px;
  background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;


  }
  
    .about-info h1 {
      font-weight: 500;
      font-size: 42px;
      line-height: 55px;
      margin-right: 20px;
      @media (max-width:767px){
        line-height: 45px;
      }
    }
  
    .about-info ul {
      padding-left: 15px;
    }
  
    .about-info ul li {
      font-weight: normal;
      font-size: 14px;
      line-height: 32px;
    }
  
    .about-info ul li::marker {
  
      font-size: 12px;
  
    }
    @media only screen and (min-width: 768px){
      .wedding-info h1.first {
          width:534px;
    }
  
  }
  .wedding-info span {
      font-weight: 500;
      line-height: 55px;
      background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-box-decoration-break: clone;
      @media (max-width:767px){
        line-height: 45px;
      }
  }

  .second-container{
      width: 750px;
      @media (max-width:767px){
          width: 100%;
          text-align: center;
  }
    @media (min-width:767px) {
      padding-left: 30px;
    }
  }
  .wedding-info h1 {
      font-weight: 500;
      font-size: 42px;
      line-height: 55px;
      margin-right: 20px;
      margin-bottom: 28px !important;
      @media (max-width:767px){
        margin-top:20px;
        font-size: 32px;
        text-align: center !important;
        margin-right: 0px;
        line-height: 45px;
    }
  }
  .wedding-info h1.second{
      width: 666px;
      height: 220px;
      padding-left:30px;
      @media (max-width:767px){
          width: 100%;
          line-height: 45px;
  }
  }
  .wedding-info p{
    @media (max-width:767px){
       text-align: center !important;
       font-size: 16px;
       padding-top: 2px !important;
       margin-bottom: 50px;
    }
  }
  .wedding-info ul {
      padding-left: 15px;
  }
  .wedding-info ul li {
      font-weight: normal;
      font-size: 14px;
      line-height: 32px;
  }
  .wedding-info ul li::marker {
      font-size: 12px;    
  }
  h4{
      font-weight: 500;
      font-size: 42px;
      margin-bottom: 28px;
      span {
        background: linear-gradient(98.07deg,#115297 5.22%,#600abd 101.6%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-box-decoration-break: clone;
    }
  }
  .service-holder{
      justify-content: space-between;
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 50px;
    }
    .honeymoon-service-card{
      height: 355px !important;
      .honeymoon-sevice-body{
      padding: 0px 1px;
      height: 274px;
      }
      @media (max-width:767px){
        .service-icon{
          height: 60px !important;
          margin: 0px !important;
        }
        .service-title{
          margin-bottom: 31px !important;
          
        }
      }
    }
    .services-card{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 16px;
      gap: 10px;
      background: #FFFFFF;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
      border-radius: 54px;  
      width: 350px;
      height: 340px;
      border: none;
      @media (max-width:767px){
        width: 100% !important;
      }
      .sevice-accom{
        margin-top:0px;
      }
    }
    .service-icon{
      margin-top: 20px;
      width: 60px;
      height: 60px;
      border-radius: 50px;
      background: radial-gradient(50% 50% at 50% 50%, #c4bebe 0%, rgba(190, 181, 181, 0) 100%);
      z-index: 1;
      display: flex;
      justify-content: space-around;
      align-items: center;
      img{
        width: 40px;
        height: 40px;
      }
    }
    .service-title{
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 34px;
      display: flex;
      align-items: center;
      color: #000000;
      margin-bottom: 0;
      margin-top: 10px;
    }
    .service-body{
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      display: flex;
      color: #000000;
      height: 154px;
      padding-top: 10px;
      text-align: center;
    }
    .icon{
      position: absolute;
      left: 0%;
      right: 0%;
      top: 0%;
      bottom: 0.26%;
      background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
    }
}
// wedding & more media queries
.wedding-holder {
  @media only screen and (max-width: 600px) {
    .about {
      flex-direction: column;
    }

    .about .img1 img {
      width: 150px;
      height: 240px;
      border-radius: 45px;
      margin: 0 5px 0 0;
    }

    .about .img2 img {
      width: 160px;
      height: 250px;
      border-radius: 45px;
      margin: 0 0 0 5px;
    }

    .about .img2 {
      margin-top: 50px;
    }

    .about-info {
      margin-top: 0;
    }

    .about-info h1 {
      font-size: 28px;
      line-height: 35px;
    }
  }

  @media only screen and (min-width: 600px) {
    .about {
      flex-direction: column;
    }
  }

  .about-info {
    @media (max-width: 767px) {
      margin: -16px auto 0 !important;
    }
  }
  .about {
    img {
      @media (max-width: 767px) {
        object-fit: cover;
      }
    }
  }

  @media only screen and (max-width: 1024px) and (min-width: 768px) {
    .service-holder{
      gap: 20px;
      overflow-x: scroll;
      .services-card{
        min-width: 350px;
      }
    }
    .about {
      margin-top: 40px;
      gap:30px;
      .first{
        margin-right: 0px;
      }
    }

    .about-info {
      margin-top: 50px;
    }
  }

  @media only screen and (min-width: 992px) {
    .about {
      flex-direction: row;
    }
  }
  @media only screen and (max-width: 1200px)and (min-width: 768px) {
    .about {
  flex-direction: row;
  flex-wrap: wrap-reverse;
  gap: 30px;
  align-items: center;
  justify-content: center;
  width: 100%;
    }}
  @media only screen and (min-width: 1024px) {
    .about {
      flex-direction: row;
     
    }
  }
}
.wedding-holder-mob {
  .about {

      .img1,
      .img2 {
          @media (max-width:767px) {
              min-width: 307px !important;
              max-width: 307px !important;
          }

          img {
              @media (max-width:767px) {
                  border-radius: 0px !important;
                  height: 379px !important;
                  width: 100% !important;
                  margin: 0 !important;
              }
          }
      }

      .img1 {
          @media (max-width:767px) {
              margin-bottom: 0px !important;
              margin-right:5px;
              margin-left: 5px;
          }
      }

      .img2 {
          @media (max-width:767px) {
              margin-top: 0px !important;
              margin-right:5px;
              margin-left: 5px;
          }
      }

      .mobiImages {
          @media (max-width:767px) {
              width: 100vw;
              img{
                width: 100%;
               
              }
              overflow: scroll;
          }
      }
      .mobiImages::-webkit-scrollbar {
        display: none;
      }
      .mobiImages {
        -ms-overflow-style: none;
        scrollbar-width: none;
      }
  }
}
.destination-holder{
  .cat-img img{
    height: 300px;
    border-radius: 42px;
    @media (max-width:767px){
      height: 227px;
      border-radius: 42px;
    }
  }
  .cat-img .overlay{
    border-radius: 42px;
  }
  .cat-img p{
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    // top: 81.37%;
    bottom: 0.45%;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    display: flex;
    align-items: center;
    color:#FFFFFF;
    z-index: 999;
    text-align:center;
    text-transform: capitalize;
  }
}
.wedding-collage{
  .engagement-collage .first img {
    width: 100%;
    border-radius: 5px;
  }
  .engagement-collage .middle img {
    width: 100%;
    border-radius: 5px;
    margin-bottom: 0;
  }
  .engagement-collage .last img {
    width: 100%;
    border-radius: 5px;
  }
  .corporate-services{
    margin-top: 0;
    .mobile-reward-slider {
      margin: 30px 0 60px !important;
      
    }
    .engagement-collage {
      margin: 40px 0 80px !important;
    }
  }
}
.create-gift-holder {
  margin: 5px auto 100px !important;
  @media (max-width:767px){
      margin: 100px auto 0  !important;
  }
}
.wedding-btn{
  padding: 20px;
}
.wedding-mob-holder{
.service-holder{
  justify-content: space-between;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 50px;
}
.honeymoon-service-card{

  @media (max-width:767px){
    .honeymoon-sevice-body{
      padding: 0px;
    }
    .honeymoon-title{
      margin: 10px;
    }
    .service-icon{
      height: 60px !important;
      margin: 5px 0px 10px !important;
    }
    
  }
}
.services-card{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 16px;
  gap: 10px;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 54px;  
  width: 100%;
  height: 340px;
}
.service-icon{
    margin-bottom: 0;
    width: 60px;
    height: 80px;
    border-radius: 50px;
    background: radial-gradient(50% 50% at 50% 50%, #c4bebe 0%, rgba(190, 181, 181, 0) 100%);
    z-index: 1;
    display: flex;
    justify-content: space-around;
    margin: 30px 0;
    align-items: center;
    img{
      height: 40px;
      width: 40px;
    }

  @media (max-width:767px){
    
  }
}
.service-title{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 34px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-bottom: 0;
}
.service-body{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #000000;
  height: 154px;
  padding-top: 0;
  text-align: center;
}
.icon{
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0.26%;
  background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
}
.owl-theme .owl-dots{
  display: revert !important;
  text-align: center !important;
  -webkit-tap-highlight-color: transparent;
}
.owl-dots button{
margin : 0 2px;
}
}
.story_carousel .owl-item{
  @media only screen and (max-width: 1024px) and (min-width: 768px) {
  
        height: auto;
        width: auto;
  
  }
@media (min-width:1300px){
    height: 348px !important;
    width: 523px !important;
}
}
.story_carousel .card-wrapper img {
@media (min-width:767px){
  height: 250px !important;
}
}
.story_carousel .card-wrapper .info {
@media (min-width:767px){
  height: 100px !important;
}
}
.service_card{
width:100%;
}
.wedding-exports{
  @media (max-width:767px){
    padding: 0px !important;
  }
}
.carousel-center{
  @media (min-width:767px){
    .owl-stage-outer{
      display: flex;
      justify-content: center;
      .owl-stage{
        display: flex;
      justify-content: center;
      }
    }
  }
}
