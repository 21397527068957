.visa-holder {
  .about {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;

  }

  .img1 img {
    border-radius: 54px;
    margin: 0 12px;
  }

  .img2 img {
    border-radius: 54px;
    margin: 0 12px;
  }

  .img1 {
    margin-bottom: 100px;
  }

  .img2 {
    margin-top: 100px;
  }

  .about-info {
    padding-left: 30px;
    margin-top: -50px;
  }

  .about-info span {
    font-weight: 500;
    line-height: 55px;
    background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;


  }

  .about-info h1 {
    font-weight: 500;
    font-size: 42px;
    line-height: 55px;
    margin-right: 20px;
  }

  .about-info ul {
    padding-left: 15px;
  }

  .about-info ul li {
    font-weight: normal;
    font-size: 14px;
    line-height: 32px;
  }

  .about-info ul li::marker {

    font-size: 12px;

  }
  h4{
    font-weight: 500;
    font-size: 42px;
    margin-bottom: 28px;
    span {
      background: linear-gradient(98.07deg,#115297 5.22%,#600abd 101.6%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-box-decoration-break: clone;
    }
  }  
}

.custom-divider {
  border-bottom: 1px solid #eee;
  margin-bottom: 30px;
}

.visa-travelInfo {
  margin-bottom: 30px;

  .travelInfo-holder .traveller-holder {
    margin-top: 30px;
  }
}

.visa-callback-form {
  .switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 26px;

  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #c9c9c9;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked+.slider {
    background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
  }

  input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

// holiday index page queries
.holiday-holder {
  .card-center {
    text-align: center;
  }
}

.holiday-holder {
  @media screen and (max-width: 768px) {
    .card-wrapper {
      display: flex;
      overflow: auto;
      padding-left: calc((100% - 92%)/2) !important;
    }

  }

  @media screen and (max-width: 528px) {
    .card-wrapper {
      display: flex;
      overflow: auto;
      padding-left: calc((100% - 92%)/2) !important;
    }

  }

}

.holiday-range {
  background: #F8F8F8;
  border-radius: 28px;
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
  padding: 17px 36px;
  cursor: pointer;
  width: 90%;

  @media (max-width:767px) {
    width: 100%;
  }

  p {
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #000000;
    text-align: left;
  }
}

.holiday-range-scroll {
  max-height: 350px;
  min-height: 300px;
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;

  /* Firefox */
  @media (max-width:991px) {
    height: auto;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.holiday-range-active {
  background: #747474;

  p {
    color: #FFFFFF;
  }

  .grey-text {
    color: rgba(255, 255, 255, 0.5) !important;
    font-size: 16px !important
  }
}

.trip-date {

  h5 {
    font-size: 18px !important;
    line-height: 30px !important;

    @media (max-width:767px) {
      font-size: 16px !important;
      line-height: 22px;
      text-align: center !important;
    }
  }

  p {
    @media (max-width:767px) {
      text-align: center !important;
    }
  }
}

.grey-text {
  color: rgba(0, 0, 0, 0.5) !important;
  font-size: 16px !important
}


.month-label {
  position: relative;
  background: #F8F8F8;
  border-radius: 28px;
  padding: 19.5px 35px;
  margin-top: 0px !important;
  margin-bottom: 12px !important;
  width: 100%;
  text-align: left;
  cursor: pointer;

  .checked-icon {
    display: none;
  }

}

#month-checkboxes input[type=checkbox] {
  display: none;
}

#month-checkboxes input[type=checkbox]:checked+.month-label {
  background: #747474;
  color: #FFFFFF;

  .checked-icon {
    display: block;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.select-month {
  height: 340px !important;

  @media (max-width:767px) {
    height: auto !important;
  }

  .holiday-range-scroll {
    max-height: 280px !important;

    @media (max-width:767px) {
      max-height: auto !important;
    }
  }
}

.selected-months-holder {
  h6 {
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    text-align: left;
    margin-bottom: 10px;

    @media (max-width:767px) {
      text-align: center;
    }
  }

}

.months-selected {
  p {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
    margin-bottom: 8px;
  }

  &:nth-last-child(1) {
    span {
      display: none;
    }
  }
}

.internation-user-form {
  top: 0;
  left: 0;
  position: fixed;
  overflow: auto;
  background: #22222275;
  z-index: 22;
  height: 100%;
  width: 100%;

  .PhoneInputInput {
    max-width: calc(100% - 80px);
    padding-left: 40px !important;
    margin-left: -40px;
  }

  .user--modal {
    padding: 48px 42px;
    background: #fff;
    width: 480px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 32px;
    overflow: auto;
    height: 95%;

    @media (max-width:767px) {
      width: 100%;
      top: 65%;
      left: 50%;
      overflow: unset;
      height: auto;
    }

    h5 {
      font-weight: 500;
      font-size: 24px;
      line-height: 34px;
      text-align: center;
      color: #000000;
    }

    input {
      text-align: center;
      font-size: 22px;
      @media (max-width:767px){
        font-size: 20px;
      }
    }

    .form-line {
      border: 1px solid #000000;
      width: 70%;
      margin: -6px auto 9px;
    }

    label {
      opacity: 1;
      text-align: center !important;
    }
  }

  .user-card-bg {
    background: #fff !important;
    text-align: center !important;

    .form-control:focus {
      color: #000;
    }
  }

  .closeIcon {
    position: absolute;
    top: -25px;
    right: -15px;
    cursor: pointer;
  }
}

.international-location {
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: #797979;
  text-align: center;
  margin-top: 10px;
  @media (max-width:767px){
    text-align: left;
    margin-top: 5px;
  }
}

.customcard-holder {
  margin-top: 40px;
  @media (max-width:767px){
    margin-top: 0px !important;
  }
  .giftCard {
    &:nth-child(even){
      .row {
        flex-direction: row-reverse !important;
      }
    }
    .giftCard-img {
      img {
        @media (max-width:767px){
          height: 329px !important;
          border-radius: 32px !important;
        }
      }
    }
    .giftCard-content .title p {
      @media (max-width:767px){
        font-size: 18px !important;
        text-align: left;
        line-height: 23px !important;
        margin-top: 0px;
      }
    }
  }
}

.card-wrapper-coverage {
   width: 230px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}