@import '../common';
@import '../mixins';

.tabs-holder {
    .nav-tabs {
        justify-content: center;
        margin: 40px auto 48px;
        border-bottom: 2px solid rgba(0,0,0,0.05);
        .nav-link {
            font-size: 28px;
            font-weight: $fontWeight-medium;
            line-height: 36px;
            text-align: center;
            color: $color-black;
            opacity: 0.3;
            padding: 8px 31px 20px;
        }
        .nav-link.active {
            @include brand-gradient;
            opacity: 1;
            border: none;
            border-bottom: 2px solid $color-secondary;
        }
    }


}

.nav-holder {
    
    ul{
        
        justify-content: center;
        margin: 40px auto 48px;
        border-bottom: 2px solid rgba(0,0,0,0.05);
        padding: 8px 31px 20px;
        @media (min-width:1200px){
           margin: 25px -25px 38px;
        }
        li {
            list-style: none;
            display: inline-block;
            a {
                font-size: 28px;
                font-weight: $fontWeight-medium;
                line-height: 36px;
                text-align: center;
                color: $color-black;
                opacity: 0.3;
                padding: 8px 31px 20px;
                @media (max-width:767px){
                    padding: 8px 16px 20px;
                }
                @media (min-width:768px) and (max-width:991px){
                    padding: 8px 31px 14px;
                }
                @media (min-width:1200px){
                    padding: 8px 24px 20px;
                }
                &:hover {
                    text-decoration: none;
                }
            }
            .active {
                @include brand-gradient;
                opacity: 1;
                border: none;
                border-bottom: 2px solid $color-secondary;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .nav-holder {
        overflow: hidden;
        padding-left: calc((100% - 1105px)/ 2);
        white-space: nowrap;
        scroll-behavior: smooth;
        
        nav{
            overflow: auto;
            // // white-space: nowrap;
            // scroll-behavior: smooth;
            // // white-space: nowrap;
            // align-items: flex-start;
        }
        ul{
            overflow: auto;
            justify-content: left;
            margin: 0px auto 25px;
            padding: 25px 0px 16px;
        }
        
        
    }
    .nav-holder ul::-webkit-scrollbar {
        display: none;
    }
    /* For Firefox and IE */
    .nav-holder ul{
        scrollbar-width: none;
        -ms-overflow-style: none;
    }
}

.nav-holder ul {
    @media (max-width:767px){
        padding: 12px 0px 16px;
    }
}

@media screen and (max-width: 540px) {
    .nav-holder{
        nav ul li a{
            font-size: 18px;
        }
       
    }
}