@import '../../variables';
@import './../../mixins';

.booking-details-page{
    .package-title {
        h1{
            text-align: center !important;
        }
    }
}

.package-holder {
    @media (min-width:767px) {
        .owl-stage{
            padding-left: 40px !important;
        }
    }
    
    .owl-prev {
        position: absolute;
        top: 50%;
        left: 0px;
        transform: translate(-50%, -50%);
    }
    .owl-next {
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translate(50%, -50%);
    }
    .add-background {
        border: 2px solid #000000;
    }

    .package-title {
        margin-bottom: 64px;

        @media (max-width:767px) {
            margin-bottom: 32px;
        }

        h1 {
            font-weight: $fontWeight-medium;
            font-size: 42px;
            line-height: 55px;
            text-align: left;
            color: $color-black;
            margin: 19px auto 12px;

            @media (max-width:767px) {
                font-size: 32px;
                line-height: 42px;
                text-align: left;
                margin-top: -10px;
            }
        }

        .voucher {
            @include brand-gradient;
            font-weight: $fontWeight-bold;
            font-size: 18px;
            text-align: center;
            margin-bottom: 12px;

            @media (max-width:767px) {
                text-align: left;
            }
        }

        .destination {
            text-align: center;
            color: #797979;
            font-size: 18px;
            font-weight: $fontWeight-normal;
            margin: 0;

            @media (max-width:767px) {
                font-size: 16px;
                text-align: left;
            }
        }

        .workshop-type {
            @include brand-gradient;
            font-weight: 700;

            @media (max-width:767px) {
                text-align: left !important;
            }
        }

        .city-name {
            text-align: center;

            @media (max-width:767px) {
                text-align: left;
            }
        }
    }

    .package-details {
        .breadcrums{
            display: flex;
            align-items: center;
            
            p{
                margin-bottom: 0;
                padding-left: 22px;
                @media (max-width: 767px){
                    padding-left: 0px;
                    margin-bottom: 20px;
                }
                span{
                    
                    margin: 0 5px;
                    a{
                        cursor: pointer;
                    }
                }
                img{
                    margin: 0 5px;
                }
            }
        }
        .price{
            font-weight: 700;
            font-size: 40px;
            line-height: 52px;
            text-align: center;
            margin-top: 25px;
            @media (max-width: 992px){
                display: none;
            }

        }
        .mb-price{
            font-size: 24px;
            line-height: 31px;
            font-weight: 700;
            @media (min-width: 992px){
                display: none;
            }
        }
        .video-overlay {
            top: 0;
            left: 0;
            position: fixed;
            overflow: auto;
            background: #222222a3;
            z-index: 1000;
            height: 100%;
            width: 100%;
            .popup-close{
                img{
                    position: absolute;
                    background-color: #FFFFFF;
                    top: 16px;
                    right: 16px;
                    border-radius: 50%;
                    cursor: pointer;
                    padding: 14px;
                }
            }
            .video-holder{
                position: absolute;
                width: 100%;
                margin: 0 auto;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                iframe{
                    width: 100%;
                    height: 280px;
                }
                video{
                    width: 100%;
                    height: 280px;
                }
            }
        }
        
        .package-image {
            border-radius: 42px;
            margin-bottom: 32px;

            img {
                border-radius: 42px;
                height: 413px;
                object-fit: cover;

                @media (max-width:767px) {
                    height: 373px;
                    border-radius: 0px;
                }
            }
        }
        .video_mask{
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:413px;
            z-index:999;
            opacity:0;
            cursor: pointer;
            @media (max-width: "768px") {
                width: 0;
                height: 0;
                display: none;
            }
            // background: #000;
            // opacity: 0.5;
          }
          .mobile_video_mask{
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:413px;
            z-index:999;
            // opacity:0;
            cursor: pointer;
            @media (min-width: "768px") {
                width: 0;
                height: 0;
                display: none;
            }
            // background: #000;
            // opacity: 0.5;
          }
        .package-video {
            border-radius: 42px;
            margin-bottom: 32px;
            @media (max-width:767px) {
                border-radius: 0px;

            }
            iframe {
                border-radius: 42px;
                height: 413px;
                width: 100%;
                background-color: #000000;
                @media (max-width:767px) {
                    height: 373px;
                    width: 100%;
                    border-radius: 0px;
                    background-color: #000000;

                }
            }
            video {
                border-radius: 42px;
                height: 413px;
                width: 100%;
                background-color: #000000;
                @media (max-width:767px) {
                    height: 373px;
                    width: 100%;
                    border-radius: 0px;
                    background-color: #000000;

                }
            }
        }

        .package-description {
            background: #FBFBFB;
            border-radius: 32px;
            padding: 48px 54px 54px;
            margin: 24px 0px 0px;

            @media (max-width:767px) {
                border-radius: 0;
                padding: 24px;
                margin: 20px -15px 0px;
            }

            p {
                margin-bottom: 0;
                // padding: 15px 0
                
            }
            img{
                 border-radius: 17px;   
                 max-width: 100%;
                //  max-height: 100%;
                //  object-fit: cover;
                height: auto;
            }
            span{
                max-width: 100% !important;
                height: auto !important;
            }
        }

        .contact-holder {
            padding: 24px 16px 20px;
            h5 {
                font-size: 24px;
                margin-bottom: -10px;
            }
        }

        .images--holder {
            position: relative;

            @media (max-width:767px) {
                margin: 0px -15px;
            }

            .owl-dots {
                position: absolute;
                bottom: 38px;
                left: 50%;
                transform: translateX(-50%);

                .owl-dot {
                    margin: 0px 4px 0px !important;
                    width: 12px;
                    // height: 12px;
                }
            }

            .owl-dots .owl-dot span {
                background: rgba(255, 255, 255, 0.5);
                width: 12px;
                height: 12px;
                margin: 5px 0px;
            }

            .owl-dots .owl-dot.active span,
            .owl-dots .owl-dot:hover span {
                background: $color-white;
            }
        }
    }
    .package-testimonial{
        background: #FFFFFF;
        border: 1px solid #C9C9C9;
        box-sizing: border-box;
        border-radius: 54px;
        padding: 54px;
        margin-top: 40px;
        @media screen and (max-width: 768px) {
            padding: 0px;
            border: 0px solid #C9C9C9;
        }
        .heading h2{
            font-weight: bold;
            font-size: 24px;
            line-height: 31px;
            margin-bottom: 0;
        }
        .cards-wrapper {

        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;

        @media screen and (max-width: 768px) {
            flex-direction: column
        }

        .testimonial-card{
            background: #FCFCFC;
            border: 2px solid #F8F8F8;
            box-sizing: border-box;
            border-radius: 24px;
            padding: 20px;
            margin-bottom: 30px;
            width: 48.5%;
            @media screen and (max-width: 768px) {
                width: 100%;
            }

            .header{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .profile{
                    display: flex;
                    align-items: center;
                    .nav-proile-icon{
                        margin-right: 20px; 
                    }
                    img{
                        margin-right: 20px;
                        width: 52px;
                        height: 52px;
                        border-radius: 50%;
                    }
                    p{
                        margin-bottom: 0;
                        font-weight: bold;
                        font-size: 18px;
                        line-height: 23px;
                        color: #000000;
                    }
                }
                p{
                    font-size: 11px;
                    line-height: 14px;
                    color: #959595;
                }
            }
            .header-mobile{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .profile{
                    display: flex;
                    align-items: center;
                    .nav-proile-icon{
                        margin-right: 20px; 
                    }
                    img{
                        margin-right: 20px;
                        width: 52px;
                        height: 52px;
                        border-radius: 50%;
                    }
                    p{
                        margin-bottom: 0;
                        font-weight: bold;
                        font-size: 18px;
                        line-height: 23px;
                        color: #000000;
                        word-break: break-word;
                    }
                    span{
                        font-size: 11px;
                        line-height: 14px;
                        color: #959595;
                        font-weight: 400;
                    }
                }
               
            }
            .content{
                font-size: 16px;
                line-height: 24px;
                color: #000000;
                // p{
                //     display: block;
                //     height: 70px;
                //     overflow: hidden;
                //     white-space: nowrap;
                //     text-overflow: ellipsis;
                    
                // }
                
                a{
                    background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    -webkit-box-decoration-break: clone;
                }
                a:hover{
                    cursor: pointer;
                }
            }

        }
    }
    }
}

.nearby-experiences {
    margin-top: 92px;

    h5 {
        font-weight: $fontWeight-medium;
        font-size: 36px;
        color: $color-black;
        span{
            background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            -webkit-box-decoration-break: clone;
        }
        @media (max-width:767px) {
            font-size: 24px;
        }
    }

    .divider {
        margin: 12px 0px 24px;
        opacity: 0.1;
        border-top: 1px solid #000000;
    }
     .see-all {
        border: 4px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 48px;
        height: 487px;
        display: flex;
        align-items: center;

        @media(max-width: 767px){
            height: 369px;
        }
        @media screen and (min-device-width: 768px) and (max-device-width: 991px) { 
            height: 435px;
        }
        
         
        p{
            font-weight: 500;
            font-size: 20px;
            line-height: 26px;
            text-align: center;
            align-items: center;
            margin: auto
        };
    }
    .card-wrapper {
        @media (min-width:991px) {
            max-height: 503px;
        }

        img {
            border-radius: 32px 32px 0px 0px;
            object-fit: cover;

            @media (min-width:991px) {
                max-width: 100% !important;
                height: 352px;
                object-fit: cover;
            }

            @media (max-width:767px) {
                height: 244px;
                border-radius: 32px 32px 0px 0px;
            }

            @media (min-width:768px) and (max-width:991px) {
                height: 300px;
                border-radius: 32px 32px 0px 0px;
            }
        }

        .info {
            padding: 16px 28px 20px;
            background: #FBFBFB;
            border: 1px solid #EBEBEB;
            border-radius: 0px 0px 32px 32px;
            width: 100%;
            height: 135px;

            @media (max-width:767px) {
                border-radius: 0px 0px 32px 32px;
                padding: 15px 20px;
                height: 140px;
            }

            @media (min-width:768px) and (max-width:991px) {
                border-radius: 0px 0px 32px 32px;
                padding: 30px;
                height: 135px;
            }

            p {
                font-size: 20px;
                // font-weight: $fontWeight-medium;
                margin-bottom: 10px;
                line-height: 26px;
                overflow: hidden !important;
                display: -webkit-box !important;
                -webkit-line-clamp: 3 !important;
                -webkit-box-orient: vertical !important;

                @media (max-width:767px) {
                    -webkit-line-clamp: 3 !important;
                    font-size: 16px;
                    margin-bottom: 10px;
                }

            }

            .title {
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            .desc {
                height: 75px;
                overflow: auto;
                margin-bottom: 8px;

                p {
                    color: $color-black;
                    font-size: 14px;
                    font-weight: $fontWeight-normal;
                    opacity: 0.7;
                    margin-bottom: 10px;
                    line-height: 20px;

                    @media (max-width:767px) {
                        font-size: 12px;
                    }
                }

            }

            .points {
                font-size: 14px;
                // font-weight: $fontWeight-bold;
                color: #000000;
                opacity: 0.5;
                line-height: 18px;
                margin-bottom: 0;

                @media (max-width:767px) {
                    margin-bottom: 0px;
                }
            }
        }
    }

    .owl-nav {
        position: absolute;
        top: -20%;
        right: 0;
        display: flex;
    }

    .owl-theme .owl-nav .disabled {
        display: block !important;
        opacity: 0.6 !important;
    }
}

.similar-offers {
    margin: 104px 0px 104px;
    @media (max-width:767px) {
        margin: 48px 0px 100px;
    }

    .owl-stage-outer {
        @media (max-width:991px) {
            padding-left: calc((100% - 92%)/2) !important;
            margin: 1em -100%;
            /* old browsers fallback */
            margin: 1em calc(50% - 50vw);
        }
    }

    .owl-carousel .owl-stage {
        @media (max-width:991px) {
            left: -50px;
        }
    }

    a {
        color: #212529;
    }
}

.booking-holder  {
    padding: 24px 16px 20px;
    // padding: 48px 36px;
    @media (max-width: 991px) {
        .stay-price {
          display: none !important;
        }
      }
      .stay-price {
        margin-top: 20px;
        padding-bottom: 30px;
        border-bottom: 0.691511px solid #000000;
        margin-bottom: 20px;
    
        .pricess {
        
          align-items: center;
          flex-wrap: wrap;
          justify-content: center;
          flex-direction: column;
          .normal {
            font-weight: 400;
            font-size: 16px;
            color: #959595;
           
          }
          .discount {
            span{
                font-size: 16px;
                font-weight: 400;
            }
            font-weight: 700;
            font-size: 22px;
          }
        }
        .normal {
          text-decoration: line-through;
        }
        p {
          border: none;
          margin-bottom: 0px !important;
          padding-bottom: 0px;
        }
      }
    @media (max-width:767px) {
        padding: 36px 22px;
        margin-top: 30px;
    }
    .grey-title{
        background: none !important;
        -webkit-background-clip: unset;
        -webkit-text-fill-color: #000;
        opacity: 0.3;
    }
    h4{
        text-align: center;
        font-weight: $fontWeight-bold !important;
        font-size: 22px !important;
        margin-bottom: 12px;

        @media (max-width:767px) {
            font-size: 22px !important;
        }
    }
    h5 {
        @include brand-gradient;
        text-align: center;
        font-weight: $fontWeight-bold !important;
        font-size: 22px !important;
        margin-bottom: 12px;

        @media (max-width:767px) {
            font-size: 22px !important;
        }
    }

    .fill-details {
        @include brand-gradient;
        text-align: center;
    }

    .form-group {
        border: 2px solid #F8F8F8;
        box-sizing: border-box;
        border-radius: 24px;
        padding: 20px 17px;
        text-align: center;
        margin-bottom: 15px;
        position: relative;

        @media (max-width:767px) {
            padding: 20px 16px;
        }
        .flexiDates span{
            position: absolute;
            top: 35px;
            right: 0;
            font-size: 12px;
            cursor: pointer;
        }

        label {
            margin-bottom: 0;
            font-weight: $fontWeight-medium;
            font-size: 18px;
            line-height: 23px;
            color: #000000;
            margin-top: 10px;

            @media (max-width:767px) {
                font-size: 14px;
            }
        }

        input {
            border-bottom: 1px solid #000000;
            border-top: none;
            border-right: 0;
            border-left: 0;
            border-radius: 0px;
            font-weight: $fontWeight-bold;
            font-size: 19px;
            line-height: 31px;
            text-align: center;
            color: #000000;
            padding: 5px;
            @media (max-width:767px) {
                font-size: 18px;
            }

            &::placeholder {
                opacity: 0.5;
            }

        }
    }

    .total-amount {
        margin-bottom: 24px;
        text-align: center;

        p {
            margin-bottom: 0;
            font-weight: 500;
            font-size: 18px;
            color: #000000;
        }
    }

    .specific-request {
        background: #F7F7F7;
        // border: 2px solid #E0E0E0;

        label {
            margin-bottom: 20px;
            margin-top: 0px;
        }

        .custom-request {
            height: 111px;
            background: $color-white;
            border-radius: 16px;
            width: 100%;
            border: none;
            text-align: left;
            padding: 18px 12px;

            input {
                font-weight: $fontWeight-normal;
                font-size: 18px;
                padding: 0;
                line-height: 23px;
                color: #000;
                text-align: left;
                border-bottom: none;
                width: 100%;
                padding-bottom: 5px;

                @media (max-width:767px) {
                    font-size: 16px;
                }

                &::placeholder {
                    opacity: 0.5;
                }

                &:focus {
                    outline: 0;
                }
            }

            .form-line {
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            }
        }

        .example {
            margin-top: 5px;

            p {
                margin-bottom: 0px;
                font-size: 12px;
                color: #000000;
                opacity: 0.5;
                line-height: 16px;
            }
        }
    }

    .line-custom {
        opacity: 0.04;
        border-top: 1px solid #000000;
        width: 60%;
        margin: 0 auto 24px;
    }

    .proceed-to-book {
        background: linear-gradient(91.61deg, #600ABD 3.87%, #115297 100%);
        border-radius: 24px;
        padding: 14px 25px;
        font-size: 24px;
        line-height: 31px;
        color: #FFFFFF;
        font-weight: $fontWeight-bold;

        @media (max-width:767px) {
            font-size: 20px;
            padding: 16px 41px;
        }
    }

    .points-worth {
        text-align: center;
        font-weight: bold;
        font-size: 14px;
        margin: 12px auto 24px;
        @include brand-gradient;

        @media (max-width:767px) {
            margin: 12px auto 0px;
        }
    }
}

.giftVoucher-points {
    display: none;

    @media (max-width:767px) {
        display: block;
        font-size: 14px;
        padding: 12px 30px;
        border: 2px solid #000000;
        background: #E9E9E9;
        border-radius: 24px;
        width: max-content;

        p {
            margin-bottom: 0;
            font-weight: 500;
        }

    }
}
.points-slider-wrapper {
    border: 2px solid #F8F8F8;
    box-sizing: border-box;
    border-radius: 24px;
    padding: 24px;
    margin: 20px 0 25px 0;
}

.giftVoucher-mobi {
    @media (max-width:767px) {
        margin-right: 0px !important;

        .checkoutProd-img img {
            width: 100% !important;
            border-radius: 32px 32px 0px 0px;
        }

        .prod-info {
            width: 100% !important;
        }
    }
}

.object-contain {
    object-fit: contain !important;
}

.gift-images {
    @media (min-width:768px){
        border-radius: 42px;
    }
    overflow: hidden;
}

.blur-img {
    position: absolute;
    top: 0;
    object-fit: cover;
    filter: blur(20px);
    z-index: -1;
    overflow: hidden;
    border-radius: 42px;
}
.gift-bg-image {
    .package-image  {
        margin-bottom: 0px !important;
    }
}

.testimonial-popup{
    .header {
        display: flex;
                justify-content: space-between;
                align-items: center;
                .profile{
                    display: flex;
                    align-items: center;
                    .nav-proile-icon{
                        margin-right: 20px; 
                        p{
                            margin-right: 0px;
                        }
                    }
                    img{
                        margin-right: 20px;
                        width: 52px;
                        height: 52px;
                        border-radius: 50%;
                    }
                    .guest_name{
                        display: block;
                        @media screen and (max-width: 768px) {
                            display: flex;
                            flex-direction: column;
                        }
                    }
                    p{
                        margin-bottom: 0;
                        font-weight: bold;
                        font-size: 28px;
                        line-height: 36px;
                        color: #000000;
                        margin-right: 20px;
                        word-break: break-word;
                        @media screen and (max-width: 600px) {
                            font-size: 20px;
                            line-height: 28px;
                            margin-right: 0px;
                        }
                        span{
                            font-size: 14px;
                            line-height: 18px;
                            color: #959595;
                            font-weight: 400;
                        }
                    }
                    
                    
                }
        
    }
    .line{
        margin: 15px 0;
    }
    .content{
        padding: 32px;
        background: #fdfdfd;
        border: 1px solid #EAEAEA;
        box-sizing: border-box;
        border-radius: 32px;
        @media screen and (max-width: 768px) {
            background: #FFFFFF;
            border: 0px solid #EAEAEA;
            padding: 0px;
        }
        p{
            font-size: 20px;
            line-height: 32px;
        }
    }
}

.checkout-flexiDates span{
    position: absolute;
    top: 20px;
    right: 0;
    font-size: 12px;
    cursor: pointer;
}