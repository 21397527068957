@import '../../variables';
@import '../../mixins';
@import '../common/forms.scss';

.filter-holder {
    @include hide-scrollbar;
    scroll-behavior: smooth;

    .filter-wrapper {
        @include container-paddingLeft;
        display: flex;
        overflow: auto;

        @media (max-width:767px) {
            padding-left: calc((100% - 92%)/2) !important;
        }

        @media (min-width:768px) and (max-width:991px) {
            padding-left: calc((100% - 90%)/ 2) !important;
        }
    }
}
.corporate-enquiry-columns{
    display: flex;
    flex-direction: column;
    min-height: 300px ;
    justify-content: space-around;
    .input-holder{
  min-height: 67px;
    }
}
.corporate-enquiry-columns-right{
    display: flex;
    flex-direction: column;
    min-height: 300px ;
   padding-top: 17px;
   gap:30px;
   .input-holder{
    min-height: 67px;
      }
   .coprate-mobile{
    margin-top: 9px;
    .input-container{
        background: #fff;
        .PhoneInput{
            border-bottom: 1px solid rgba(0, 0, 0, 0.47) !important;
        }
        label{
            position: absolute;
            top: 105px;
            font-size: 15px;
    color: rgba(0, 0, 0, 0.60);
        }
        input{
    border:none;
    font-size:15px;
    color: rgba(0, 0, 0, 0.60);
        }
    }
}
    .enquiry-btn{
        margin-top: 18px;
        padding: 10px 30px;
        
    }
    .enquiry-btn:hover{
        color: #FAFAFA;
    }
}

.corporate-services {
    margin-top: 72px;

    @media (max-width:767px) {
        margin-top: 0px;
    }
    .filter-holder .filter-wrapper {
        @media (min-width:767px) {
            padding-left: 0; 
            display: block; 
            overflow: auto;
        }
       
    }

    .overlay-modal {
        position: fixed;
        top: 0;
        right: 0%;
        width: 309%;
        height: 135%;
        opacity: 0.4;
        background-color: #000000;
        z-index: 1;
    }
    .img-overlay{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.32) 100%);
        opacity: .3;
        border-radius: 5px;
    }
    .mobile-reward-slider{
        .owl-stage{
            padding-left: 10px !important;
        }
        img{
            border-radius: 5px;
            height: 197px;
            
        }
    }

    .rewards-collage{
        
        .first{
            // padding-right: 5px;
            img{
                width: 100%;
                border-radius: 5px;
            }
        }
        .middle{
            // padding-right: 5px;
            :first-child{
                // margin-bottom: 10px;
            }
            img{
                width: 100%;
                border-radius: 5px;
            }
        }
        .last{
            .inner{
                // margin-bottom: 1.2rem;
                .left{
                    // margin-right: 5px;
                }
            }
            :first-child{
                // margin-bottom: 13px;
            }
            img{
                width: 100%;
                border-radius: 5px;
            }
        }
    }
    .engagement-collage {
        .first{
            img{
                width: 100%;
                border-radius: 5px;
            }
        }
        .middle{
            :first-child{
                // margin-bottom: 10px;
            }
            img{
                width: 100%;
                border-radius: 5px;
                margin-bottom: 0;
            }
        }
        .last{
            
            :first-child{
                // margin-bottom: 10px;
            }
            img{
                width: 100%;
                border-radius: 5px;
            }
        }
    }
}

.servies-card {
    display: flex;
    border: 1px solid #EDEDED;
    border-radius: 32px;
    margin-bottom: 24px;

    .img-holder {
        img {
            border-radius: 32px 0px 0px 32px;
        }
    }

    .service-info {
        padding: 32px 20px 15px 28px;
        background: #F9F9F9;
        border-radius: 0px 32px 32px 0px;

        h5 {
            font-weight: $fontWeight-medium;
            font-size: 28px;
            line-height: 36px;
            color: $color-black;
            margin-bottom: 20px;
        }

        p {
            font-weight: $fontWeight-normal;
            font-size: $fontSize-smallest;
            line-height: 18px;
            color: $color-black;
            opacity: 0.6;
        }
    }
}

.packages-holder {
    margin-top: 104px;

    .package {
        display: flex;
        align-items: center;
        margin-bottom: 100px;

        &:nth-child(even) {
            flex-direction: row-reverse;

            .package-title {
                margin-left: 0px;
                margin-right: 36px;
            }

        }

        .package-img {
            img {
                border-radius: 54px;
            }
        }

        .package-title {
            margin-left: 36px;

            p {
                font-weight: $fontWeight-medium;
                font-size: $fontSize-larger;
                line-height: $lineHeight-larger + 2;
                color: $color-black;
                margin-bottom: 0;
            }
        }
    }
}

// login-holder for corporates

.login-holder {
    padding: 48px 32px 36px;
    border: 1px solid #C9C9C9;
    border-radius: 32px;
    margin-bottom: 24px;
    @media (max-width: 414px){
        padding: 48px 24px 36px;
    }

    h5 {
        font-weight: $fontWeight-medium;
        font-size: 26px;
        line-height: 34px;
        color: $color-black;
    }

    .connect-title {
        @media (max-width:767px) {
            font-size: 20px;
            line-height: 26px;
        }
        span{
            @include brand-gradient
        }
    }
}

.line {
    opacity: 0.1;
    border-top: 1px solid $color-black;
    margin: 24px 0px;

    @media (max-width:767px) {
        margin: 20px 0px;
    }
}

.contact-card {
    background: #FCFCFC;
    border: 2px solid #F8F8F8;
    box-sizing: border-box;
    border-radius: 24px;
    padding: 20px 16px;
    text-align: left;

    @media (max-width:767px) {
        padding: 16px;
    }

    p {
        line-height: normal;
        margin-bottom: 0px;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: $color-black;
        background: none;
        text-align: left;

        @media (max-width:767px) {
            font-size: 12px;
        }

        &:nth-child(1) {
            font-size: 16px;
            margin-bottom: 12px;

            @media (max-width:767px) {
                font-size: 14px;
            }
        }

        a {
            color: $color-black;
            text-decoration: none;
            word-break: break-all;
        }
    }
}

.success-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;

    .goBack {
        position: absolute;
        top: -10px;
        right: -5px;
        cursor: pointer;
    }

    .success-message {
        margin-top: 25px;

        p {
            text-align: center;
            color: $color-black;
            margin-bottom: 10px;

            &:nth-child(1) {
                font-weight: $fontWeight-normal;
                font-size: 16px;
            }

            &:nth-child(2) {
                font-weight: $fontWeight-bold;
                font-size: 18px;
                margin-bottom: 20px;
            }
        }
    }

    .alert-message {
        margin-top: 25px;

        p {
            text-align: center;
            color: $color-black;
            margin-bottom: 10px;

            &:nth-child(1) {
                font-weight: $fontWeight-bold !important;
                font-size: 18px !important;
                width: 85%;
                margin: 0 auto 10px;
            }

            &:nth-child(2) {
                font-weight: $fontWeight-normal !important;
                font-size: 16px !important;
                margin: 0 auto 25px;
            }
        }
    }
}



.MuiPaper-rounded {
    border-radius: 32px !important;
}

// clients

.our-clients {
    margin-top: 128px;

    @media (max-width:767px) {
        margin-top: 80px;
    }

    h6 {
        font-weight: $fontWeight-medium;
        font-size: 36px;
        line-height: 47px;
        color: $color-black;
        text-align: center;
        margin-bottom: 36px;

        @media (max-width:767px) {
            font-size: 24px;
            margin: 0 auto 12px;
            line-height: 31px;
            width: 95%;
        }
    }

    @include logo-cards;

    .card-wrapper {
        width: 100%;
        margin-right: 20px;
        margin-bottom: 20px;

        &:last-child {
            margin-right: 0px;
        }

        .img-holder {
            min-width: 180px;
            margin: 0px 20px;

            img {
                width: 100%;
                max-width: fit-content;
            }
        }
    }

    .logo-wrapper {
        @media (max-width:1199px) {
            overflow-x: auto;
        }

        @media (max-width:767px) {
            padding-left: 15px;
        }
    }
}

.corporate-otp-holder {
    .input-container {
        background: #FCFCFC;
        border: 2px solid #F8F8F8;
        box-sizing: border-box;
        border-radius: 24px;
        padding: 20px 24px;

        label {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            width: 100%;
        }

        .resend-otp {
            @include brand-gradient;
            font-weight: 400;
            font-size: 14px;
        }
    }

    input {
        padding: 0 0 10px !important;
        border: none !important;
        font-weight: $fontWeight-medium;
        font-size: 18px;
        color: $color-black;
        background: transparent;

        &::placeholder {
            opacity: 0.4;
        }

        &:disabled {
            background: #cccccc2b !important;
            padding: 5px;
        }
    }

    .otp-btn-container {
        justify-content: flex-end;

        button {
            padding: 20px 54px;
            background: #2d2d2d;
            border-radius: 24px;
            color: #fff;
            font-weight: 700;
            font-size: 18px;
        }
    }

}

.wedding-programs-holder {
    margin-top: 40px !important;

    @media (max-width:767px) {
        padding-left: 0px !important;
        margin: 40px auto 0 !important;
        width: 95%;
    }

    @media (min-width:768px) and (max-width:1199px) {
        margin-top: 25px !important;
    }

    h1 {
        @media (max-width:767px) {
            font-size: 30px !important;
            text-align: center;
            margin-right: 0 !important;
            line-height: 42px !important;
        }

        span {
            @media (max-width:767px) {
                line-height: 45px !important;
            }
        }
    }

    .employee-programs {
        margin-top: 16px;

        @media (max-width:767px) {
            text-align: center;
        }

        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            margin-bottom: 8px;
        }
    }

}

.learn-more-holder {
    background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
    border-radius: 24px;
    padding: 20px 32px;
    width: max-content;
    margin-top: 24px;
    cursor: pointer;

    .btn-learn-more {
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        color: #FFFFFF;
        padding: 0;
    }
}
.employee-signup-holder{
    border: 2px solid #600ABD !important;
    border-radius: 24px;
    padding: 20px 32px;
    width: max-content;
    margin-top: 24px;
    cursor: pointer;

    .btn-employee-signup{
        background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);;
        -webkit-background-clip: text !important;
        -webkit-text-fill-color: transparent !important;
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        padding: 0;
    }
}
.corp-receive-otp{
    border: 2px solid #600ABD !important;
    border-radius: 24px;
    padding: 15px 35px;
    margin-top: 8px;
    cursor: pointer;
    display: inline-block;
    text-align: center;

    .btn-receive-otp{
        background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);;
        -webkit-background-clip: text !important;
        -webkit-text-fill-color: transparent !important;
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        padding: 0;
        
    }

}



.acheivements-holder {
    margin: 20px -50px 115px;

    @media (max-width:991px) {
        overflow: auto;
        margin: 0px -50px 88px;
        padding: 0px 55px;
    }

    .acheivement-card {
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 32px;
        margin-right: 24px;
        width: 240px;

        @media (max-width:991px) {
            min-width: 240px;
            max-width: 240px;
        }

        .count {
            padding: 26px 24px 10px;

            p {
                margin-bottom: 0px;
                text-align: center;
                font-size: 36px;
                color: #000000;

                @media (max-width:767px) {
                    font-size: 32px;
                }
            }

            span {
                &:nth-child(1) {
                    background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
                    -webkit-text-fill-color: transparent;
                    -webkit-background-clip: text;

                }

                &:nth-child(2) {
                    color: #959595;
                }
            }
        }

        &:last-child {
            margin-right: 0px;
        }
    }

    .info {
        background: #FAFAFA;
        padding: 24px 10px;
        border-radius: 0px 0px 32px 32px;

        p {
            color: #000000;
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            margin-bottom: 0px;
            text-align: center;
        }
    }
}

.corpPage-login {
    .login-holder {
        width: 534px;
        border: none;
        margin: 0 auto;
        padding: 0px;
        p{
            font-size: 22px;
            margin-bottom: 0;
            text-align: center;
        }
        @media (max-width:767px) {
            width: 100%;
        }

        .line {
            display: none;
        }

        h5 {
            font-size: 20px;
            text-align: center;
            font-weight: 400;
            line-height: 26px;
            margin: 0px -25px 28px;

            @media (max-width:767px) {
                font-size: 24px;
                line-height: 31px;
                width: 96%;
                margin: 0 auto 28px;
            }

            span {
                background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;
                -webkit-box-decoration-break: clone;
            }
        }

        .input-holder {
            p {
                display: none;
            }
        }

        .otp-btn {
            background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            border: 2px solid #600ABD;
            margin-top: 8px;
            padding: 15px 35px;

        }

        .otp-btn-container {
            justify-content: center;

            .btn-common {
                background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                border: 2px solid #600ABD;
                margin-top: 8px;
                padding: 15px 35px;
                span{
                    background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                
                }
            }
        }
    }
}

.cerp-corp-wrapper {
    margin-top: 150px;

    @media (max-width:767px) {
        margin-top: 88px;
    }

    .cerp-corp {
        margin-bottom: 100px;

        @media (max-width:767px) {
            margin-bottom: 80px;
        }

        .vertical-center {
            display: flex;
            align-items: center;
        }

        .info {
            p {
                &:nth-child(1) {
                    font-weight: 500;
                    font-size: 42px;
                    color: #000000;
                    line-height: 55px;
                    margin-bottom: 24px;

                    @media (max-width:767px) {
                        font-size: 31px;
                        line-height: 40px;
                        text-align: center;
                        margin-top: 24px;
                        margin-bottom: 12px;
                    }
                }

                &:nth-child(2) {
                    font-size: 18px;
                    line-height: 36px;
                    color: rgba(0, 0, 0, 0.6);

                    @media (max-width:767px) {
                        font-size: 14px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}

// testimonials 

.testimonial-holder {
    margin-top: 100px;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

    /* Firefox */
    & ::-webkit-scrollbar {
        display: none;
        /* Hide scrollbar for Chrome, Safari and Opera */
    }

    @media (max-width:767px) {
        margin-top: 110px;
    }

    h5 {
        @include corporate-title;

        @media (max-width:767px) {
            font-size: 28px;
        }
    }

    .testimonial-wrapper {
        // display: flex;
        // overflow-x: auto;
        // margin: 1em calc(50% - 50vw);
        // padding-left: calc((100% - 73%)/2) !important;
        // align-items: center;
        // margin: 0 150px;
        
        
        

        @media (max-width:767px) {
            // padding-left: calc((100% - 91%)/2) !important;
            // top: 0;
            // left: 0;
            // margin: 0 auto;
            // width: 100%;
            // height: 100%;
        }
        @media (min-width:998px) {
            // padding-left: calc((100% - 91%)/2) !important;
            top: 0;
            left: 0;
            margin: 0 auto;
            width: 852px;

        }
        .owl-dots{
            display: block;
            margin-top: 20px !important;
            button{
                margin: 0 2px;
            }
        }

        .testimoni {
            // min-width: 852px;
            // max-width: 852px;
            // width: 50%;
            height: 370px;
            background: #FFFFFF;
            box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.04);
            border-radius: 54px;
            padding: 48px;
            margin: 10px 24px 10px 5px;

            @media (max-width:767px) {
                // min-width: 300px;
                // max-width: 300px;
                height: 630px;
                margin: 10px 16px 10px 5px;
                padding: 28px;
            }

            @media (min-width:768px) and (max-width:991px) {
                // min-width: 450px;
                // max-width: 450px;
                height: 430px;
            }

            img {
                width: 68px;
                height: 68px;
            }

            .name-designation {
                margin-left: 24px;

                p {
                    &:nth-child(1) {
                        font-size: 24px;
                        line-height: 31px;
                        color: #000000;
                        margin-bottom: 6px;

                        @media (max-width:767px) {
                            font-size: 20px;
                            line-height: 26px;
                        }
                    }

                    &:nth-child(2) {
                        color: #000000;
                        opacity: 0.6;

                        @media (max-width:767px) {
                            font-size: 14px;
                        }
                    }
                }
            }

            .description {
                color: rgba(0, 0, 0, 0.6);
                font-size: 18px;
                line-height: 36px;
                margin-top: 24px;

                @media (max-width:767px) {
                    font-size: 16px;
                    line-height: 28px;
                }
            }
        }
    }
}

.talk-to-us {
    margin-top: 110px;

    h5 {
        @include corporate-title
        span{
            @include brand-gradient
        }
    }

    .login-holder {
        h5 {
            font-size: 26px;
            text-align: left;
        }
    }

    .card--bg {
        padding: 10px 14px 12px;
    }

    .input-holder {
        p {
            margin-bottom: 16px;
        }

        .form-line {
            margin: 0px 0px 10px;
        }
    }

}

// employee engagement

.employee-reward {
    // background: #F4F4F4;
    // border-radius: 54px;
    // padding: 57px 48px 54px;
    margin-bottom: 50px;

    @media (max-width:767px) {
        padding: 32px;
        border-radius: 0px;
        margin: 0px calc(50% - 50vw) 1em;
    }

    h5 {
        @include corporate-title;
        margin-bottom: 20px;

        @media (max-width:767px) {
            font-size: 24px;
            // margin-bottom: 48px;
        }

        .grey {
            color: #959595;
        }

        .gradient {
            @include brand-gradient
        }
    }
    p{
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        // margin-bottom: 60px;
        padding: 0 150px;
        @media (max-width:767px) {
            font-size: 18px;
            margin-bottom: 50px;
            padding: 0;
        }
    }
    .padding{
        padding: 0 20px;
        @media (max-width:767px) {
            padding: 0;
        }
    }
    .tagline{
        margin-top: 50px;
        p{
            font-size: 28px;
            line-height: 35px;
            font-weight: 700;
            margin-bottom: 0;
            // padding: 0 150px;
            @media (max-width:767px) {
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 0;
            }
        }
    }
    h6{
        font-size: 22px;
        font-weight: 500;
        line-height: 28px;
        text-align: center;
        margin-top: 50px;
        padding: 0 150px;
        @media (max-width:767px) {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 0;
            padding: 0;
        }
    }

    .filter-wrapper {
        margin: 1em calc(50% - 50vw);
        padding-left: calc((100% - 80%)/2) !important;

        @media (max-width:767px) {
            padding-left: calc((100% - 75%)/2) !important;
        }
    }
}

.employee-engagement {
    // background: #F4F4F4;
    // border-radius: 54px;
    // padding: 57px 48px 54px;
    margin-bottom: 80px;

    @media (max-width:767px) {
        padding: 32px;
        border-radius: 0px;
        margin: 0px calc(50% - 50vw) 1em;
    }

    h5 {
        @include corporate-title;
        margin-bottom: 10px;

        @media (max-width:767px) {
            font-size: 24px;
            // margin-bottom: 48px;
        }

        .grey {
            color: #959595;
        }

        .gradient {
            @include brand-gradient
        }
    }
    p{ 
        font-size: 22px;
        line-height: 24px;
        text-align: center;
        margin-bottom: 50px;
        @media (max-width:767px) {
            font-size: 16px;
        }
    }

    .filter-wrapper {
        margin: 1em calc(50% - 50vw);
        padding-left: calc((100% - 80%)/2) !important;

        @media (max-width:767px) {
            padding-left: calc((100% - 75%)/2) !important;
        }
    }
}

.corp-mobi-p0 {
    @media (max-width:767px) {
        padding: 0;
    }
}

.employeeRewards-holder {
    .about {

        .img1,
        .img2 {
            @media (max-width:767px) {
                min-width: 307px !important;
                max-width: 307px !important;
            }

            img {
                @media (max-width:767px) {
                    border-radius: 0px !important;
                    height: 379px !important;
                    width: 100% !important;
                    margin: 0 !important;
                }
            }
        }

        .img1 {
            @media (max-width:767px) {
                margin-bottom: 0px !important;
            }
        }

        .img2 {
            @media (max-width:767px) {
                margin-top: 0px !important;
            }
        }

        .mobiImages {
            @media (max-width:767px) {
                // margin: 1em calc(50% - 50vw);
                margin-bottom: 48px !important;
                width: 100vw;
                overflow: scroll;
            }
        }
    }
}

.employerForm-holder {
    position: relative;
    border-bottom: transparent;
    border-radius: 0;

    .closeIcon {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }
}

.employer-modal {
    top: 0;
    left: 0;
    position: fixed;
    overflow: auto;
    background: #fff;
    z-index: 22;
    height: 100%;
    width: 100%;
    border: 1px solid #C9C9C9;
    border-radius: 32px;

    @media (max-width:767px) {
        background: rgba(0, 0, 0, 0.4);
        border-radius: 0px;
        border: unset;
    }

    .login-holder {
        border: transparent;
        position: absolute;

        @media (max-width:767px) {
            padding: 48px 32px 55px;
            background: #fff;
            margin-top: 50px;
            border: 1px solid #C9C9C9;
        }

        .closeIcon {
            position: absolute;
            top: 20px;
            right: 30px;
        }
    }
}

.filter-wrapper-mobi {
    @media (max-width:767px) {
        display: flex;
        overflow: auto;
        margin-left: 10px;
    }
}

.gift-mobi-amount {
    @media (max-width:767px){
        margin-bottom: 140px;
    }
}
.similar-gifts-holder {
    @media (max-width:767px){
        margin-top: -140px;
    }   
}

.promotion-card{
    background: rgba(244, 244, 244, 0.75);
    border-radius: 54px;
    text-align: center;
    padding: 50px;
    margin: 50px 0;
    @media (max-width:767px) {
        padding: 24px 20px;
        border-radius: 32px;
        
    }
    
    .cta{
        background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
        border-radius: 24px;
        padding: 10px 24px;
        margin: 25px auto 0;
        cursor: pointer;
        width: fit-content;
        text-align: center;

        button{
            color: #fff;
            font-weight: 500;
        }
    }

    h5  {
            background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            -webkit-box-decoration-break: clone;
            font-weight: 500;
            font-size: 34px;
            line-height: 55px;
            @media (max-width:767px) {
                font-size: 22px;
                line-height: 31px;
                margin-bottom: 10px;
                
            }
        }
}