// Gift cards

.giftVoucher-holder{
    
    margin: 30px auto;
    width: 100%;

    .gift-voucher{
        display: flex;
        background: #FDFDFD;
        border: 1px solid #EDEDED;
        box-sizing: border-box;
        border-radius: 32px;
    }
    .voucher-content{
        padding: 32px 20px 24px 28px;
        align-items: flex-start;
    }
    .voucher-content input[type="text"] {
        border: 1px solid #fff;
        font-weight: 500;
        font-size: 24px;
        &:focus{
            outline: none;    
          }
    }
    .voucher-content h2{
        font-size: 24px;
        line-height: 31px;
    }
    .voucher-content p{
        font-size: 14px;
        line-height: 18px;

    }
    .voucher-content .line{
        margin: 12px 0;
    }
    .description textarea {
        border: 0px solid #fff;
        width: 300px;
        height: 100px;
        font-size: 14px;
        display: block;
        background: #FDFDFD;
        cursor: pointer;
        &:focus{
          outline: none;    
        }
    }
    .gift-voucher .edit-image{
        position: absolute;
        top: 50%;
        left: 50%;
        cursor: pointer;
        transform: translate(-50%, -50%);
        
        
    }
    

    .img-wrapper{
        position: relative;
        
    }
    .img-wrapper img{
        border-bottom-left-radius: 30px;
        border-top-left-radius: 30px;
        
    }
    .voucher-image{
        width: 244px;
        height: 228px;
    }

    .gift-voucher .edit-image{
        position: absolute;
        top: 50%;
        left: 50%;
        cursor: pointer;
        transform: translate(-50%, -50%);
        
        
    }

    .gift-voucher .edit-image .upload-file{
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        cursor: pointer;
        width: 50px;
        height: 50px;
        z-index: 9999;
    }
}

@media screen and (max-width: 991px) {
    .giftVoucher-wrapper{
        display: flex;
        flex-direction: row;
        overflow: auto;
        scroll-behavior: smooth;
        white-space: nowrap;
    }
    .giftVoucher-wrapper::-webkit-scrollbar {
        display: none;
    }
    /* For Firefox and IE */
    .giftVoucher-wrapperr {
        scrollbar-width: none;
        -ms-overflow-style: none;
    }

    
    .giftVoucher-holder {
            width: 340px !important;
            margin-right: 20px;

            .description textarea {
                border: 0px solid #fff;
                width: 205px;
                height: 100px;
                font-size: 14px;
                display: block;
                background: #FDFDFD;
            }
            
        .gift-voucher {
            display: flex;
            flex-direction: column;
            background: #FDFDFD;
            border: 1px solid #EDEDED;
            box-sizing: border-box;
            border-radius: 32px;

            .img-wrapper img{
                border-top-right-radius: 32px;
                width: 100%;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .giftVoucher-holder {

        .description textarea {
            width: 215px;    
        }
    }
}
@media screen and (min-width: 1200px) {
    .giftVoucher-holder {

        .description textarea {
            width: 300px;    
        }
    }
}