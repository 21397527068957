@import '../../variables';

.input-holder {
    p {
        font-weight: $fontWeight-normal;
        font-size: 18px;
        color: $color-black;
        margin-bottom: 20px;
    }

    .custom-input {
        input {
            padding: 0 !important;
            border: none !important;
            font-weight: $fontWeight-medium;
            font-size: 18px;
            color: $color-black;
            background: transparent;
            &::placeholder{
                opacity: 0.4;
            }
        }

        label {
            font-weight: $fontWeight-normal;
            font-size: 14px;
            color: $color-black;
            opacity: 1;
            margin-bottom: 0px;
        }

        .form-line {
            opacity: 0.1;
            border-top: 1px solid $color-black;
            margin: 6px 0px 12px;
        }
    }
}
.enquiry-btn{
    padding: 20px 54px;
    background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
border-radius: 24px;
color: $color-white;
font-weight: $fontWeight-bold;
font-size: 18px;
}
.otp-btn {
    padding: 20px 54px ;
    background: #2D2D2D;
    border-radius: 24px;
    color: $color-white;
    font-weight: $fontWeight-bold;
    font-size: 18px;
    width: auto;
    &:hover {
        color: $color-white !important;
    }
}

.card--bg {
    background: #FCFCFC;
    border: 2px solid #F8F8F8;
    box-sizing: border-box;
    border-radius: 24px;
    padding: 20px 24px;
}

.mb20 {
    margin-bottom: 20px;
}