$primary: #F8F8F8;
$radius: 12px;

.calendar {
  width: 360px;
  padding: 15px;
  border-radius: $radius;
  overflow: hidden;
  // @media (max-width:767px){
  //   // width: 280px;
  //   // height: 280px;
  //   height: auto;
  //   margin-bottom: 20px;
  // }
  @media (max-width: 991px) {
    
    // position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    height: auto;
    top: auto;
    border-radius: 42px 42px 0 0;
    }
  &--nav {
    border-radius: 50px !important;
    margin: -15px -15px 15px;
    padding: 0 15px;
    background-color: $primary;
    color: #000000;;
    height: 63px;
    display: flex;
    align-items: center;
    position: relative;
    

    a {
        position: absolute;
        cursor: pointer;
        left: 10px; 
        font-size: 44px;
        line-height: 1;
        top: 11px;
        width: 47px;
        text-align: center;
        display: inline-block;
        background-color: #F8F8F8;
       -webkit-user-select: none; 
      user-select: none;
        padding: 5px;

      &:hover {
        color: #fff;
      }

      &:last-child {
        left: auto;
        right: 10px;
        background-color: #FFFFFF;
        border-radius: 100%;
        padding: 2px;
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &:first-child{
        right: 10px;
        background-color: #FFFFFF;
        border-radius: 100%;
        padding: 2px;
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    h1 {
      margin: 0;
      position: absolute;
      left: 40px;
      right: 40px;
      text-align: center;
      cursor: pointer;
      font-weight: 400 !important;
      font-size: 22px !important;
      line-height: 66px;
      user-select: none;
      color: #000000;
    }

    small {
      font-weight: 500;
      font-size: 18px;
    }
  }

  &--days {
    font-size: 0;
    
    span {
      width: 14.28571%;
      display: inline-block;
      text-align: center;
      user-select: none;
      cursor: pointer;
      margin: 8px 0;
      line-height: 18px;
      position: relative;
      font-size: 14px;
      @media (max-width: 991px){
        padding: 8px 0;
        margin: 2px 0;   
      }

      &.label {
        text-transform: uppercase;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.3);
        font-size: 14px;
        cursor: initial;
      }

      &.active {
        font-weight: 700;
        // background-color: transparentize($primary, 0.95);
        border-radius: $radius;
      }

      &.muted {
        color: rgba(0, 0, 0, 0.3);
      }
      &.disabled {
        cursor: default;
        color: rgba(0, 0, 0, 0.3);
        pointer-events: none;
      }

      &.between {
        border-radius: 0;
      }

      &.start, &.between, &.end {
        background-color: #9E9E9E;
        color: #fff;
      }

      &.start {
        border-radius: $radius  0 0 $radius;
        @media (max-width: 991px) {
          border-radius: 16px  0 0 16px;
        }
      }

      &.end {
        border-radius: 0 $radius $radius 0;
        @media (max-width: 991px) {
          border-radius: 0 16px 16px 0;
        }
      }

      &.start.end {
        border-radius: $radius;
      }

      &.between:nth-child(7n):after, &.start:nth-child(7n):after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 100%;
        // background-color: #b670f4;
        width: 20px;
      }

      &.between:nth-child(7n + 1):after, &.end:nth-child(7n + 1):after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 100%;
        // background-color: #b670f4;
        width: 20px;
      }

      &.start.end:after {
        display: none;
      }
    }
  }
}

