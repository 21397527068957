
    @media (max-width:767px){
        .calendar-overlay{
            position: fixed;
            width: 100%;
            height: 100%;
            z-index: 1000;
            top: 0;
            left: 0;
            background: #222;
            opacity: 0.7;
        }
        .card-box.calender-modal{
            box-shadow: 0px 0px 0px 0px #eee;
        }
}

.calender-modal{
    position: absolute;
    right: 0;
    top: 80px;
    width: 640px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 36px 40px 30px;
    /* transform: translateY(-60%);
    transition-timing-function: ease-in-out; */
    /* transition: 0.2s; */
    /* transition: top ease 2s; */
    
}
.calender-modal .schedule-date{
    font-weight: 500;
    background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    text-align: center;
}
@media (max-width:991px){
    .calender-modal {
        position: absolute;
        left: -10%;
        right: 0;
        top: 70%;
        width: 120%;
        z-index: 1001;
        display: flex;
        flex-direction: column;
        padding: 35px 20px 30px;
        overflow: auto;
    }
}
.react-calendar{
    border: none;
}
.react-calendar__navigation{
    background-color: #F8F8F8;
    padding: 5px;
    border-radius: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.calender-header button{
    outline: none;
    border-width: none;
    border: none;
    padding: 10px;
    background-color: FFFFFF;
    background-color: #FFFFFF;
    border-radius: 50px;
}
.calender-header span{
    padding: 8px 31px;
    color: #000000;
    font-weight: 600;
}
.check-in{
    flex-direction: column;
}
@media (max-width:767px){
    .check-in, .check-out {
        width: 50%;
        margin-top: 0px !important;
    }
    .check-in h5 {
        font-size: 18px !important;
        margin-bottom: 20px;
    }
    .check-out h5 {
        font-size: 18px !important;
        margin-bottom: 20px;
    }
}
.check-in h5{
    font-weight: 900 !important;
    font-size: 20px !important;
    color: #000000 !important;
    background: unset !important;
    -webkit-text-fill-color: unset !important;
}
.check-in p{
    color: #000000;
    margin-bottom: 10px;
}
.check-out{
    flex-direction: column;
    margin-top: 50px;
}
.check-out h5{
    font-weight: 900 !important;
    font-size: 20px !important;
    color: #000000 !important;
    background: unset !important;
    -webkit-text-fill-color: unset !important;
}
.check-out p {
    color:  #000000;
    margin-bottom: 10px;
}
@media (min-width:768px){
    .calender-footer-btn{
        position: absolute;
        bottom: 20px !important;
        right: 50px !important;
    }
}
.calender-footer-btn{
    bottom: 50px;
    right: 60px;
}
@media (max-width:767px){
    .calender-footer-btn {
        margin-top: 35px;
        justify-content: center;
    }
}


.react-calendar__navigation__prev-button
{
    outline: none !important;
    border-width: none !important;
    border: none !important;
    padding: 10px !important;
    background-color: #FFFFFF !important;
    border-radius: 50% !important;
    font-size: 50px !important;
}
.react-calendar__navigation__next-button{
    outline: none !important;
    border-width: none !important;
    border: none !important;
    padding: 10px !important;
    background-color: #FFFFFF !important;
    border-radius: 50px !important;
    font-size: 50px !important;
}
.react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button {
    display: none !important;
}

.react-calendar__navigation__label {
    color: #000000;
    background-color: #F8F8F8 !important;
    font-weight: 600;
}
.react-calendar__navigation__label:hover {
    background-color: #F8F8F8 !important;
}
.react-calendar__navigation button {
    width: 40px;
}
.react-calendar__month-view__weekdays__weekday > abbr {
    text-decoration: none !important;
}