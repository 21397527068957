// page starts //
.launcimg-soon-page {
    .banner-holder {
        background-image: url('../../images/common/launcingSoon-banner-bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 100vh;
        /* object-fit: cover; */

        @media (max-width:767px) {
            height: 40vh;
            padding: 50px 0px;
            position: relative;
        }

        @media (min-width:768px) and (max-width:991px) {
            height: 40vh;
        }

        @media (min-width:1200px) {
            height: 100vh;
            -ms-overflow-style: none;
            /* IE and Edge */
            scrollbar-width: none;

            /* Firefox */
            &::-webkit-scrollbar {
                display: none;
            }
        }

        .content-holder {
            color: #fff;
            max-width: 480px;
            z-index: 2;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @media (max-width:991px) {
                width: 100%;
                text-align: center;
              
            }

            .logo {
                img {
                    width: 100px;
                    margin-bottom: 40px;

                    @media (max-width:991px) {
                        margin-bottom: 16px;
                    }
                }
            }

            .intro {
                color: #FFFFFF;
                margin-top: 0px;

                p {
                    font-weight: 700;
                    font-size: 16px;
                    margin-bottom: 18px;

                    @media (max-width:767px) {
                        font-size: 12px;
                        margin-bottom: 10px;
                    }
                }

                h4 {
                    font-weight: bold;
                    font-size: 36px;
                    line-height: 42px;
                    margin-bottom: 20px;

                    @media (max-width:346px) {
                        font-size: 25px !important;
                        line-height: 34px !important;
                    }

                    @media (max-width:767px) {
                        font-size: 36px;
                        line-height: 42px;
                        margin: 0 auto 32px;
                        width: 90%;
                        text-shadow: 2px 4px 8px rgba(0, 115, 215, 0.5);
                    }

                    @media (min-width:992px) {
                        font-size: 48px;
                        font-weight: 700;
                        line-height: 62px;
                    }
                }
            }

            .launching-soon {
                background: linear-gradient(98.07deg, rgba(17, 82, 151, 0.72) 5.22%, rgba(96, 10, 189, 0.72) 101.6%);
                box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
                border-radius: 20px;
                padding: 14px 24px 20px;
                margin: 24px 0px;

                @media (max-width:991px) {
                    margin: 0px 40px 30px;
                }

                @media (min-width:992px) {
                    width: 70%;
                }

                p {
                    color: #FFFFFF;
                    margin-bottom: 0px;

                    &:nth-child(1) {
                        text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
                        font-weight: 500;
                        font-size: 24px;
                        margin-bottom: 5px;

                        @media (max-width:767px) {
                            font-size: 18px;
                        }
                    }

                    &:nth-child(2) {
                        font-size: 16px;
                        text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);

                        @media (max-width:767px) {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }

    .custom-line {
        margin: 20px auto;
    }

    .contact-bg {
        @media (max-width:991px) {
            padding: 32px 0px;
        }

        .contact-holder {
            .get-in-touch {
                width: 70%;
                margin: 0 auto 24px;
                @media (max-width:767px){
                    width: 80%;
                }
            }
        }
    }

}