@import "../variables";
@import "../mixins";

.banner-holder {
    position: relative;
    z-index: 9;
    @media screen and (min-width: 1200px) {
        max-height: 200px;
    }
    .banner {
        position: relative;
        display: inline-block;
        width: 100%;

        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: inline-block;
            background: linear-gradient(94.7deg, rgba(1, 25, 50, 0.71) 9.13%, rgba(52, 1, 106, 0.27) 96.14%);
        }

        img {
            // display: block;
            width: 100%;
            height: 200px;
            object-fit: cover;
            @media (min-width: 768px) and (max-width: 1199px) {
                height: 350px;
                object-fit: cover;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .banner {
            img {
                height: 240px;
                object-fit: cover;
            }
        }

        .text-holder h1 {
            font-size: 24px !important;
        }

        .text-holder p {
            font-size: 16px !important;
        }

        .vector2 {
            display: none;
        }

        .vector1 {
            display: none;
        }

        .header-holder {
            padding: 20px 25px;
        }
    }

    @media screen and (max-width: 540px) {
        .banner {
            img {
                height: 240px;
                object-fit: cover;
            }
        }

        .text-holder h1 {
            font-size: 22px !important;
            width: 95%;
            margin: 0 auto 12px;
        }

        .text-holder p {
            font-size: 14px !important;
        }

        .vector2 {
            display: none;
        }

        .header-holder {
            padding: 10px 15px;
        }
    }

    @media screen and (max-width: 360px) {
        .banner {
            img {
                height: 200px;
                object-fit: cover;
            }
        }

        .text-holder h1 {
            font-size: 18px !important;
        }

        .text-holder p {
            font-size: 12px !important;
        }

        .vector2 {
            display: none;
        }

        .header-holder {
            padding: 5px 5px;
        }
    }

    .text-holder {
        position: absolute;
        top: 70%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        z-index: 0;

        @media (max-width: 991px) {
            transform: translate(-50%, -20%);
        }

        h1 {
            @include main-heading;
            font-size: 40px;
            text-align: center;
            color: $color-white;
            margin-bottom: 8px;

            span {
                @include light-gradient;
            }
            .vix-char {
                color: #b6f177 !important;
                background: unset;
                -webkit-text-fill-color: unset;
            }
        }

        p {
            text-align: center;
            font-size: $fontSize-smaller;
            color: $color-white;
            font-weight: $fontWeight-bold;
            @media (max-width: 767px) {
                width: 90%;
                margin: 0 auto;
                line-height: 18px;
            }
        }
    }

    .vector-holder {
        position: absolute;
        top: 0;
        height: 100%;
        img {
            height: 100%;
        }
    }

    .vector1 {
        left: 0;
    }

    .vector2 {
        right: 0;
    }

    .corporate-logo {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 20px);
        border-radius: 20px;

        img {
            width: 50px;
            border-radius: 20px;

            @media (max-width: 767px) {
                width: 60px;
                // display: none;
            }
        }
    }
}

.category-image {
    position: relative;
    display: inline-block;
    border-radius: 54px;
    border: transparent;
    width: 100%;
    @media (min-width: 767px) and (max-width: 991px) {
        margin-bottom: 10px;
        .cat7-txt {
            top: 75% !important;
        }
    }
    .cat1 {
        border-radius: 17px;
    }

    .card-img {
        display: block;
        border-radius: 17px;
        object-fit: cover;
        width: 100%;
    }
    .cat6-txt {
        left: 45%;
        top: 25%;
        font-weight: 800;
        line-height: 15px !important;
        font-size: 38px;
        span {
            font-size: 28px;
        }
    }
    .cat4-txt {
        left: 32%;
        top: 85%;
        font-weight: 800;
        line-height: 15px !important;
        font-size: 30px;
    }
    .cat3-txt {
        left: 45%;
        top: 90%;
        span {
            font-weight: 600;
            font-size: 20.9532px;
        }
        font-weight: 600;
        font-size: 26.9532px;
    }
    .cat7-txt {
        left: 50%;
        top: 85%;
        img {
            width: 55%;
            margin-top: -20px;
        }
        .fun-txt {
            margin-left: 35px;
            margin-top: -20px;
        }
        p {
            line-height: 10px;
            font-weight: 700;
            font-size: 22px;
        }
    }
    .cat5-txt {
        left: 50%;
        top: 30%;
        img {
            width: 100%;
        }
    }
    .cat2-txt {
        left: 48%;
        top: 90%;
        font-weight: 800;
        font-size: 32px;
    }
    .cat1-txt {
        left: 50%;
        bottom: 5%;
        font-size: 60px;
        font-weight: 600;
        span {
            font-weight: 700;
            font-size: 22px;
        }
    }
    .category-txt {
        position: absolute;
        color: #ffffff;
        font-family: "League Spartan" !important;
        font-style: normal;
        line-height: 24px;
        transform: translate(-50%, -50%);
        width: calc(100% - 20%);
        text-shadow: 0px 4px 7px rgba(0, 0, 0, 0.28);
    }

    .category-info {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: rgba(0, 0, 0, 0.4);
        border: 2px solid rgba(0, 0, 0, 0.3);
        border-radius: 16px;
        padding: 8px 20px;
        width: calc(100% - 20%);
        z-index: 8;

        p {
            margin-bottom: 0;
            text-align: center;
            color: $color-white;
            text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
            font-size: $fontSize-smaller + 2;
            font-weight: $fontWeight-bold;
            line-height: 23px;
        }
    }

    @media (min-width: 992px) {
        .cat1 {
            height: 555px;
            border-radius: 17px;
        }

        .cat2 {
            height: 235px;
        }

        .cat3 {
            height: 317px;
        }

        .cat4 {
            height: 251px;
        }

        .cat5 {
            height: 301px;
        }

        .cat6 {
            height: 219px;
        }

        .cat7 {
            height: 285px;
        }
    }
    @media only screen and (max-width: 767) {
        .category-image {
            width: 50%;
            float: left;
            padding: 20px;
        }
        .card-columns {
            padding: 20px;
        }
    }
}

// recognitions and partners

.recognitions-holder {
    margin-top: 166px;

    h5 {
        @include recognitions-partners;
    }

    @include logo-cards;

    .owl-carousel .owl-stage-outer {
        @include container-paddingLeft;
    }

    .owl-stage {
        padding-left: 0px !important;
    }
}

.partners-holder {
    margin-top: 112px;
    overflow: hidden;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

    /* Firefox */
    & ::-webkit-scrollbar {
        display: none;
        /* Hide scrollbar for Chrome, Safari and Opera */
    }
    @media (max-width: 767px) {
        margin-top: 84px;
    }

    .partners-wrapper {
        padding-left: calc((100% - 1105px) / 2);
        overflow: auto;
        @media (max-width: 767px) {
            margin-left: 7px;
        }
        h5 {
            @include recognitions-partners;
        }

        // .list1 {
        //     .card-wrapper {
        //         &:first-child {
        //             // margin-left: 50px;
        //         }
        //     }
        // }

        .card-wrapper {
            margin-bottom: 20px;
            margin-right: 20px;

            .img-holder {
                min-width: 236px;
                padding: 0px 15px;

                // img {
                //     width: 100%;
                // }
            }
        }
    }
}
/* Mobile view CSS Starts*/
.mobile {
    display: none;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

    /* Firefox */
    & ::-webkit-scrollbar {
        display: none;
        /* Hide scrollbar for Chrome, Safari and Opera */
    }
}

.card-wrappers {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    //   margin-left: 10px;
    margin-right: -435px;

    .cards {
        display: inline-block;
        height: 300px;
        //box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        transition: 0.3s;
        width: 40%;
        margin: 10px;
        position: relative;
        width: 300px;
        height: 300px;
        .category-txt {
            position: absolute;
            color: #ffffff;
            font-family: "League Spartan" !important;
            font-style: normal;
            line-height: 24px;
            transform: translate(-50%, -50%);
            width: calc(100% - 20%);
           text-shadow: 0px 4px 7px rgba(0, 0, 0, 0.28);
        }
        .cat6-txt {
            left: -40%;
            top: 25%;
            font-weight: 800;
            line-height: 15px !important;
            font-size: 40px;
            span {
                font-size: 30px;
            }
        }
        .cat4-txt {
            left: -80%;
            top: 85%;
            font-weight: 800;
            line-height: 15px !important;
            font-size: 30px;
        }
        .cat3-txt {
            left: -18%;
            top: 86%;
            span {
                font-weight: 600;
                font-size: 20.9532px;
            }
            font-weight: 600;
            font-size: 26.9532px;
        }
        .cat7-txt {
            left: -80%;
            top: 80%;
            img {
                width: 45%;
                margin-top: -20px;
            }
            .fun-txt {
                margin-left: 40px;
                margin-top: -20px;
            }
            p {
                line-height: 10px;
                font-weight: 700;
                font-size: 24px;
            }
        }
        .cat5-txt {
            left: -4%;
            top: 30%;
            img {
                width: 80%;
            }
        }
        .cat2-txt {
            left: 30%;
            top: 85%;
            font-weight: 800;
            font-size: 34px;
        }
        .cat1-txt {
            left: 40%;
            bottom: 3%;
            font-size: 40px;
            font-weight: 600;
            line-height: 15px;
            span {
                font-weight: 700;
                font-size: 22px;
            }
        }
        .card-img {
            max-height: 100%;
            max-width: 100%;
            border-radius: 40px;
            height: fit-content;
            width: 78%;
            opacity: 1;
            @media (max-width: 767px) {
                height: 100%;
            }
        }
        .cat2 {
            margin-left: -66px;
        }
        .cat5 {
            margin-left: -131px;
        }
        .cat3 {
            margin-left: -195px;
        }
        .cat6 {
            margin-left: -258px;
        }
        .cat4 {
            margin-left: -320px;
        }
        .cat7 {
            margin-left: -382px;
        }
        .category-info {
            padding: 4px 10px;
            color: white;
            border-radius: 8px;
            //margin-left: -50px;
            border: 1px solid rgba(0, 0, 0, 0.3);
            width: calc(100% - 20%);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-weight: bold;
            width: 200px;
            height: 40px;
            background-color: rgba(0, 0, 0, 0.4);
            text-align: center;
            p {
                padding-bottom: 25px;
            }
        }
        .auto-1 {
            margin-left: -30px;
        }
        .auto-2 {
            margin-left: -94px;
        }
        .auto-3 {
            margin-left: -159px;
        }
        .auto-4 {
            margin-left: -224px;
        }
        .auto-5 {
            margin-left: -288px;
        }
        .auto-6 {
            margin-left: -350px;
        }
        .auto-7 {
            margin-left: -412px;
        }
    }
}
/* Mobile view CSS Ends */
@media only screen and (max-width: 767px) {
    .desktop {
        display: none;
    }
    .mobile {
        display: block;
    }
    .recognitions-holder h5 {
        font-size: 24px;
        margin: 0 auto 12px;
        line-height: 31px;
        width: 95%;
    }
}

.vix-wrapper {
    margin-top: 70px !important;
    @media (max-width: 767px) {
        margin-top: -8px !important;
    }
    .cerp-corp {
        @media (max-width: 767px) {
            margin-bottom: 45px !important;
        }
    }
    .vix-content {
        @include brand-gradient;
    }
    .vix-desc {
        color: #000 !important;
        line-height: 24px !important;
        @media (max-width: 767px) {
            text-align: center !important;
        }
    }
    .vix-desc1 {
        @media (min-width: 992px) {
            width: 95%;
            margin-right: 20px;
        }
    }
    .vix-desc2 {
        @media (min-width: 992px) {
            width: 95%;
            margin-left: 20px;
        }
    }
}

.vix-contact-wrapper {
    position: relative;
    margin-top: 70px !important;

    .vix-contact-img {
        border-radius: 60px 0px 0px 60px;
        height: 100%;
        display: block;
        object-fit: cover;

        @media (max-width: 991px) {
            border-radius: 50px 50px 0px 0px;
        }

        @media (min-width: 768px) and (max-width: 991px) {
            height: 400px !important;
        }
    }

    .closeIcon {
        position: absolute;
        right: 70px;
        z-index: 99;
        top: 20px;
        cursor: pointer;
        @media (max-width: 767px) {
            right: 10px;
            top: 8px;
        }
    }

    .vix-contact-info {
        background: #fdf2f2;
        height: 100%;
        border-radius: 0px 60px 60px 0px;
        padding: 48px 54px 42px;

        @media (max-width: 991px) {
            padding: 48px 24px 42px;
            border-radius: 0px 0px 50px 50px;
        }

        @media (min-width: 768px) and (max-width: 991px) {
            padding: 48px 48px 42px !important;
        }

        .line {
            margin: 32px 0px 16px !important;
        }

        .contact-card {
            background: #fff;
            border: unset;
            padding: 23px 24px;

            p {
                &:nth-child(1) {
                    line-height: 12px !important;
                    margin-bottom: 12px !important;
                    margin-top: 0px !important;
                    text-align: left !important;
                }

                &:nth-child(2) {
                    font-size: 14px !important;
                    line-height: 16px !important;
                }

                a {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 23px;
                }
            }
        }
    }
}

.vix-associations-holder {
    margin-bottom: 34px;
    @media (max-width: 767px) {
        margin-bottom: 40px;
    }
    .associates {
        background: #f5f5f5;
        border-radius: 32px;
        padding: 48px 32px 30px;

        h5 {
            text-align: center;
        }

        .logos {
            background: #ffffff;
            border-radius: 20px;
            padding: 29px 24px;

            @media (max-width: 991px) {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 22px 20px;
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
            }
        }
        .single-logo {
            background: #ffffff;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            padding: 29px 24px 0px;

            @media (max-width: 991px) {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 22px 20px 0px;
            }
        }
    }

    .grades-holder {
        width: 100%;
        border: 1px solid #e0e0e0;
        box-sizing: border-box;
        border-radius: 32px;
        background: #fff;
        margin-bottom: 36px;

        @media (max-width: 991px) {
            min-width: 262px;
            margin-right: 20px;
            height: max-content;
        }

        .grades {
            p {
                color: #000000;
                text-align: center;
                font-weight: 500;
                font-size: 36px;
                margin: 48px 10px 36px;

                @media (max-width: 767px) {
                    font-size: 32px;
                }
            }
        }
    }

    .activities {
        background: #fafafa !important;
        border-radius: 0px 0px 32px 32px;
        padding: 26px;
        display: flex;
        justify-content: space-between;

        @media (max-width: 991px) {
            display: block;
        }

        .activity-list {
            @media (max-width: 991px) {
                margin-bottom: 20px;
            }
        }

        p {
            margin-bottom: 0px;
            font-weight: 500;
            font-size: 16px;

            span {
                background: linear-gradient(98.07deg, #115297 5.22%, #600abd 101.6%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 18px;
            }
        }
    }
}

.vix-clients {
    @media (max-width: 767px) {
        margin-bottom: 90px;
    }
    .card-wrapper {
        .count {
            height: 155px;
            width: 186px;
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                margin-bottom: 0px;
                font-weight: 500;
                font-size: 36px;

                span {
                    background: linear-gradient(98.07deg, #115297 5.22%, #600abd 101.6%) !important;
                    -webkit-background-clip: text !important;
                    -webkit-text-fill-color: transparent !important;
                }
            }
        }

        .info {
            height: 111px !important;

            p {
                span {
                    background: linear-gradient(98.07deg, #115297 5.22%, #600abd 101.6%) !important;
                    -webkit-background-clip: text !important;
                    -webkit-text-fill-color: transparent !important;
                }
            }
        }
    }
}

.outcome-span {
    color: #c9c9c9 !important;
    background: transparent !important;
    -webkit-text-fill-color: unset !important;
    font-size: 14px !important;
}
.outcome-text {
    font-size: 14px !important;
}
.grades-holder-custom {
    @media (min-width: 992px) {
        width: 140% !important;
    }
}
.floating-contact-button {
    background: linear-gradient(64.21deg, #600abd 10.36%, #115297 105.36%);
    border-radius: 30px 0px 0px 30px;
    padding-top: 1%;
    position: fixed;
    cursor: pointer;
    right: 0;
    top: 30%;
    justify-content: center;
    width: 85px;
    height: 216.55px;
    z-index: 999;
    p {
        padding-top: 8px;
        font-weight: 500;
        font-size: 14.7284px;
        line-height: 20px;
        text-align: center;
        width: 80%;
        color: #ffffff;
    }
}
