@import '../../variables';
@import '../../mixins';


.myfamily-holder {
    margin: 10px auto;
}
.myfamily-first-category-holder{
    @media (min-width: "767px"){
        display: flex;
        justify-content: center;
        width: 100%;
    }
    overflow: auto;
}
.stories{
   
    .heading{  
        text-align: center;
        h2{
            font-size: 42px;
            line-height: 55px;
            margin-bottom: 50px;
            @media (max-width: "767px"){
                font-size: 31px;
                line-height: 42px;
                margin-bottom: 30px;
            }
            span{
                background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;
                -webkit-box-decoration-break: clone;
            }
        }

    }
    .heading2{
        text-align: left;
        h2{
            font-size: 42px;
            line-height: 55px;
            margin-bottom: 20px;
            @media (max-width: "767px"){
                font-size: 25px;
                line-height: 42px;
                margin-bottom: 15 px;
            }
            span{
                background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;
                -webkit-box-decoration-break: clone;
            }
        }

    }
    .stories-holder{
        overflow: hidden;
        scroll-behavior: smooth;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        
        /* Firefox */
        & ::-webkit-scrollbar {
            display: none;
            /* Hide scrollbar for Chrome, Safari and Opera */
        }
        .wrapper{
            overflow: auto;
            padding-left: calc((100% - 1105px) / 2);
            display: flex;
            overflow-y: hidden;
        }

    }
    .card-wrapper{
        background-color: #f9f9f9;
        border-radius: 0 0 32px 32px;
        margin-right: 20px;
        width: 473px;
        height: 348px;
        @media (max-width: '767px'){
            width: 273px;
            margin-right: 0px;
            height: 370px;
        }
        .card-img{
            img{
                border-radius: 32px 32px 0 0;
                height: 250px;
                width: 100%;
                object-fit: cover;
            }
        }
        .title{
            

            p{
                font-size: 18px;
                line-height: 26px;
                padding: 18px 24px 20px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }
    }
}
// ------* Slider start *---------
.giftSlider {
    img {
        border-radius: 54px;
    }

    h3 {
        font-size: $fontSize-smaller +2;
        display: inline-block;
        color: #fff;
        padding: 10px 20px;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
        font-style: normal;
        background: rgba(0, 0, 0, 0.4);
        border: 2px solid rgba(0, 0, 0, 0.3);
        box-sizing: border-box;
        border-radius: 16px;
    }

    .carousel-caption {
        position: absolute;
        transform: translateY(-50%);
        bottom: initial;
        top: 50%;
    }
}

.carousel-indicators li {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: #eee;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.32) 100%);
    opacity: .3;
    border-radius: 54px;

    @media (max-width:767px) {
        border-radius: 0px;
    }
}

// --------* slider media queries *-----------
.giftSlider {
    @media(max-width: 767px) {
        img {
            border-radius: 54px;
            height: 357px;
            object-fit: cover;
        }

        h3 {
            font-size: 14px !important;
            border-radius: 10px !important;
            padding: 5px 10px !important;
        }

        .carousel-indicators {
            margin-bottom: 0;
        }

        .giftSlider {
            margin-top: 30px !important;
        }
    }
}

// -------* Slider end *--------



// -------* giftCard start *-------

.giftCard-holder {
    margin-top: 104px;

    .giftCard {
        display: flex;
        align-items: center;
        margin-bottom: 50px;

        &:nth-child(even) {
            flex-direction: row-reverse;

            .giftCard-content {
                margin-left: 0px;
                margin-right: 36px;
            }
        }
    }

    .row {
        align-items: center;

        &:nth-child(even) {
            display: flex;
            flex-direction: row-reverse;
        }
    }

    .giftCard-img img {
        border-radius: 54px;
        height: 450px;
        object-fit: cover;
    }

    .giftCard-content {
        margin-left: 21px;
        padding: 20px 0;

        .title p {
            font-style: normal;
            font-weight: 500;
            font-size: $fontSize-larger;
            line-height: $lineHeight-larger +2;
        }

        .desc p {
            font-style: normal;
            font-weight: 500;
            font-size: $fontSize-smallest;
            line-height: $lineHeight-tiny;
        }
    }

    .owl-corporate .owl-dots {
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
    }
}

// -------* Gift cards media queries *--------
@media(max-width: 768px) {
    .giftCard-holder {
        margin-top: 30px !important;
    }

    .giftCard-content {
        margin-left: 10px !important;
        margin-right: 10px !important;

        .title p {
            font-size: 31px !important;
            line-height: 40px !important;
            text-align: center;
            margin-top: 12px;
            margin-bottom: 12px;
        }

        .desc p {
            font-size: 14px !important;
            line-height: 24px !important;
            color: rgba(0, 0, 0, 0.6);
            font-weight: normal !important;
        }
    }

    .giftCard {
        margin-bottom: 10px !important;

        .giftCard-img img {
            width: 100%;
            height: 350px;
            object-fit: cover;
        }
    }

    .giftCard:nth-child(even) .giftCard-content {
        margin-right: 10px !important;
    }
}

@media(min-width: 768px) {
    .giftCard {
        padding-right: 3em;
    }
}

// --------* giftCard end *-------------

// ----* prepare Gift form start *-----
.prepareGift-holder {
    padding: 48px 32px 36px;
    border: 1px solid #C9C9C9;
    text-align: center;
    border-radius: 32px;
    margin-bottom: 24px;
    position: relative;
}

.prepareGift-holder .close-icon {
    display: block;
    right: 0;
    top: 0;
    margin: 20px;
    color: #222;
    position: absolute;
    cursor: pointer;
    @media (min-width:992px){
        right: 5px;
        top: 5px;
    }
}

.prepareGiftFormModal .close-icon {
    display: block;
    right: 0;
    top: 0;
    margin: 20px;
    color: #222;
    position: absolute;
}

.prepareGift-holder h5 {
    font-weight: bold;
    font-size: 36px;
    line-height: 47px;
    background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.prepareGift-holder h3 {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 36px;
}

.prepareGift-holder h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    margin-bottom: 20px;
}

.prepareGift-holder h6 {
    text-align: left;
}

// .prepareGift-holder p {
//     text-align: left;
// }

.prepareGift-holder {
    .input-holder .custom-input input {
        font-weight: bold;
        font-size: 22px;
        line-height: 31px;
        text-align: center;
        color: $color-black;

        &::placeholder {
            opacity: .5;
        }
    }

    .input-holder .custom-input label {
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        opacity: 1;
        display: block;
    }

    .input-holder .card--bg {
        background-color: #ffffff;
    }

    .input-holder .card--bg2 {
        background: #F6F6F6;
        border: 2px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 24px;
        padding: 20px 24px;
    }

    .input-holder .card--bg2 .inner-card {
        background: #FFFFFF;
        border-radius: 16px;
        padding: 18px 12px;
    }

    .input-holder .card--bg2 .inner-card input {
        color: #000000;
        text-align: left;
        font-weight: normal;
        font-size: 18px;
        line-height: 23px;
        opacity: 1;
    }

    .input-holder .card--bg2 .inner-card label {
        display: flex;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        opacity: .5;
    }

    .prepare-btn {
        background: linear-gradient(91.61deg, #600ABD 3.87%, #115297 100%);
        border-radius: 24px;
        width: 100%;
        font-weight: bold;
        font-size: 24px;
        line-height: 31px;
        text-align: center;
        margin: 0;
    }

    .input-holder .card--bg2 p {
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        color: black;
    }

    .line {
        opacity: 0.1;
        border-top: 1px solid #000;
        margin: 24px auto;
        width: 70%;
    }

    @media(min-width : 768px) {
        .close-icon {
            display: none;
        }

    }
}

// -------* prepare Gift form end *---------


// ----* Prepare Gift Form Modal *----

.prepareGiftFormModal {
    text-align: center;

    // .modal-dialog {
    //     top: 50%;
    //     transform: translateY(-55%) !important;
    // }

    .modal-content {
        padding: 48px 32px;
        @include hammock-custom-border
    }

    @media screen and (max-width: 540px) {
        .modal-content {
            margin-top: 140px;
            padding: 24px 16px;
            @include hammock-custom-border
        }
    }

    .modal-wrapper {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }

    .overlay-modal {
        margin: 10px auto;
        width: 100%;
        height: 100%;
        opacity: 0.4;
        background-color: #868586;
        z-index: 1;
    }

    .card-box-index {
        display: flex;
        justify-content: center;
        left: 0;
        right: 0;
        margin: 0 auto;

        @media screen and (max-width: 500px) {
            width: fit-content;
            left: 50%;
        }
    }

    .modal-title {
        font-weight: 500;
        font-size: 26px;
        line-height: 34px;
        text-align: center;
    }

    .modal-body {
        padding: 0;
        margin-top: 24px;
    }

    .custom-input {
        @include hammock-border-bg;
        padding: 14px 24px;

        textarea,
        input {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 0;
            border-top: transparent;
            border-right: transparent;
            border-left: transparent;
            background-color: transparent;
            padding: 0px;
            font-size: 18px;
            color: #000000;
            opacity: 1;
            text-align: center;
        }

        label {
            font-size: 14px;
            color: $color-black;
            opacity: 1;
            font-weight: 500;
            margin-top: 12px;
            text-align: center;
        }

        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    .btn-delete {
        background: $color-white;
        border-radius: 24px;
        padding: 16px 40px;
        font-weight: $fontWeight-bold;
        font-size: 18px;
        line-height: 23px;
        color: #2D2D2D;
        border-color: #2D2D2D;

        &:hover,
        &:active {
            background: $color-white !important;
            border-color: #2D2D2D !important;
            color: #2D2D2D !important;
        }
    }

    .btn-done {
        background: #2D2D2D;
        border-radius: 24px;
        padding: 16px 60px;
        font-weight: $fontWeight-bold;
        font-size: 18px;
        line-height: 23px;
        border-color: #2D2D2D;
        color: $color-white;

        &:hover,
        &:active {
            background: #2D2D2D !important;
            color: $color-white;
        }

        .line {
            opacity: 0.1;
            border-top: 1px solid #000;
            margin: 24px auto;
        }
    }
    .PhoneInputInput {
        max-width: calc(100% - 80px);
        padding-left: 40px !important;
        margin-left: -40px;
    }
}

.white-button {
    padding: 20px 20px;
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    background-color: #ffffff;
    margin: 10px 10px;

    a {
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;
    }
    @media screen and (max-width: 450px){
        p{
            font-size: 0.7rem;
        } 
    }
}
.custom-button.disabled{
    background: #C9C9C9 !important;       
}
.custom-button {
    padding: 20px 20px;
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    background: linear-gradient(91.61deg, #600ABD 3.87%, #115297 100%);
    color: #fff;
    margin: 10px 10px;
    @media screen and (max-width: 450px){
        p{
            font-size: 0.7rem;
        } 
    }
   
}

.custom-button a {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
}

.stcky-leftButton {
    display: inline-block;
    width: 40%;
    text-align: center;
}

.stcky-rightButton {
    display: inline-block;
    width: 60%;
    text-align: center;
}

.myfamily-holder {

    @media(min-width: 768px) {
        .prepareGift-desk {
            display: block;
        }

        // .prepareGift-mobile {
        //     display: none;
        // }
    }

    @media(max-width: 767px) {
        .prepareGift-desk {
            display: none;
        }

        .prepareGift-mobile {
            display: block;
        }
    }
}

.prepareGift-mobile {
    top: 0;
    left: 0;
    position: fixed;
    overflow: auto;
    background: #22222275;
    z-index: 22;
    height: 100%;
    width: 100%;

    .prepareGift-holder {
        background-color: #ffffff;
        overflow-x: hidden;
    }
    @media (min-width:992px){
        width: 100%;
        margin: 0 auto;
        top: 50%;
        padding: 0px 20%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}

@media(max-width: 768px) {
    .mobile-buttons {
        display: block;
        position: fixed;
        bottom: 1%;
        left: 3%;
        right: 3%;
        z-index: 8;
    }
}

@media(max-width: 412px) {
    .mobile-buttons {
        .white-button {
            padding: 15px 15px;
        }

        .custom-button {
            padding: 15px 15px;
        }

        a {
            font-size: 15px;
        }
    }
}

@media(min-width: 768px) {
    .mobile-buttons {
        display: none;
    }
}

.prepareGiftFormModal {
    .modal-seprator {
        border-bottom: 1px solid #000;
        opacity: 0.6;
        width: 60%;
        margin: 0 auto;
    }
}

// ------* contact modal *--------
.contact-modal {
    top: 0;
    left: 0;
    position: fixed;
    overflow: auto;
    background: #22222275;
    z-index: 22;
    height: 100%;
    width: 100%;

    .contact-holder {
        top: 20%;
        margin: 40px 20px;
        background-color: #ffffff;
        position: relative;

        @media (max-width:767px) {
            padding: 32px 24px;
            top: 50%;
            left: 50%;
            position: absolute;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            width: 92%;
            margin: 0 auto;

            .closeIcon {
                position: absolute;
                top: 10px;
                right: 10px;
            }
        }
    }

    .close-icon {
        display: block;
        right: 0;
        top: 0;
        margin: 20px;
        color: #222;
        position: absolute;
        cursor: pointer;
      
    }
}

// --------* Prepare form responsive *-----------
.myfamily-holder {

    @media screen and (max-width: 540px) {
        .prepareGift-holder {
            padding: 48px 10px 25px;

            h5 {
                font-size: 32px;
            }

            h3 {
                font-size: 12px;
            }

            .input-holder {
                .custom-input {
                    .card--bg2 {
                        padding: 20px 10px;
                    }

                    input {
                        font-size: 18px;
                    }

                    label {
                        font-size: 15px;
                    }

                    .inner-card label {
                        font-size: 10px;
                    }
                }
            }

            .prepare-btn {
                font-size: 18px;
                width: 100%;
                margin: 0;
            }
        }

        // .calender-modal {
        //     top: 67%;
        //     z-index: 1100;
        //     position: absolute;
        //     width: min-content;
        //     // left: 1%;
        //     margin: auto;
        // }

        // .guest-modal {
        //     top: 70%;
        //     z-index: 1000;
        //     position: absolute;
        //     padding: 32px 45px;
        //     width: 100%;
        //     justify-content: center;
        //     overflow: auto;
        //     margin: 0 auto;
        //     // left: 50%;
        // }
    }

    @media screen and (min-width: 540px) {
        // .calender-modal {
        //     top: 5%;
        //     z-index: 1100;
        //     position: fixed;
        // }

        // .guest-modal {
        //     top: 5%;
        //     z-index: 1000;
        //     position: fixed;
        //     padding: 32px 30px;
        // }
    }

    @media screen and (min-width: 768px) {
        // .calender-modal {
        //     top: 66%;
        //     left: 0%;
        //     width: 100%;
        //     z-index: 1100;
        //     position: absolute;
        // }

        // .guest-modal {
        //     top: 90%;
        //     z-index: 1000;
        //     width: 93%;
        //     left: 4%;
        //     position: absolute;
        //     padding: 32px 30px;
        // }
    }

    @media screen and (min-width: 1024px) {
        // .calender-modal {
        //     top: 65% !important;
        //     left: 0% !important;
        //     z-index: 1100;
        // }

        // .guest-modal {
        //     top: 90% !important;
        //     z-index: 1000;
        //     left: 5% !important;
        //     width: max-content !important;
        //     position: absolute;
        //     height: max-content;
        // }
    }

    @media screen and (min-width: 1200px) {
        // .calender-modal {
        //     top: 40%;
        //     z-index: 1000;
        //     width: 670px;
        //     left: 35%;
        //     right: 0;
        // }

        // .guest-modal {
        //     top: 49%;
        //     z-index: 1000;
        //     left: 35%;
        //     width: 50%;
        //     position: absolute;
        // }
    }
}

@media screen and (max-width:600px) {
    .custom-input .mob-error {
        text-align: center;
    }
}

.my-family-holder {
    @media (min-width:992px){
        margin-top: -20px !important;
    }
}

.my-family-wrapper {
    margin-top: 90px;
    @media (max-width:767px){
        margin-top: 50px !important;
    }
    .my-family-card {
        @media(min-width:768px){
            &:nth-child(odd){
                .row {
                    flex-direction: row-reverse;
                }
            }
        }
    }
}

.create-gift-holder {
    margin: 5px auto 100px !important;
    @media (max-width:767px){
        margin: 100px auto 0  !important;
    }
}

.stories-details-page{
    @media (max-width: "767px"){
        .stay-back {
            top: -389px !important;
            left: 16px !important;
          }
        }
    .package-holder .package-title {
        margin-bottom: 34px;
    }
    .prepareGift-holder {
        padding: 24px 16px 20px;
        .calender-modal {
            left: auto;
        }
    }
    
}