@import "../variables";
@import "../mixins";

a:hover {
  text-decoration: none;
}

.stay-forms {
  padding: 0px !important;
}
.itinerary-container {
  scroll-margin-top: 140px;
  @media (max-width: 767px) {
    .itinerary-mob {
      padding: 0px 15px !important;
    }
    .stay-header {
      padding: 0px !important;
    }
    .packages-prices {
      gap: 20px !important;
    }
  }
  .packages-prices {
    gap: 40px;
    .active-pkg {
      background: linear-gradient(98.07deg, #115297 5.22%, #600abd 101.6%);
      color: #ffffff !important;
    }
    .non-active-pkg{
      span {
        background: linear-gradient(98.07deg, #115297 5.22%, #600abd 101.6%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .pkg-buttons {
      width: 237px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      background-color: #fff;
      border-radius: 22px;
      font-size: 18px;
      font-weight: 700;
      border: 2px solid #600abd;
      @media (max-width: 767px) {
        font-size: 14px;
        height: 50px;
      }
    }
  }
}
.stay-container {
  display: flex;
  justify-content: center;
  margin: 0px;
  max-width: 1500px;
  @media (max-width: 991px) {
    margin: auto;
  }
  @media (min-width: 1500px) {
    margin: auto;
  }
}
.itinerary-package-title {
  display: flex;
  align-items: center;
  margin-bottom: 0px !important;
  @media (max-width: 767px) {
    justify-content: flex-start;

    p {
      padding: 0px !important;
    }
    span {
      font-size: 34px !important;
    }
  }
  p {
    margin-bottom: 0;
    padding-left: 26px;
  }
  span {
    margin: 0 5px;

    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    display: flex;
    align-items: center;

    color: #000000;
  }
}
.itinerary-header {
  position: relative;
  @media (max-width: 767px) {
    .itinerary-mob {
      padding: 0px 15px !important;
    }
    .safe-note {
      flex-direction: column;
    }
    .note {
      .notes {
        max-height: max-content !important;
      }
    }
  }
  .note {
    .notes {
      max-height: 100px;
      p {
        font-size: 16px;
      }
    }
  }
  .itinerary-package-title {
    display: flex;
    align-items: center;
    margin-bottom: 0px !important;
    @media (max-width: 767px) {
      justify-content: flex-start;

      p {
        padding: 0px !important;
      }
      span {
        font-size: 34px !important;
      }
    }
    p {
      margin-bottom: 0;
      padding-left: 26px;
    }
    span {
      margin: 0 5px;

      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 36px;
      display: flex;
      align-items: center;

      color: #000000;
    }
  }
  .sanitized-row {
    .rowww {
      min-height: 133px;
      padding: 30px 20px 15px 20px !important;
      .firstss {
        padding-top: 10px;
      }
    }
  }
  .safe-note {
    .row-note {
      .rowww {
        min-height: 133px;
        .firstss {
          padding-top: 11px;
          width: 10%;
        }
      }
    }
    .notes {
      min-height: 128px;
    }
  }
}
// summary holder css starts

.itinerary-features-holder {
  p {
    margin-bottom: 0px !important;
  }
  font-style: normal;
  background: #fbfbfb;
  border-radius: 24px;
  font-size: 16px;

  padding: 32px 0px 32px 4px;
  @media (max-width: 991px) {
    padding: 0px 15px !important;
    font-size: 15px;
    margin: 0 calc((100% - 100vw) / 2);
    border-radius: 0px;
    display: flex;
    flex-direction: column-reverse;
    .act-rating {
      flex-direction: row-reverse;
      margin: 20px 0px;
      border-top: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
      padding: 20px 0px;
    }
  }
  .itinerary-loc-details {
    @media (max-width: 991px) {
      border: none;
      padding: 0px;
      padding-bottom: 10px;
      .location {
        justify-content: space-between;
        
        padding: 0px 20px;
        .loc-val {
          align-self: flex-end;
          text-align: right;
        }
      }
    }
    padding-left: 19px;
    border-right: 1px solid #e0e0e0;

    .loc-key {
      font-weight: 500;
    }
    .itinerary-details {
      @media (max-width: 991px) {
        border: none;
        justify-content: space-between;
        padding: 0px 20px;
      }
      display: flex;
      gap: 15px;
      margin-top: 15px;

      p {
        margin-bottom: 16px !important;
        font-weight: 500;
      }
      .ages {
        margin-bottom: 0px !important;
      }
      .sum-val {
        position: relative;
        margin-top: 1px;

        p {
          font-weight: 400;
        }
      }
    }
  }
  .sum-act-level {
    display: flex;
    flex-direction: column;
    width: 100%;
    @media (min-width: 991px) {
      .level-meter {
        width: 270px;
        #uv-gauge-chart {
          width: 450px !important;
          overflow: visible;
          svg {
            width: 300px !important;
            overflow: visible;
            padding-top: 10px;
            margin-left: -7px;
          }
        }
      }
    }

    .GaugeContainer {
      position: relative;
      display: flex;
      justify-content: center;
    }

    span {
      margin-left: 16px;
      font-weight: 400;
    }

    font-weight: 500;
    .text {
      height: 49px;
      width: 92px;
      bottom: 7px;
      font-size: 32px;
      margin-left: -7px;
    }

    @media (max-width: 767px) {
      gap: 10px;
      .lev-tit {
        padding-left: 2px;
      }
      border: none;
      .level-meter {
        width: 240px;

        .text {
          height: 39px;
          width: 72px;
          bottom: 13px;
        }
      }
    }
    @media (max-width: 430px) {
      margin-top: 10px;
      justify-content: space-around;
      gap: 25px;
      span {
        margin-left: 3px;
      }
      .level-meter {
        width: 170px;

        .text {
          height: 24px;
          width: 42px;
          font-size: 18px;
          margin-left: 2px;
          bottom: 10px;
        }
      }
    }
    border-right: 1px solid #e0e0e0;
  }
  .sum-ratings {
    gap: 15px;

    width: 100%;
    border-right: 1px solid #e0e0e0;

    img {
      @media (max-width: 767px) {
        height: 69px;
      }
      height: 89px;
      width: 115px;
    }
  }
  .sum-price {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .date-price {
      flex-direction: column;
    }
    @media (max-width: 991px) {
      padding-top: 17px;
      flex-direction: column;

      align-items: center;
      .date-price {
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 10px;
        justify-content: center;
      }

      .view-prices-btn {
        display: none;
      }
    }

    .normal {
      text-decoration: line-through;
    }

    .view-prices-btn {
      background: linear-gradient(98.07deg, #115297 5.22%, #600abd 101.6%);
      border-radius: 22px;
      padding: 19px 62px;
      font-size: 18px;
      line-height: 23px;
      color: #ffffff;
      font-weight: 700;
    }
  }
}

//nav holder css starts

.itinerary-nav {
  margin: 0 calc((100% - 100vw) / 2);
  max-width: none;

  width: 100vw;
}
.nav-holders {
  white-space: nowrap;
  scroll-behavior: smooth;
  ul {
    margin-bottom: 0px !important;
    cursor: pointer;
    width: 100%;
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
    padding-top: 22px;
    padding-right: 0px;
    padding-bottom: 12px;

    padding-left: 0px;

    li {
      list-style: none;
      display: inline-block;
      cursor: pointer;
      a {
        font-weight: $fontWeight-medium;
        font-size: 18px;
        text-align: center;
        color: $color-black;
        opacity: 0.3;
        @media screen and (max-width: 900px) {
          margin-right: 51px;
        }
        padding-bottom: 12px;

        &:hover {
          text-decoration: none;
        }
      }
      .nav-ls {
        padding-right: 0px;
      }
      .active {
        @include brand-gradient;
        opacity: 1;
        border: none;
        border-bottom: 2px solid $color-secondary;
        padding-bottom: 14px;
      }
    }
  }
}
@media (max-width: 767px) {
  .nav-holders {
    display: none;
  }
  .sticky-nav {
    display: none;
  }
  .mob-nav {
    margin: 0 calc((100% - 100vw) / 2);
    max-width: none;
    overflow: auto;
    position: relative;
    width: 100vw;
  }
}

@media screen and (max-width: 1024px) {
  .nav-holder {
    overflow: hidden;

    white-space: nowrap;
    scroll-behavior: smooth;
    nav {
      overflow: auto;
    }
  }
 
 .itinerary-container { 
  .package-nav{
  ul {
    overflow: auto;
    justify-content: left;
    margin: 0px auto 25px;
    padding: 25px 0px 16px;
  }
}
}
  .nav-holders ul::-webkit-scrollbar {
    display: none;
  }
  /* For Firefox and IE */
  .nav-holders ul {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}
//tab holder css ends
//contact forms css starts

.itinerary-enquiry-holder {
  @media (max-width: 991px) {
    .tittle {
      font-size: 16px !important;
    }
  }
  .tittle {
    font-weight: 700;
    font-size: 18px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
    margin-bottom: 16px !important;
  }
  .form-group-new {
    border: 2px solid #f8f8f8;
    box-sizing: border-box;
    border-radius: 24px;
    padding: 17px 17px;
    text-align: center;
    margin-bottom: 16px;
    position: relative;

    input {
      border-bottom: 0.690724px solid #000000;
      border-top: none;
      height: 22px;
      border-right: 0;
      border-left: 0;
      padding: 0 !important;
      border-radius: 0px;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #000000;
    }
  }
  .form-group-stay {
    border: 2px solid #f8f8f8;
    box-sizing: border-box;
    border-radius: 24px;
    padding: 14px 34px;
    text-align: center;
    margin-bottom: 25px;
    position: relative;

    label {
      font-weight: 500;
      font-size: 12.4472px;
      line-height: 16px;
    }
    input {
      border-bottom: 0.690724px solid #000000;
      border-top: none;
      height: 22px;
      border-right: 0;
      border-left: 0;
      padding: 0 !important;
      border-radius: 0px;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #000000;
    }
  }
  background: #ffffff;
  border: 0.690724px solid #c9c9c9;
  border-radius: 22.1032px;
  padding: 33px 25px;
  margin-bottom: 20px;
  @media (max-width: 991px) {
    .stay-price {
      display: none !important;
    }
  }
}

.enquire {
  margin-top: 20px;

  background: linear-gradient(91.61deg, #600abd 3.87%, #115297 100%);
  border-radius: 24px;
  padding: 14px 25px;
  font-size: 24px;
  line-height: 31px;
  color: #ffffff;
  font-weight: 700;
  @media (max-width: 991px) {
    font-size: 20px;
    padding: 16px 41px;
  }
}
.enquire:hover {
  color: #fff;
}

.enquiry-contact {
  margin-top: 25px;
  padding: 40.1077px 33.1925px 42.8737px;

  height: 180px;

  background: #ffffff;
  border: 0.691511px solid #c9c9c9;
  border-radius: 22.1284px;
  .tel {
    p {
      font-weight: 700;
      color: #000000;
      margin: 0px !important;
      padding-bottom: 11px;
      border-bottom: 1px solid #e0e0e0;
    }
  }
  .tel {
    font-weight: 700;
    color: #000;
  }
  .mobile {
    img {
      height: 35px;
      width: 35px;
    }
    margin-top: 33px;
  }
}
//contact forms css ends
//overview css starts
.package-nav {
  .mob-tabs {
    display: none;
  }

  @media (max-width: 767px) {
    margin-bottom: 15px !important;
    ul {
      margin-top: -41px !important;
      position: absolute;
      margin-left: -15px !important;
      z-index: 20;
    }
    .stay-ul {
      width: 100vw !important;
      margin-left: -15px;
    }
    .fixed {
      position: fixed;
      z-index: 9;
      top: 0;
      left: 0;
      width: 100%;
      ul {
        margin-left: 0px !important;
      }
      scroll-behavior: smooth;
      .details {
        position: relative;
      }
    }
    .mob-tabs {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      width: 100%;
      color: #ffffff;
      margin-top: 0px !important;
      border-bottom: 1px solid #ffffff;
      background: linear-gradient(98.07deg, #115297 5.22%, #600abd 101.6%);

      span {
        margin-left: 15px;
      }
    }

    .mob-details {
      padding: 24px 0px;
    }
  }
}
.sticky-nav {
  position: fixed;
  z-index: 9;
  top: 74px;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: 1px 1px 5px #ccc;
  @media (min-width: 1500px) {
    max-width: 1500px;
    left: 50%;
    margin-left: -750px;
  }
  .stay-tab {
    @media (min-width: 992px) {
      margin-left: -17% !important;
    }
  }

  ul {
    margin-left: 0px;
  }
  scroll-behavior: smooth;
  .details {
    position: relative;
  }
}
@media only screen and (min-device-width: 991px) and (max-device-width: 1250px) {

.packageTabs-description{
  .stay-package-description {
    scroll-margin-top: 187px !important;
  }
}
}
.stay-features-holder {
  p {
    margin-bottom: 0px !important;
  }

  background: #fbfbfb;
  border-radius: 24px;
  font-style: normal;
  font-size: 16px;

  margin: auto;

  padding: 32px 23px 32px 25px;
  .hd-sum-prices {
    display: none;
  }
  .loc-features {
    padding-left: 0px;
  }
  @media (min-width: 991px) {
    .loc-features {
      width: 80%;
    }
    .hol-rating {
      width: 20%;
    }
  }
  @media only screen and (min-width: 991px) and (max-width: 1250px) {

    .loc-features {
      width: 100%;
      .stay-features{
        border: none;
        padding: 0px 10px;
        grid-gap: 10px;
      }
    }
    .hol-rating {
      margin-top: 20px;
      width: 100%;
      border-top: 0.5px solid #e0e0e0;
      padding-top: 20px;
    }
  
}
  @media (max-width: 991px) {
    padding: 24px 15px !important;
    border-radius: 0px;

    margin: 0 calc((100% - 100vw) / 2);
    flex-direction: column-reverse;
    .hd-sum-prices {
      display: flex;
      align-items: center;
      flex-direction: column;
      border-bottom: 0.5px solid #e0e0e0;
      .normal {
        font-weight: 400;
        font-size: 16px;
        color: #959595;
        text-decoration: line-through;
        align-self: center;
      }
      .discount {
        font-weight: 700;
        font-size: 28px;
        padding-right: 8px;
      }
    }
    .hol-rating {
      padding-bottom: 20px;
      padding-top: 12px;
      border-bottom: 0.5px solid #e0e0e0;
    }
    .loc-features {
      flex-direction: column;
      padding: 0px;
      .stay-location {
        border-right: none;
        border-bottom: 0.5px solid #e0e0e0;
        padding-right: 0px;
        padding-bottom: 13px;
        width: 100%;
        .sum-keys {
          justify-content: space-between;
        }
        .sum-values {
          justify-content: space-between;
          gap: 0px;
        }
        .location {
          width: auto;
          padding: 0px;
          p{
            text-align: right;
          }
          span {
            align-self: flex-end;
          }
        }
      }
    }
    .stay-features {
      width: 100%;
      gap: 30px;
      border-right: none;
      padding: 24px 0px 0px 0px !important;
    }
  }
}
.hol-rating {
  padding: 0px;
  .rating-count {
    gap: 5px;
    flex-wrap: wrap;
  }
  @media (min-width: 991px) {
    .rating-count {
      gap: 3px;
      flex-direction: row;
      align-items: center;
      margin-left: 7px;
      font-size: 15.6px;
      margin-top: 3px;
    }

    justify-content: center;
    margin-top: -20px;
  }
}
.stay-location {
  gap: 20px;
  width: 40%;
  border-right: 1px solid #e0e0e0;
  padding-right: 20px;
  .sum-keys {
    gap: 30px;
  }
  .sum-values {
    gap: 42px;
  }

  .location {
    font-size: 16px;

    span {
      font-size: 14px;
    }
  }
}
.stay-features {
  .amenities-wrapper {
    gap: 25px;
  }
  @media (min-width: 1224px) {
    min-width: 300px;
  }
  width: 60%;
  border-right: 1px solid #e0e0e0;
  padding: 0px 25px;
  gap: 25px;

  .amenities-wrapper {
    flex-direction: column;
    .stay-amenities {
      gap: 5px;
      align-items: center;
      @media (max-width: 767px) {
   img{
    width: 22px;
   }
  }
      .stay-amenities-txt {
        padding-top: 3px;
      }
    }
  }
}

.amenities-holder {
  .amenities-sub-holder {
    margin-bottom: 40px;
    .title {
      font-size: 18px;
      font-weight: 700;
    }
    .amenities-list {
      margin-top: 15px;
      flex-flow: wrap;
      @media (max-width: 991px) {
        flex-direction: column;
        .stay-amenities {
          width: 100% !important;
          @media (max-width: 767px) {
            img {
              width: 24px !important;
              border-radius: 0px;
            }
          }
        }
      }

      row-gap: 20px;
      .stay-amenities {
        gap: 15px;
        width: 50%;
        img {
          border-radius: 0px;
          width: 24px;
        }
        align-items: center;
        .stay-amenities-txt {
          padding-top: 3px;
        }
      }
    }
  }
}

.reviews-list {
  margin-top: 20px;
  @media (max-width: 767px) {
    height: max-content;
    overflow: visible;
    .review-holder {
      background: none !important;
      border-top: 1px solid #e0dcdc !important;
      border-radius: 0px !important;
      border-left: none !important;
      border-right: none !important;
      border-bottom: none !important;
    }
    .end-line{
      border-top: 1px solid #e0dcdc !important;
      margin-top: -16px;
      margin-bottom: 18px;
    }
    .rew-profile-rating {
      flex-direction: column;
      gap: 5px;
      .profile {
        .date {
          display: none;
        }
      }
      .rating {
        margin-left: 35px;
        gap: 10px;
        .date {
          display: block !important;
          margin: 0px;
          color: #707070;
        }
      }
    }
  }

  .review-holder {
    margin-bottom: 16px;
    border-radius: 22.7561px;
    padding: 23px;
    background: #fcfcfc;
    border: 1.89634px solid #f8f8f8;

    .review-commant {
      margin-top: 18px;
    }
    .rew-profile-rating {
      .profile {
        gap: 10px;
        align-items: center;
        img {
          margin: 0px;
          height: 40px !important;
          width: 40px !important;
          border-radius: 50%;
        }
        .nameChar {
          p {
            margin-bottom: 0px;
          }
          height: 42px;
          width: 42px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #f8f8f8;
          border-radius: 100%;
          border: 2px solid #600abd;
        }
        .name {
          margin: 0px;
          font-size: 18px;
          font-weight: 700;
        }
        .date {
          margin: 0px;
          color: #707070;
          size: 11.28px;
        }
      }
      .rating {
        .date {
          display: none;
        }
      }
    }
  }
}

//dates&prices
.Dates-Wrapper-heading {
  padding-left: 45px;
  padding-bottom: 24px;
  p {
    margin-bottom: 0px !important;
  }
  gap: 125px;
  @media (max-width: 767px) {
    margin-top: 10px;
    gap: 135px;
  }
}
.Dates-Wrapper {
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 24px;
    padding: 20px 20px;
    align-items: center;
    .Dates-Duration {
      justify-content: space-between;
    }
  }
  .Date-range {
    padding-top: 20px;
    font-size: 18px;
  }
  margin-bottom: 12px;
  border: 1px solid #e2e5ea;
  border-radius: 14px;
  padding: 20px 40px;
  justify-content: space-between;
  p {
    margin: 0px;
  }
  .Dates-Duration {
    gap: 30px;
    .day {
      color: #959595;
      font-size: 14px;
    }
    .date {
      font-weight: 700;
    }
  }
  .Dates-Wrapper-btn {
    background: linear-gradient(98.07deg, #115297 5.22%, #600abd 101.6%);
    border-radius: 14px;
    padding: 15px 40px;
    color: #ffffff;
    font-weight: 700;
    font-size: 18px;
  }
}
//Itinerary reviws
.experience-details {
  .reviews {
    @media (max-width: 767px) {
      margin-top: 20px;
      height: max-content;
      overflow: visible;
      .review-Wrapper {
        padding: 20px 5px !important;
      }
      .rew-profile-rating {
        flex-direction: column;
        gap: 5px;
        .profile {
          .date {
            display: none;
          }
        }
        .rating {
          margin-left: 35px;
          gap: 10px;
          .date {
            display: block !important;
            margin: 0px;
            color: #707070;
          }
        }
      }
    }
    overflow-y: scroll;
    height: 100vh;

    scrollbar-width: none;
    -ms-overflow-style: none;
    .review-Wrapper {
      border-bottom: 1px solid #f8f4f4;
      padding: 40px 10px 32px 10px;
      .review-holder {
        margin-bottom: 16px;

        .rew-profile-rating {
          flex-direction: column;
          gap: 5px;
          .rating {
            align-items: center;
            margin-left: 35px;
            gap: 10px;
            .date {
              display: block !important;
              margin: 0px;
              color: #707070;
            }
          }
        }

        .profile {
          gap: 10px;
          img {
            margin: 0px;
            height: 30px;
            width: 30px;
            border-radius: 50%;
          }
          .name {
            margin: 0px;
            font-size: 18px;
            font-weight: 700;
          }
          .date {
            margin: 0px;
            color: #707070;
            size: 11.28px;
          }
        }
      }
    }
  }
  .FAQs {
    .FAQ-Wrapper {
      @media (max-width: 767px) {
        padding: 11px 3px;
      }

      border-bottom: 1px solid #ede0e0;
      padding: 33px 5px;
      p {
        margin: 0px;
      }
      .qs {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 5px !important;
      }
    }
  }
  .Itinerary {
    .title {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 20px;
      @media (max-width: 767px) {
        margin-top: 15px;
      }
    }
    .Itinerary-details {
      margin-bottom: 10px;
      p {
        margin: 0px;
      }
      .day {
        font-size: 18px;
        font-weight: 700;
      }
    }
  }
}
.Amenities {
  @media (max-width: 767px) {
    margin-top: 20px;
    .title {
      margin-bottom: 10px;
    }
  }
  .title {
    font-weight: 700;
  }
  .Amenities-list {
    gap: 24px;
    margin-bottom: 24px;
    margin-right: 80px;
    .sub-list {
      justify-content: space-between;
      @media (max-width: 767px) {
        flex-direction: column;
        gap: 24px;
      }
    }
  }
}
//customer reviews
.customer-reviews {
  ul {
    li {
      .MuiButtonBase-root.Mui-disabled {
        background: #919eab;

        color: #c4cdd5;
      }

      button {
        svg {
          color: #c4cdd5;
        }
        border: 1px solid #dfe3e8;
        border-radius: 4px;
        background: #ffffff;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
  .reviews-header {
    @media (max-width: 767px) {
      h1 {
        font-size: 18px !important;
      }
      .header-btn {
        font-size: 13px !important;
        padding: 13px 27px !important;
      }
      .disabled {
        font-size: 13px !important;
        padding: 13px 27px !important;
      }
    }

    h1 {
      font-weight: 700;
      font-size: 24px;
    }
    .header-btn {
      padding: 14px 25px;
      color: #ffffff;

      font-weight: 700;
      font-size: 18px;

      background: linear-gradient(98.07deg, #115297 5.22%, #600abd 101.6%);
      border-radius: 22.7561px;
    }
    .disabled {
      background: #959595 !important;
      padding: 14px 25px;
      color: #ffffff;
      border: none;
      font-weight: 700;
      font-size: 18px;

      border-radius: 22.7561px;
    }
  }
}

.Review-popup-holder {
  @media (max-width: 991px) {
    padding: 20px;
    width: 90%;
    p {
      margin-bottom: 20px !important;
    }
    .review-buttons {
      align-self: center !important;
    }
  }
  top: 50%;
  background-color: #fff;
  position: relative;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 40%;
  padding: 45px;
  align-items: center;
  border: 1px solid #c9c9c9;
  border-radius: 32px;
  p {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 40px !important;
    align-self: flex-start;
  }
  .review-rating {
    align-self: flex-start;
    margin-bottom: 25px;
  }
  textarea {
    width: 100%;
    height: 300px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    resize: none;
    padding: 20px;
    border-radius: 32px;
    outline: none;
  }
  .review-buttons {
    align-self: flex-end;
    gap: 24px;
    margin-top: 25px;
    button {
      background: #ffffff;
      padding: 12px 28px;

      border: 1px solid #e0e0e0;
      border-radius: 12px;
    }
  }
}
.stay-tabs-discription {
  @media (min-width: 992px) {
    padding-left: 0px;
    padding-right: 3rem;
  }
  padding: 0px;
}
.packageTabs-description {
  .booking-holder {
    background: #fff;
    .form-group {
      input {
        font-size: 18px;
      }
    }
  }
  @media (min-width: 991px) {
    margin: auto;
  }
  .package-nav {
    margin-bottom: 40px;
  }
  .stay-package-tabs-description {
    padding-bottom: 20px !important;
    min-height: 300px;
    margin-top: 20px;
    padding: 0px 25px;
    @media (max-width: 991px) {
      background: #fff;
      border-radius: 0px;
      padding: 0px !important;
    }
    .stay-sticky-description {
      scroll-margin-top: 177px;
      @media (max-width: 767px) {
        scroll-margin-top: 76px;
        background: #fff;
        border-radius: 0px;
        padding: 0px !important;
      }
    }
    .stay-package-description {
      scroll-margin-top: 180px;
      @media (max-width: 767px) {
        scroll-margin-top: 92px;
        background: #fff;
        border-radius: 0px;
        padding: 0px !important;
      }
    }
    background: #fbfbfb;
    border-radius: 32px;
  }
}

.itinerary-package-Tabs-description {
  .booking-holder {
    background: #fff;
    .form-group {
      input {
        font-size: 19px;
      }
    }
  }
  .contact-holder {
    background: #fff;
  }
  @media (max-width: 991px) {
    background: #fff;
    border-radius: 0px;
    padding: 0px !important;
  }
  padding: 0px 20px;
  padding-bottom: 20px;
  margin-top: 20px;
  background: #fbfbfb;
  border-radius: 32px;
  .package-nav {
    margin-bottom: 40px;
  }

  position: relative;
  scroll-margin-top: 82px;
  @media (max-width: 767px) {
    scroll-margin-top: 0.1px;
    margin-top: 20px;
  }

  .tour-planner-model {
    top: 50%;
    left: 50%;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 999;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    padding: 0px 20%;

    transform: translate(-50%, -50%);

    @media (max-width: 767px) {
      top: 50%;
      left: 50%;
      padding: 0px 5%;
      .contact-tour-planner {
        min-width: 0%;
        width: 120%;
      }
      .calender-modal {
        top: -140% !important;
      }
    }
  }
  .contact-tour-planner {
    min-width: 400px;
    background: #fff;
    span {
      .close-icon {
        cursor: pointer;
        margin-bottom: 20px;
      }
      justify-content: flex-end;
    }
  }
}
.stay-desc {
  p {
    margin-bottom: 0;
    // padding: 15px 0
  }
  img {
    border-radius: 17px;
    max-width: 100% !important;
    //  max-height: 100%;
    //  object-fit: cover;
    @media (max-width: 767px) {
      width: 100% !important;
    }
    height: auto !important;
  }
  span {
    max-width: 100% !important;
    height: auto !important;
  }
}
.itinerary-package-sticky-Tabs-description {
  margin-top: 40px;
  position: relative;
  scroll-margin-top: 167px;
}
.itinerary-package-description {
  min-height: 200px;
}
.review-stay-model {
  position: absolute;
  top: -40%;
  left: 60%;
  opacity: 1;

  height: 150vh;
  width: 130%;
}
.review-itinerary-model {
  position: absolute;
  top: -200%;
  left: 25%;
  opacity: 1;

  height: 150vh;
  width: 130%;
}
