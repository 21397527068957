.guest-modal{
    position: absolute;
    right: 0;
    top: 80px;
    width: 600px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 42px 40px;
    text-align: left;
    @media (max-width:767px){
        right: 5%;
        width: 120%;
        overflow: auto;
    }
    .corp-row{
        h6{
         font-weight: 900 !important;
         font-size: 1rem !important;
         margin-bottom: .5rem !important;
         text-align: left !important;
        }
        p {
            @media (max-width:767px){
                font-size: 14px;
            }
        }
    }

    .corp-content{
        border-right: 1px solid #6666;
        @media (max-width:767px){
            border-right: transparent;
        }
    }
    .corp-content-single-row{
        padding-bottom: 15px;
        @media (max-width:767px){
            flex-wrap: nowrap;
            .corp-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: 25px !important;
                    height: 25px !important;
                }
            }
        }
        p {
            font-size: 16px !important;
        }
    }

    .corp-icon{
        img{
          cursor: pointer;
          width: 33%;
          @media (max-width: 767px){
            width: 22%;
          }
        }
        span{
            padding: 15px 10px;
            font-size: 23px;
            font-weight: 500;
        }
    }
    .additional-note{
        margin-top: 20px;
        color: 666666;
        font-size: 13px;
        color: #666666;
        text-align: center
    }
}

// .prepareGift-holder .guest-modal {
//     position: relative;
//     left: 0;
//     right: 35%;
//     top: 0%;
//     width: 150%;
// }

.prepareGift-holder .calender-modal {
    left: 0;
    @media (max-width: "768px") {
        width: 100%;
    }
}