.search-location-price-range {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 6px;
    margin-bottom: 48px;

    @media (max-width:991px) {
        margin: -20px 0px 20px;
        flex-wrap: wrap;
        display: block;
    }
    

    .search-location {
        
        .buttonIn {
            // width: 300px;
            position: relative;

            .clear-button {
                position: absolute;
                right: 15px;
                top: 15px;
                font-size: 20px;
                font-weight: 500;
                height: 30px;
                width: 30px;
                padding: 13px 10px;
                line-height: 0;
                border-radius: 50px;
                cursor: pointer;
                color: #4f1fb5;
                background-color: #fff;
                transform: translateX(2px);
                @media(max-width: 768px){
                    top: 8px;
                }
                
            }
        }
        .input-wrapper {
            input{
                background-color: #f9f9f9;
            }
        }
        input {
            background: #FFFFFF;
            border: 1px solid #E0E0E0;
            box-sizing: border-box;
            border-radius: 24px;
            padding: 18px 33px;
            height: 60px;
            font-size: 18px;
            line-height: 23px;
            font-weight: 400;
            color: #000000;
            // text-transform: capitalize;
            &:focus {
                border: 1px solid #E0E0E0;
            }

            @media (max-width:767px) {
                height: 46px;
                font-size: 14px;
                padding: 14px 20px;
                margin-right: -5px;
                width: 100%;
            }

            @media (min-width:768px) {
                width: 504px;
            }
        }

        .btn--search {
            background: #FBFBFB;
            border: 1px solid #EBEBEB;
            box-sizing: border-box;
            border-radius: 24px;
            color: #000000;
            font-weight: 500;
            font-size: 18px;
            padding: 15.5px 23px;
            width: max-content;
            @media(max-width:767px) {
                height: 46px;
                padding: 10px 20px;
                font-size: 14px;
            }
        }
    }

    .price-range {
        @media (max-width:991px){
            display: flex;
            justify-content: center;
        }
        .dropdown--btn {
            font-weight: 500;
            @media (max-width:767px) {
                height: 46px;
                font-size: 14px;
                
            }
            @media (max-width:991px){
                width: max-content;
            }
        }

        .ui.dropdown:not(.button)>.default.text {
            color: #000000 !important;
        }

        .ui.upward.selection.dropdown.visible,
        .ui.active.selection.dropdown {
            border-radius: 24px !important;
        }

        .ui.selection.active.dropdown .menu {
            background: #FFFFFF;
            border-color: transparent;
            border-radius: 17px;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
            margin: 7px 0;
            width: 265px;
            padding: 25px;
            max-height: 19.028571rem;
            display: block;
            right: 0;
            left: unset;

            @media (max-width:767px) {
                width: 230px;
                padding: 25px;
            }
        }

        .ui.selection.dropdown>.dropdown.icon {
            @media (max-width:767px) {
                top: 14px !important;
            }
            top: 17px !important;
            display: block !important;
        }

        // .ui.dropdown .menu {
        //     right: 0 !important;
        //     left: unset !important;
        // }
        .ui.selection.dropdown .menu>.item {
            background: #FBFBFB;
            border: 1px solid #EBEBEB;
            box-sizing: border-box;
            border-radius: 24px;
            margin-bottom: 10px;
            width: max-content;
            font-weight: 500;
            font-size: 18px;
            line-height: 23px;
            color: #000000;
            float: left;

            width: -webkit-fill-available;

            @media (max-width:767px) {
                height: 46px;
                font-size: 14px;
            }
        }

        .ui.dropdown .menu .active.item {
            background: #E9E9E9 !important;
            border: 2px solid #000000 !important;
        }
    }
}

.react-autosuggest__container {
    position: relative;

    .react-autosuggest__suggestions-container--open {
        display: block;
        position: absolute;
        width: 100%;
        background-color: #FFFFFF;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        border-radius: 32px;
        margin-top: 12px;
        padding: 30px 20px;
        max-height: 300px;
        overflow-y: auto;
        z-index: 9;
    }

    .react-autosuggest__input--focused {
        outline: none;
    }

    .react-autosuggest__suggestion {
        cursor: pointer;
        padding: 15px 20px;
        background: #FBFBFB !important;
        border: 1px solid #EBEBEB !important;
        box-sizing: border-box;
        border-radius: 24px !important;
        font-size: 18px;
        margin-bottom: 12px;
        font-weight: 500;
        display: flex;
        align-items: center;
        text-transform: capitalize;
        @media(max-width:767px) {
            font-size: 14px;
            padding: 12px 18px;
        }

        &::before {
            content: '';
            background: url('../../../images/corporates/location-icon.svg');
            background-repeat: no-repeat;
            width: 20px;
            height: 22px;
            display: block;
            margin: 10px;
        }
    }

    .react-autosuggest__suggestions-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .react-autosuggest__suggestion--highlighted {
        background-color: #ddd;
    }
}

.inline-search-mobi {
    @media(max-width:767px){
       flex-wrap: nowrap;
    }
    @media(max-width:991px){
        margin-bottom: 12px;
    }
}