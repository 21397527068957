@mixin brand-gradient {
    background: linear-gradient(98.07deg, #115297 5.22%, #600ABD 101.6%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
}

@mixin light-gradient {
    background: linear-gradient(98.77deg, #6DFFCE -7.14%, #68C9FF 111.3%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
}

@mixin main-heading {
    font-size: $fontSize-hero - 2;
    font-weight: $fontWeight-bold;
}

@mixin container-paddingLeft {
    padding-left: calc((100% - 1105px)/ 2);
}

@mixin recognitions-partners {
    font-size: $fontSize-largest + 8;
    line-height: 47px;
    color: $color-black;
    font-weight: $fontWeight-medium;
    text-align: center;
    margin-bottom: 36px;
}

@mixin hide-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

    /* Firefox */
    & ::-webkit-scrollbar {
        display: none;
    }
}

@mixin logo-cards {
    .card-wrapper {
        max-width: 254px;
        border: 1px solid #EDEDED;
        border-radius: 32px;

        .img-holder {
            position: relative;
            height: 155px;
            min-width: 174px;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: max-content;
            }
        }

        .info {
            text-align: center;
            background-color: #F8F8F8;
            padding: 0px 20px;
            display: flex;
            align-items: center;
            border-radius: 0px 0px 32px 32px;
            height: 90px;
            display: none;
            p {
                font-size: $fontSize-smaller;
                line-height: 21px;
                color: $color-black;
                font-weight: $fontWeight-medium;
                width: 95%;
                margin: 0 auto;
            }
        }
    }
}

@mixin hammock-custom-border {
    background: $color-white;
    border: 1px solid #C9C9C9;
    border-radius: 32px;
}

@mixin hammock-border-bg {
    background: #FCFCFC;
    border: 2px solid #F8F8F8;
    border-radius: 24px;
}

@mixin profile-card-bg {
    @include hammock-border-bg;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
}

@mixin  corporate-title {
    font-weight: 500;
    font-size: 42px;
    color: #000000;
    text-align: center;
    margin-bottom: 24px;
}