@import "../variables";
@import "../mixins";

.header-holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    padding: 22px 5%;
    right: 0;
    margin: 0 auto;
    // max-width: 1440px;
    .cta-buttons {
        a {
            color: $color-white;
            font-weight: $fontWeight-medium;
            // text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.footer-holder {
    //text-align: center;
    margin-top: 80px;
    background: linear-gradient(98.07deg, #115297 5.22%, #600abd 101.6%);
    padding: 40px 0px;

    a:hover {
        text-decoration: none;
    }
    .footer-sm {
        align-items: center;
        gap: 45px;
    }
    .careers-footer{
        h4{
        font-size: 17px !important;
        margin-bottom: 0px !important;
        }
        flex-direction: column;
        gap: 15px;
        margin-bottom: 20px;
        cursor: pointer;
    }
    p {
        // margin: 0;
        // font-weight: $fontWeight-normal;
        // font-size: 16px;
        // color: $color-black;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #dddddd;
        padding-top: 26px;
        padding-right: inherit;
        @media (max-width: 767px) {
            margin: 0 0;
            padding-right: 0px;
        }
    }
    .footer-links {
        cursor: pointer;
        text-decoration: none;
        color: #ffffff !important;
        margin-right: 10px;
    }
    .footer-sec-head {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        color: #ffffff;
        margin-bottom: 30px;
        @media (max-width: 767px) {
            margin-bottom: 15px;
            font-size: 28px;
        }
    }
    .footer-sec-p {
        font-family: "DM Sans" !important;
        cursor: pointer;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #dddddd;
        padding-top: 10px !important;
        @media (max-width: 767px) {
            font-size: 19px;

            line-height: revert;
        }
    }
    hr {
        opacity: 0.2;
        border: 1px solid #ffffff;
    }
    p.footer-last {
        justify-content: center;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #ffffff;
        @media (max-width: 767px) {
            display: block;
        }
    }
    .footer-logo {
        // background: #FFFFFF;
        padding-top: 30px;
        width: 30px;
    }
    .footer-logo-facebook {
        // background: #FFFFFF;
        padding-top: 30px;
        width: 15px;
    }
    .footer-logo-contact {
        width: 14px;
        height: 15px;
    }
    .footer-logo-mob {
        height: 17px;
        margin-top: -2px;
    }
}
.profile-ham-menu {
    .profile-list {
        position: absolute;
        transform: translate3d(-160px, 72px, 0px) !important;
        //left: -65% !important;
        background: $color-white;
        border-color: transparent;
        border-radius: 17px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        padding: 30px 36px;
        max-height: 17.028571rem;
        align-items: flex-end;
        .dropdown-item {
            font-weight: $fontWeight-medium;
            font-size: 18px;
            line-height: 23px;
            color: $color-black;
            padding: 0;
            margin-bottom: 20px;
            cursor: pointer;
        }
        @media only screen and (max-width: 767px) {
            transform: translate3d(-160px, 72px, 0px) !important;
        }
    }

    .gradient-border {
        background: linear-gradient(98.07deg, #115297 5.22%, #600abd 101.6%);
        border-radius: 100%;
        padding: 4px;

        img {
            width: 42px;
            height: 42px;
            border-radius: 100%;
            @media only screen and (max-width: 767px) {
                width: 28px;
                height: 28px;
            }
        }

        .name-char {
            height: 60px;
            width: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f8f8f8;
            border-radius: 100%;

            p {
                margin: 0;
                font-size: 30px;
                font-weight: $fontWeight-bold;
                @include brand-gradient;
            }
            @media only screen and (max-width: 767px) {
                width: 60px;
                height: 60px;
            }
        }
    }
    @media only screen and (max-width: 1024px) {
        position: absolute;
        left: 2%;
    }
}

.profile-holder {
    margin-right: -150px;
    .profile-dropdown {
        background: transparent;
        border: none;
        display: flex;
        align-items: center;

        &::after {
            display: none;
        }
    }

    .profile-list {
        position: absolute;
        transform: translate3d(-160px, 72px, 0px) !important;
        //left: -65% !important;
        background: $color-white;
        border-color: transparent;
        border-radius: 17px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        padding: 30px 36px;
        max-height: 17.028571rem;
        align-items: flex-end;
        .dropdown-item {
            font-weight: $fontWeight-medium;
            font-size: 18px;
            line-height: 23px;
            color: $color-black;
            padding: 0;
            margin-bottom: 20px;
            cursor: pointer;
        }
        @media only screen and (max-width: 767px) {
            transform: translate3d(-160px, 72px, 0px) !important;
        }
    }

    .gradient-border {
        background: linear-gradient(98.07deg, #115297 5.22%, #600abd 101.6%);
        border-radius: 100%;
        padding: 4px;

        img {
            width: 42px;
            height: 42px;
            border-radius: 100%;
            @media only screen and (max-width: 767px) {
                width: 28px;
                height: 28px;
            }
        }

        .name-char {
            height: 42px;
            width: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f8f8f8;
            border-radius: 100%;

            p {
                margin: 0;
                font-size: 18px;
                font-weight: $fontWeight-bold;
                @include brand-gradient;
            }
            @media only screen and (max-width: 767px) {
                width: 28px;
                height: 28px;
            }
        }
    }

    .dropdown-item.active,
    .dropdown-item:active,
    .dropdown-item:hover {
        background-color: transparent !important;
    }
    @media only screen and (max-width: 1024px) {
        position: absolute;
        right: 2%;
    }
}
.my-profile-dropdown {
    color: black;
    padding: 25px 20px;
    margin-top: 5px;
    font-weight: 700;
    font-size: 16px;
    border-radius: 26px;
    width: 150px;
    right: 1%;
    position: absolute;
    z-index: 9999;
    background-color: #fff;
    filter: drop-shadow(0px 1px 12px rgba(0, 0, 0, 0.17));
    &:after {
        left: 66%;
        border: solid transparent;
        content: " ";

        position: absolute;
        pointer-events: none;
        border-bottom-color: #fff;
        border-width: 14px;
        margin-top: -28px;
        top: 0px;
    }
    p {
        margin-bottom: 0px;
    }
    .my-profile {
        padding-bottom: 10px;
        border-bottom: 2px solid rgba(0, 0, 0, 0.05);
        margin-bottom: 10px;
    }
}

.pre-login-header {
    .outline--btn {
        color: #000 !important;
        border: 2px solid #000;
    }
    .contact-btn {
        color: #000 !important;
    }
    .signup--btn {
        border-radius: 16px;
        font-weight: 500;
        font-size: 16px;
        background: #fff;
        border: 2px solid #000;
        color: #000 !important;
        padding: 8px 16px;
    }
}
.cta-buttons a.icon,
.mobileNav {
    display: none;
}
@media only screen and (max-width: 767px) {
    .cta-buttons {
        display: none;
    }

    .header-holder {
        display: flex;
        justify-content: center;

        .logo {
            margin-top: 10px;
        }
        .virtual-mobile-logo{
            top: 15px;
                left: 20px;
                position: absolute;
                margin-top: 0px;
        }
    }
}
/* Mobile view navigation*/
.overlays,
.icon {
    display: none;
}
@media only screen and (max-width: 767px) {
    .overlays,
    .icon {
        display: block;
    }
}

.overlays {
    height: 55%;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgb(250, 246, 246);
    background-color: rgba(250, 250, 250, 1);
    overflow-y: hidden;
    transition: 0.5s;
}
.shadowClass {
    box-shadow: 0px 48px 30px 400px rgba(0, 0, 0, 0.4) !important;
    overflow-y: hidden;
}

.overlays-content {
    position: relative;
    // top: 6%;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    padding: 15px 0;
    // text-align: center;
    // margin-top: 30px;
}

.overlays a {
    padding: 8px;
    text-decoration: none;
    font-size: 17px;
    //color: #818181;
    display: block;
    transition: 0.3s;
}

.overlays a:hover,
.overlays a:focus {
    color: #f1f1f1;
}

.overlays .closebtn {
    position: absolute;
    top: 0px;
    right: 25px;
    font-size: 55px;
}
.profile--btn {
    background-color: white;
    color: black;
    border: 2px solid black;
    border-radius: 20px;
    margin: 10px;
    //font-size: 20px;
    width: 200px;
    @media (max-width: 1199px) {
        padding: 0px;
    }
}
.mobiBtns {
    border-radius: 16px;
    width: 150px;
    height: 48px;
}
.yo2 {
    background-color: black;
    color: white;
    padding: 0px;
    border-radius: 16px;
}
.yo1 {
    padding: 0px;
    border-radius: 16px;
    border: 2px solid #000;
    // margin-bottom: 25px;
}
.yo1:hover {
    background-color: #555555;
    color: white;
}
.yo3 {
    color: black;
    margin: 30px;
    font-size: 14px;
    font-weight: 500;
}
.overlays p {
    text-align: center;
    margin-top: 40px;
    font-size: 17px;
    background: linear-gradient(98.07deg, #115297 5.22%, #600abd 101.6%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.header-holder .ham {
    position: absolute;
    right: 25px;
}
.mobi-boxshadow {
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.04);
    background: #fff;
    height: 100%;
}
.orig-mob-profile-holder {
    display: none;
}
@media only screen and (max-width: 767px) {
    .mob-profile-holder {
        display: none;
    }
    .orig-mob-profile-holder {
        display: block;
        left: 2%;
    }
}
.afterLogin {
    .overlays-content a {
        margin-bottom: 20px;
    }
}

.carousel-mobi-wrapper {
    @media (max-width: 767px) {
        .owl-stage {
            .owl-item {
                &:nth-child(1) {
                    margin-left: 15px !important;
                }
            }
        }
    }

    @media (min-width: 768px) and (max-width: 1199px) {
        .owl-stage {
            .owl-item {
                &:nth-child(1) {
                    margin-left: 35px !important;
                }
            }
        }
    }
}

.mobi-profile-goBack {
    @media (max-width: 767px) {
        position: absolute;
        top: 15px;
        right: 2px;
    }
}
.mob-popup {
    display: none;
    @media screen and (max-width: 767px) {
        display: block;
    }
}

.auth-mob .nav {
    margin: 0px 0px 0px !important;
    border-radius: 24px 24px 0px 0px !important;
}

.headerModel {
    // display: none;
    position: fixed;
    z-index: 9;
    padding-top: 50px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.dropdown-menu {
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: none;
    font-size: 14px;
}
.nav-link {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #c9c9c9;
}
.nav-link-section {
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.nav-link-end {
    padding-left: 5px !important;
    padding-right: 0px !important;
    //width:300px;
}

.nav-item a:hover {
    color: rgb(0, 0, 0) !important;
}
.nav-link a {
    text-decoration: none;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.5);
}
.nav-link a:hover {
    text-decoration: none;
    color: rgb(0, 0, 0);
}
.dropdown-item {
    padding: 0;
}
.dropdown-item:active {
    background: #e9ecef;
}
.dropdown-item a:hover {
    text-decoration: none;
    color: #000000;
}
.dropdown-item a {
    text-decoration: none;
    color: rgba(1, 1, 1, 0.5);
    padding: 0.5rem 1.5rem;
    display: block;
}
.holiday-header-sections{
    width: 80%;
    justify-content: center;
    padding-left: 10%;
}

.nav-proile-icon {
    height: 42px;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f8f8f8;
    border-radius: 100%;
    border: 2px solid #600abd;
    .nameChar {
        p {
            margin-bottom: 0;
            color: #222;
        }
    }
    .profileImage {
        img {
            height: 40px;
            width: 40px;
            border-radius: 100%;
        }
    }
    .userIcon {
        img {
            height: 23px;
            width: 23px;
            border-radius: 100%;
        }
    }
}
.search_btn_holiday {
    position: static !important;
    height: 42px !important;
    width: 42px !important;
}
.nav-link-search {
    width: 300px;
}
.nav-link-search > div {
    float: right;
    height: 40px;
}
a.nav-link > div > .globalSearch {
    position: relative;
    width: 300px;
    left: 0px;
    //top: -5px;
    height: 42px;
}
a.nav-link > div > .globalSearch > * {
    position: relative;
    bottom: 20px;
    width: 300px;
    float: right;
}
a.nav-link > div > .globalSearch > * > img {
    position: relative;
    bottom: 25px;
}
.nav-profile-search{
    .search-btn-holiday{
        top:18px !important;
        right: 80px !important;
        height: 42px ;
        width: 42px ;
    }
    .globalSearch{
        position: relative;
        height: 42px;
        width: 300px;
        left: 0px;
        margin-top: -17px;
    }
}
// .holiday-header{
//     width: calc(100%) !important;
// }
@media screen and (max-width: 1200px) {
    .nav-profile-search{
        .globalSearch{
           width: 200px;
           font-size: 14px;
           z-index: 999;
           margin-top: -12px;
        }
        .globalSearch > * > img {
            position: relative;
            bottom: 5px;
        }
    }
    .nav-link-search {
        width: 140px;
    }
    a.nav-link > div > .globalSearch > * {
        width: 140px;
    }
    .nav-link {
        font-size: 14px;
    }
    .nav-link a {
        font-size: 14px;
    }
}
