.career-logo {
    @media (max-width: 767px) {
        width: 65%;
        flex-direction: row-reverse;
        .close-icon {
            margin-top: -5px;
        }
    }
    img{
        cursor: pointer;
    }
    position: absolute;
    z-index: 99;
    top: 0px;
    padding: 22px 5%;
    width: 100%;
    align-items: center;
    .close-icon {
        cursor: pointer;
    }
}
.about-us-holder {
    .partners-holder{
        margin-top: 50px !important;
    }
    @media (max-width: 991px) {
        .about-us-numbers {
            margin-top: 0px !important;
            height: 593px !important;
            justify-content: space-around !important;
            flex-direction: column;
            padding: 0px 30px !important;
            p {
                font-size: 90px !important;
            }
            .text {
                font-size: 21px !important;
            }
        }
        
        .about-us-our-technical-team-wrapper {
            margin-top: 33px !important;
            h3 {
                margin-bottom: 29px !important;
                font-size: 22px !important;
            }
            .about-us-our-technical-team {
                flex-direction: column-reverse;
                gap: 38px !important;
                img {
                    width: 100% !important;

                    object-fit: contain;
                    height: 91px !important;
                }
                p {
                    font-weight: 400 !important;
                    font-size: 16px !important;
                    line-height: 21px !important;
                
                }
            }
        }
        .about-us-our-team-wrapper {
            margin-top: 0px !important;
            .about-us-our-team {
                margin-top: 48px !important;
                .our-team-heading {
                    margin-bottom: 36px !important;
                    font-size: 24px !important;
                }
                padding: 0 8%;
            }
            .our-team-details {
                flex-direction: column-reverse !important;
                gap: 24px !important;
                margin-bottom: 48px !important;
                align-items: center;
                .team-member-description {
                    margin-top: 0px !important;
                    font-weight: 400 !important;
                    font-size: 16px !important;
                    line-height: 21px !important;
                }
            }
            .our-team-img-wrapper {
                margin: 0 3%;
                max-width: 350px;
                img {
                    width: 100% !important;
                    height: 185px !important;
                }
                .member-name {
                    font-size: 16px !important;
                    margin-top: 12px !important;
                    margin-bottom: 0px !important;
                    margin: 0px 5px;
                }
                p {
                    font-size: 12px !important;
                    margin-bottom: 12px !important;
                }
            }
        }
    }
    @media (max-width: 767px) {
        .about-main-banner {
            img {
                height: 723px !important;
                max-height: 723px !important;
                object-fit: cover !important;
            }
        }
        .about-banner-text {
            height: 723px !important ;
            padding-left: 7% !important;
            .about {
                font-size: 20px !important;
            }
            .about-title {
                font-size: 45px !important;
            }
        }
        .about-us-description-wrapper {
            .description-title {
                font-size: 18px !important;
            }
            .description-content {
                font-size: 14px !important;

                line-height: 20px !important;
            }
        }
        .about-us-banner {
            img {
                max-height: 170px !important;
                object-fit: cover;
            }
        }
        .partners-holder {
            margin-top: 64px !important;
        }

        .about-us-destinations {
            margin-top: 33px !important;
        }

        .about-us-banner::after {
            height: 170px !important;
        }
        .about-us-banner-txt {
            font-size: 24px !important;
            height: 170px !important;
            line-height: 108.7%;;
        }

        .destinations-heading {
            font-size: 22px !important;
            margin-bottom: 30px !important;
        }
        .about-us-slider {
            margin-left: 7px;
            img {
                width: 180.12px !important;
                height: 222px !important;
                margin-left: 10px !important;
            }
        }
        .about-us-partners {
            font-size: 22px !important;
            margin-bottom: 30px !important;
            margin-top: 0px !important;
        }
    }
    .about-us-our-team-wrapper {
        .about-us-our-team-wrapper-bg {
            img {
                height: 1650px !important;
                width: 100%;
                object-fit: cover;
                @media (max-width: 991px) {
                    height: 1400px !important;
                    
                }
                @media (max-width: 767px) {
                    height: 1600px !important;
                   
                }
                @media (max-width: 350px) {
                    height: 1800px !important;
                }
            }
        }
        .about-us-our-team-wrapper-bg::after {
            @media (max-width: 767px) {
                height: 1600px !important;
            }
            @media (max-width: 350px) {
                height: 1800px !important;
            }
            @media (max-width: 991px) {
                height: 1400px;
            }
            height: 1650px;

            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            display: inline-block;
            background: linear-gradient(94.7deg, rgba(1, 25, 50, 0.71) 9.13%, rgba(52, 1, 106, 0.27) 96.14%);
        }
        position: relative;
        width: 100%;

        .about-us-our-team {
            margin-top: 76px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 999;
            .our-team-heading {
                text-align: center;
                font-weight: 700;
                margin-bottom: 70px;
                color: #ffffff;
                font-size: 44px;
            }
            .our-team-details {
                gap: 60px;

                margin-bottom: 80px;
                .team-member-description {
                    margin-top: 60px;
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 36px;
                    text-align: justify;
                    color: #ffffff;
                }
                .our-team-img-wrapper {
                    background-color: #f8f8f8;
                    border-radius: 32px;
                    .member-name {
                        font-size: 20px;
                        font-weight: 700;
                    }
                    p {
                        text-align: center;
                        font-weight: 500;
                        font-size: 15px;
                        margin-bottom: 0px;
                    }
                }
                img {
                    border-top-left-radius: 32px;
                    border-top-right-radius: 32px;
                    width: 350px;
                    height: 300px;
                }
            }
        }
    }
    .about-us-our-technical-team-wrapper {
        margin-top: 75px;
        h3 {
            font-weight: 700;
            font-size: 44px;
            margin-bottom: 36px;
            text-align: center;
        }
        .about-us-our-technical-team {
            align-items: center;
            gap: 30px;
            p {
                font-weight: 400;
                font-size: 20px;
                line-height: 36px;
                text-align: center;
                margin-bottom: 0px;
            }
            img {
                width: 402px;
                height: 124px;
                object-fit: scale-down;
               
            }
        }
    }
    .about-us-numbers {
        background-image: url(../../../images/home/about/about-us-BG.webp);
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 405px;
        align-items: center;
        justify-content: space-between;
        padding: 0px 100px;
        p {
            margin-bottom: 0px;

            line-height: 108.7%;
            font-weight: 700;
            font-size: 85px;
        }
        .text {
            font-weight: 700;
            font-size: 26.8083px !important;
           
        }
    }
    .about-banner-holder {
        .about-main-banner {
            img {
                height: 845px !important;
                width: 100%;
            }
        }
        .about-main-banner::after {
            height: 723px;
            @media (min-width: 767px) {
                height: 845px;
            }
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            display: inline-block;
            background: linear-gradient(94.7deg, rgba(1, 25, 50, 0.71) 9.13%, rgba(52, 1, 106, 0.27) 96.14%);
        }
        .about-banner-text {
            position: absolute;
            top: 0;
            height: 845px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 10%;
            width: 100%;
            .about {
                font-weight: 700;
                font-size: 36px;
            }
            .about-title {
                font-weight: 700;
                font-size: 96px;
                line-height: 108.7%;
            }
            p {
                color: #ffffff;
                margin-bottom: 0px;
            }
        }
    }
    .about-us-partners {
        font-weight: 700;
        font-size: 44px;
        margin-bottom: 60px;
        text-align: center;
        margin-top: 60px;
    }
    .about-us-txt {
        background: linear-gradient(109.94deg, #79008c 2.15%, #105490 100%);
        align-self: center;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-box-decoration-break: clone;
    }
    .about-us-destinations {
        width: 100%;
        margin-top: 80px;
        .destinations-heading {
            text-align: center;
            font-weight: 700;
            margin-bottom: 50px;
            font-size: 44px;
        }
        overflow: hidden;
        .about-us-slider {
            overflow-x: auto;
            padding-left: calc(50% - 552.5px);
            -ms-overflow-style: none;
            img {
                height: 440px;
                width: 357px;
                object-fit: cover;
                margin-left: 26px;
            }
        }
        .about-us-slider::-webkit-scrollbar {
            display: none;
        }
    }

    .about-us-banner-wrapper {
        position: relative;
        .about-us-banner-txt {
            display: grid;
            z-index: 999;
            place-items: center;
            height: 355px;
            width: 100%;
            text-align: center;
            margin-bottom: 0px;
            font-weight: 700;
            font-size: 54px;
            position: absolute;
            color: #ffffff;
        }
    }

    .about-us-description-wrapper {
        margin-top: 55px;
        .description-title {
            font-weight: 700;
            font-size: 36px;
        }
        .description-content {
            font-weight: 400;
            font-size: 20px;
            line-height: 36px;
            text-align: justify;
        }
    }
    .about-us-banner::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 355px;
        display: inline-block;
        background: linear-gradient(94.7deg, rgba(1, 25, 50, 0.71) 9.13%, rgba(52, 1, 106, 0.27) 96.14%);
    }
    .about-us-banner {
        margin-top: 60px;
        img {
            height: 355px !important;
            width: 100%;
        }
    }
}
.careers-banner-holder {
    @media (max-width: 767px) {
        .main-banner::after {
            height: 450px !important;
        }
        .main-banner {
            img {
                height: 450px !important;
            }
        }
        .text-holder {
            top: 16% !important;
            .main-title {
                font-size: 17px !important;
            }
            .sub-title {
                font-size: 34px !important;
                line-height: 40px !important;
            }
            .content {
                margin-top: 20px !important;
                font-size: 15px !important;
                line-height: 16px !important;
                padding: 0px 15px !important;
            }
        }
    }
    .main-banner {
        img {
            width: 100%;
            height: 798px ;
        }
    }

    .main-banner::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 798px;
        display: inline-block;
        background: linear-gradient(94.7deg, rgba(1, 25, 50, 0.71) 9.13%, rgba(52, 1, 106, 0.27) 96.14%);
    }
    .text-holder {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 20%;
        width: 100%;
        color: #ffffff;
        font-weight: 700;
        font-family: "DM Sans";
        font-style: normal;
        p {
            margin-bottom: 0px;
        }
        .main-title {
            font-size: 26px;
        }
        .content {
            margin-top: 70px;
            font-weight: 500;
            font-size: 28px;
            line-height: 36px;
            text-align: center;
        }
        .sub-title {
            font-size: 64px;
            line-height: 83px;
        }
    }
}
.current-openings-wrapper {
    @media (max-width: 767px) {
        .jobs-list {
            padding: 30px 20px !important;
            gap: 2%;
            .jop-title {
                width: 65%;
                font-size: 13px !important;
            }
            .view-btn {
                padding: 0px !important;
                font-size: 11px !important;
                padding: 7px 10px !important;
                width: 35%;
                height: 36px;
            }
        }
    }
    margin-top: 40px;
    flex-direction: column;
    .gradient-text {
        align-self: center;
        font-weight: 700px;
        font-size: 26px;
        margin-bottom: 40px;
    }
    .jobs-wrapper {
        gap: 12px;
        .jobs-list {
            p {
                margin-bottom: 0px;
            }
            padding: 55px 66px;
            background: #eeeeee;
            border: 2px solid #f8f8f8;
            border-radius: 24px;
            .jop-title {
                font-weight: 500;
                font-size: 26px;
            }
            .view-btn {
                background: linear-gradient(98.07deg, #115297 5.22%, #600abd 101.6%);
                border-radius: 32px;
                padding: 14px 36px;
                font-weight: 500;
                color: #ffffff;
                border: none;
                font-size: 19px;
            }
        }
        .jobs-list:hover {
            background-color: #ffffff;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
    }
}
.job-banner {
    @media (max-width: 767px) {
        .banner {
            max-height: 200px !important;
            img {
                height: 200px !important;
            }
        }
        .text-holder {
            margin-top: 60px;
        }
    }
    .text-holder {
        top: 50%;
        .sub-title {
            font-size: 54px;
            line-height: 65px;
        }
    }
    max-height: 348px !important;
    .banner {
        height: 348px !important;
        img {
            object-fit: fill !important;
            height: 348px;
        }
    }
}
.job-details {
    @media (max-width: 767px) {
        .careers-back {
            z-index: 999 !important;
        }
        .gradient-text {
            font-size: 24px !important;
        }
        .job-discription {
            margin: 20px 0px !important;
            padding: 25px 10px 17px 20px !important;
            .about {
                p {
                    padding: 0px !important;
                    font-size: 15px !important;
                }
                h2 {
                    font-size: 20px !important;
                }
                li {
                    font-size: 15px !important;
                }
            }
            .apply-now {
                font-size: 19px !important;
            }
            .apply-model {
                overflow-x: hidden;
                padding: 0px;
                margin: 0px;
                .job-apply-form {
                    margin: 15px 0px;
                    padding: 30px 20px;
                    width: 100vw;
                    .careers-submit {
                        width: 240px;
                        padding: 15px 0px !important;
                        font-size: 16px;
                    }
                    h5 {
                        font-size: 22px;
                    }
                    .carees-model-close {
                        margin-left: 305px;
                        margin-top: -15px;
                    }
                    .form-file {
                        .custom-file {
                            width: 110px !important;
                        }
                        padding: 21px 12px 20px;
                        .carees-model-close {
                            margin: 5px 8px !important;
                            opacity: 0.3;
                        }
                        .file-name-wrapper {
                            font-size: 15px !important;
                        }
                    }
                    .careers-input-group {
                        padding: 17px 14px 16px;
                        input {
                            font-size: 15px;
                        }
                        label {
                            font-size: 12px;
                        }
                    }
                    .custom-file-label {
                        font-size: 15px !important;
                    }
                    .custom-file-label::after {
                        height: 36px !important;
                        padding: 0px 11px !important;
                        font-size: 12px !important;
                    }
                    textarea {
                        font-size: 15px !important;
                    }
                }
            }
        }
    }
    @media (max-width: 320px) {
        .job-apply-form {
            .custom-file-label::after {
                padding: 0px 5px !important;
                font-size: 10px !important;
            }
        }
    }
    .job-title {
        margin-top: 40px;
        align-items: center;
        .gradient-text {
            margin-bottom: 0px;
            font-weight: 500;
            font-size: 28px;
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }
    .apply-model {
        width: 100%;
        margin: 0 auto;
        top: 50%;
        padding: 0px 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: fixed;
        overflow-y: auto;
        background: #22222275;
        z-index: 22;
        height: 100%;
        width: 100%;
        .job-apply-form {
            background-color: #ffffff;
            border: 1px solid #c9c9c9;
            border-radius: 32px;
            width: 110%;
            margin: 5px 20px 100px 0px;
            padding: 32px 32px;
            height: max-content;
            h5 {
                font-weight: 500;
                font-size: 26px;
                align-self: center;
            }
            .carees-model-close {
                cursor: pointer;
                height: 18px;
                width: 18px;
                // position: absolute;
                // margin-top: -25px;
                // margin-left: 470px;
            }
            .title-line {
                opacity: 0.05;
                border-top: 1px solid #000;
                margin: 6px 0 18px;
            }
            #rgba {
                color: rgba(0, 0, 0, 0);
            }
            .careers-submit {
                align-self: center;
                background: linear-gradient(91.61deg, #600abd 3.87%, #115297 100%);
                border-radius: 17.0127px;
                border: none;
                font-weight: 700;
                font-size: 18px;
                color: #ffffff;
                width: 325px;
                padding: 26px 0px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .add-note {
                margin-top: 20px;
                textarea {
                    border: none;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 23px;
                    opacity: 0.5;
                    outline: none;
                    color: #000000;
                    resize: none;
                    height: 160px;
                }
            }
            .careers-input-group {
                margin-bottom: 20px;
                label {
                    margin-bottom: 0px;
                    font-weight: 400;
                    padding-left: 2px;
                    font-size: 14px;
                    line-height: 18px;
                    opacity: 0.5;

                    color: #000000;
                }
                input {
                    border: none;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 23px;
                    opacity: 0.5;
                    outline: none;
                    color: #000000;
                }
                .form-line {
                    opacity: 0.1;
                    border-top: 1px solid #000;
                    margin: 6px 0 8px;
                }
                padding: 21px 24px 20px;
                background: #fcfcfc;
                border: 2px solid #f8f8f8;
                border-radius: 24px;
            }
            .form-file {
                .carees-model-close {
                    height: 13px;
                    width: 13px;
                    margin: 7px 8px;
                    opacity: 0.3;
                }
                .custom-file {
                    width: 28%;
                }
                .file-name-wrapper {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    color: #000;
                    opacity: 0.3;
                    margin: 7px 0px;
                    width: 73%;
                }
                .filename {
                    max-width: 75%;
                    overflow: hidden;
                    margin-bottom: 0px;
                }
                .custom-file-input {
                    cursor: pointer;
                    outline-color: transparent;
                }
                .custom-file-label {
                    border: 0px;
                    background-color: transparent;
                    font-family: "DM Sans";
                    color: #000000;
                    margin-left: -10px;

                    p {
                        opacity: 0.3;
                    }
                    cursor: pointer;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    outline: 4px solid #fcfcfc;
                }
                .custom-file-label::after {
                    content: "choose a file";
                    background: linear-gradient(98.07deg, #115297 5.22%, #600abd 101.6%);
                    height: 42px;
                    display: flex;
                    align-items: center;
                    padding: 0px 19px;
                    opacity: 1;

                    border: none;
                    border-radius: 16.8091px;
                    color: #ffffff;
                    font-weight: 500;
                    font-size: 14px;
                }
                padding: 21px 24px 20px;
                background: #fcfcfc;
                border: 2px solid #f8f8f8;
                border-radius: 24px;
                justify-content: space-between;
            }
        }
    }
    .job-discription {
        background: #fbfbfb;
        border-radius: 32px;
        margin: 20px 5% 0px;
        padding: 48px 44px 54px 54px;
        .apply-now {
            background: linear-gradient(91.61deg, #600abd 3.87%, #115297 100%);
            border-radius: 24px;
            font-weight: 700;
            font-size: 24px;
            padding: 18px 76px;
            color: #ffffff;
            border: none;
        }
        .about {
            .qualification {
                padding-top: 10px;
            }
            h2 {
                font-weight: 700;
                font-size: 24px;
            }
            p {
                font-weight: 400;
                font-size: 18px;
                margin-bottom: 0px;
                padding-right: 54px;
            }
            ul {
                margin-left: -23px;
                margin-top: 15px;
            }
            li {
                font-weight: 400;
                font-size: 18px;
            }
        }
    }
}
.instant-checkout-wrapper {
    @media (max-width: 767px) {
        .user-details {
            padding: 28px 15px !important;
        }
        .price-details {
            margin-top: 25px;
            padding: 60px 30px !important;
        }
    }
    margin-top: 50px;
    .line {
        margin: 0px;
    }
    .package-title {
        p {
            margin-bottom: 7px;
            font-weight: 500;
            font-size: 20px;
        }
        background: #fdfdfd;
        border: 1px solid #ededed;
        border-radius: 32px;
        padding: 34px 25px;
    }
    .user-details {
        margin-top: 30px;
        background: #ffffff;
        border: 1px solid #c9c9c9;
        border-radius: 32px;
        padding: 28px 48px;
        gap: 16px;
        .sub-details {
            background: #fcfcfc;
            border: 2px solid #f8f8f8;
            border-radius: 24px;
            padding: 24px;
            gap: 3px;
            span {
                font-size: 18px;
                font-weight: 700;
            }
            small {
                font-weight: 500;
                font-size: 14px;
            }
        }
    }
    .price-details {
        .line {
            opacity: 0.04;
            border-top: 1px solid #000000;
            width: 230px;
        }
        .center-line {
            margin: auto;
        }
        p {
            margin-bottom: 0px;
        }
        .price {
            font-weight: 500;
            font-size: 28px;
        }
        background: #ffffff;
        border: 1px solid #c9c9c9;
        border-radius: 32px;
        padding: 60px 90px;
        gap: 13px;
        .amount {
            span {
                font-weight: 400;
                font-size: 14px;
            }
            p {
                font-weight: 500;
                font-size: 14px;
            }
        }
        .total {
            span {
                font-weight: 500;
                font-size: 16px;
            }
            p {
                font-weight: 700;
                font-size: 18px;
            }
        }
        .note {
            font-weight: 400;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.6);
            margin-bottom: 15px;
            text-align: center;
        }
        .make-pay {
            margin-top: 10px;
            background: linear-gradient(91.61deg, #600abd 3.87%, #115297 100%);
            border-radius: 24px;
            font-weight: 700;
            font-size: 24px;
            line-height: 31px;
            justify-content: center;
            padding: 26px 0px;
            color: #ffffff;
            border: 0px;
        }
    }
}
