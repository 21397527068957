// Travelinfo
.view-more-holder{
  overflow: hidden;
        scroll-behavior: smooth;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;

        /* Firefox */
        & ::-webkit-scrollbar {
            display: none;
            /* Hide scrollbar for Chrome, Safari and Opera */
        }
  .wrapper{
    overflow: auto;
    padding-left: calc((100% - 1105px) / 2);
    display: flex;
    margin-left: 10px;
    .see-all {
      border: 4px solid #e0e0e0;
      box-sizing: border-box;
      border-radius: 48px;
      height: 472px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      align-items: center;
      background: #fff;
      justify-content: space-around;
  }
    .card-wrapper.see-all{
      height: 394px !important;
    }
    .card-wrapper {
      width: 273px;
      margin-right: 10px;
      height: 370px;
      margin-left: 0px;
      border-radius: 32px;
      overflow: hidden;
      @media (max-width: 767px){
        height: auto;
      }
      
      .card-img{
        border-bottom-right-radius: calc(0.25rem - 1px);
        border-bottom-left-radius: calc(0.25rem - 1px);
        img{
          height: 244px;
        }
          }
      .info{
        height: 100px;
        @media (max-width: 767px){
          height: 150px;
        }
        .title {
          font-size: 18px;
          line-height: 26px;
          padding: 18px 24px 0px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          // margin-bottom: 20px;
          // height: 40px;
          @media (max-width: 767px) {
            color: #000000;
            font-size: 16px;
            padding: 15px 20x;
            -webkit-line-clamp: 3;
          }
        }
        .points {
          font-size: 14px;
          line-height: 26px;
          padding: 0px 24px 0px;
          margin-bottom: 0;
          color: #000000;
          opacity: 0.5;
          // height: 40px;
        }
      }
      
  }
  }
}
.visa-travelInfo {
  overflow: hidden;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;

  /* Firefox */
  & ::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for Chrome, Safari and Opera */
  }

  .info-holder {
    padding-left: calc((100% - 1105px) / 2);
    overflow: auto;
  }

  .card-wrapper {
    display: flex;
    max-height: 300px;
    margin-left: -28px;
    width: fit-content;

    .info-card {
      display: flex;
      border-radius: 20px 20px 20px 20px;
      margin-right: 32px;
      align-items: center;
      background-color: #f8f8f8;

      .img-holder img {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 0px;
        @media (max-width: 767px) {
          border-radius: 20px 20px 0px 0px !important;
        }
      }

      .card-content {
        // background-color: #f8f8f8;
        width: 350px;
        padding: 15px 28px;
        // min-height: 228px;
      }

      .card-content h2 {
        font-weight: 500;
        font-size: 28px;
        margin-bottom: 20px;
        line-height: 36px;
      }

      .card-content p {
        font-weight: 400;
        font-size: 14px;
        opacity: 0.6;
        line-height: 18px;
      }

      &:nth-child(1) {
        margin-left: 22px;

        @media (min-width: 768px) and (max-width: 1199px) {
          margin-left: 62px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .visa-travelInfo {
    .info-card {
      flex-direction: column;
      margin: 8px !important;
    }
    .card-wrapper {
      margin-left: 10px;
      margin-right: -185px;
      max-height: 525px;
      width: 100%;
      .img-holder img {
        border-top-left-radius: 32px;
        border-top-right-radius: 32px !important;
        border-bottom-left-radius: 0px !important;
      }
    }

    .card-content {
      // background-color: #f8f8f8;
      width: 240px !important;
      padding: 15px 15px !important;

      // min-height: 228px;
    }

    .card-wrapper .info-card .card-content h2 {
      font-size: 20px;
      line-height: 25px;
    }
  }

  .card-wrapper {
    margin-left: 10px;
    margin-right: -185px;
    max-height: 525px;
    width: 100%;
    .img-holder img {
      border-top-left-radius: 32px;
      border-top-right-radius: 32px !important;
      border-bottom-left-radius: 0px !important;
    }
  }

  .card-content {
    // background-color: #f8f8f8;
    width: 240px !important;
    padding: 15px 15px !important;

    // min-height: 228px;
  }

  .card-wrapper .info-card .card-content h2 {
    font-size: 20px;
    line-height: 25px;
  }
}

// Visas & more media queries
.visa-holder {
  @media only screen and (max-width: 600px) {
    .about {
      flex-direction: column;
    }

    .about .img1 img {
      width: 150px;
      height: 240px;
      border-radius: 45px;
      margin: 0 5px 0 0;
    }

    .about .img2 img {
      width: 160px;
      height: 250px;
      border-radius: 45px;
      margin: 0 0 0 5px;
    }

    .about .img2 {
      margin-top: 50px;
    }

    .about-info {
      margin-top: 0;
    }

    .about-info h1 {
      font-size: 28px;
      line-height: 35px;
    }
  }

  @media only screen and (min-width: 600px) {
    .about {
      flex-direction: column;
    }
    // .about-info {
    //   margin-top: 50px;
    // }
  }

  .about-info {
    @media (max-width: 767px) {
      margin: -16px auto 0 !important;
    }
  }
  .about {
    img {
      @media (max-width: 767px) {
        object-fit: cover;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    .about {
      flex-direction: column;
    }

    .about-info {
      margin-top: 50px;
    }
  }

  @media only screen and (min-width: 992px) {
    .about {
      flex-direction: row;
    }
  }

  @media only screen and (min-width: 1024px) {
    .about {
      flex-direction: row;
    }
  }
}

@media screen and (min-width: 768px) {
  // .visa-enquire-mobile {
  //   display: none;
  // }

  .visa-enquire-desk {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .visa-enquire-mobile {
    display: block;
  }

  .visa-enquire-desk {
    display: none;
  }
}

.visa-enquire-mobile {
  top: 0;
  left: 0;
  position: fixed;
  overflow: auto;
  background: #22222275;
  z-index: 999;
  height: 100%;
  width: 100%;
  padding-top: 100px;
  @media (min-width: 992px) {
    width: 100%;
    margin: 0 auto;
    top: 50%;
    padding: 10px 20%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.visa-enquire-mobile {
  .mobile-bg {
    background-color: #fff;
    border-radius: 32px;
    position: relative;
    padding: 0 10px 2px 10px;

    .heading {
      text-align: center;
      padding: 30px 0 10px 0;
    }

    .close-icon {
      display: block;
      right: 0;
      top: 0;
      margin: 20px;
      color: #222;
      position: absolute;
    }
  }

  .login-holder {
    background-color: #fff;
    position: relative;

    .close-icon {
      display: block;
      right: 0;
      top: 0;
      margin: 20px;
      color: #222;
      position: absolute;
      cursor: pointer;
      @media (min-width: 992px) {
        right: 5px !important;
        top: 5px;
      }
    }
  }
}

.visas-content-wrapper {
  @media (min-width: 992px) {
    margin-top: -35px !important;
  }
}
.visa-desktop {
  .card-wrapper {
    display: block;
    max-height: max-content;
    margin-left: -21px;
    .info-card {
      width: 96%;
      &:nth-child(1) {
        margin-bottom: 32px;
      }
      &:nth-child(2) {
        margin-left: 22px;
      }
    }
  }
}

.visas-line {
  margin: -25px 0px 60px;
}
.visas-divider {
  margin: 30px 0px 60px;
}

.disclaimer-holder {
  width: 50%;
  @media (max-width: 991px) {
    width: 100%;
  }
}
